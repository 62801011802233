import React from "react";

export const ContactAddress = React.memo(({ eng }) => (
  <div className="contact-us__contacts-adress">
    <div className="contact-us__contacts-adress-title h3">
      {eng ? "OUR OFFICE" : "Адрес"}
    </div>
    <p className="adress adress-text">
      г. Калининград, ул. Генерал-Лейтенанта Озерова, 17Б (БЦ Мега)
      {/* Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab Emirates */}
      {/* {eng
				? '236010, Russian Federation, Kaliningrad, st. Lieutenant General Ozerov, 17B, 8th floor'
				: '236010, РФ, г. Калининград, ул. Генерал-лейтенанта Озерова, 17Б, 8 этаж'}
			{eng && (
				<p>
					<br />
					11312, Harju maakond, Tallinn, Kesklinna linnaosa, Pärnu mnt 105
				</p>
			)} */}
    </p>
    {/* <Link
		className="tel adress-text"
		to='#'
		onClick={(e) => {
			window.location = 'tel:+796543210'
			e.preventDefault()
		}}>+796543210</Link> */}
    <a
      href="mailto:manager@x-tek.ru"
      target="_blank"
      rel="noreferrer"
      className="email adress-text"
    >
      manager@x-tek.ru
    </a>
  </div>
));
