import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ContactForm } from '../ContactFooter/ContactForm/ContactForm'
import { Popup } from '../Popup/Popup'

import { validate } from '../../pages/functions'

import { addRequest } from '../../store/reducers/requests/requests'
import { getResult } from '../../store/reducers/requests/requests-selector'
import { showMessage } from '../../store/reducers/functions'

import './ContactUsPopup.scss'

export const ContactUsPopup = React.memo(({ onClose, show, lang }) => {
	const dispatch = useDispatch()

	const result = useSelector(getResult)

	const [name, setName] = useState('')
	const [phone, setContactInput] = useState('')
	const [company, setExtraInput] = useState('')
	const [email, setLinkInput] = useState('')
	const [description, setTextInput] = useState('')

	const [checkbox, setCheckbox] = useState(false)

	const [errorData, setErrorData] = useState({
		name: true,
		phone: true,
		email: true,
		checkbox: true,
		company: true,
		description: true,
	})

	const eng = lang === 'EN'

	const requestForm = () => {
		const data = {
			name: validate('name', name) ? name : '',
			company: validate('company', company) && company,
			phone: validate('phone', phone) ? phone : '',
			email: validate('email', email) ? email : '',
			description: validate('description', description) && description,
		}

		if (!data.name || !data.phone || !data.email || !checkbox) {
			setErrorData({
				name: data.name ? true : false,
				phone: data.phone ? true : false,
				email: data.email ? true : false,
				checkbox: checkbox ? true : false,
				company: data.company === '' || data.company ? true : false,
				description: data.description === '' || data.description ? true : false,
			})

			showMessage(dispatch, {
				code: 'error',
				msg: eng ? 'Fill all necessary fields' : 'Заполните все необходимые поля',
			})
		} else {
			dispatch(addRequest(data, eng))
		}
	}

	useEffect(() => {
		if (result) {
			setName('')
			setContactInput('')
			setExtraInput('')
			setLinkInput('')
			setTextInput('')
			setCheckbox(false)

			onClose(false)
			document.body.style.overflow = null
		}
	}, [result, onClose])

	return (
		<div className="contact-us-popup-wrapper">
			<Popup show={show} title={eng ? 'Contact us' : 'свяжитесь с нами'} onClose={onClose}>
				<ContactForm
					eng={eng}
					requestForm={requestForm}
					name={name}
					setName={setName}
					contactInput={phone}
					setContactInput={setContactInput}
					extraInput={company}
					setExtraInput={setExtraInput}
					linkInput={email}
					setLinkInput={setLinkInput}
					textInput={description}
					setTextInput={setTextInput}
					checkbox={checkbox}
					setCheckbox={setCheckbox}
					errorData={errorData}
					btnColor={'blue'}
					btnPosition={'right'}
					white
					contact
				/>
			</Popup>
		</div>
	)
})

ContactUsPopup.propTypes = {
	onClose: PropTypes.func,
}
