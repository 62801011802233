import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { ContactForm } from '../ContactFooter/ContactForm/ContactForm'
import { Popup } from '../Popup/Popup'
import { Timeline } from './Timeline/Timeline'

import { addTeam } from '../../store/reducers/requests/requests'
import { getResult } from '../../store/reducers/requests/requests-selector'
import { showMessage } from '../../store/reducers/functions'

import { validate } from '../../pages/functions'

import './confirmRequest.scss'

export const ConfirmRequest = React.memo(({ onClose, devs, setDevs, show, eng }) => {
	const dispatch = useDispatch()

	const result = useSelector(getResult)

	const [name, setName] = useState('')
	const [phone, setContactInput] = useState('')
	const [company, setExtraInput] = useState('')
	const [email, setLinkInput] = useState('')
	const [description, setTextInput] = useState('')
	const [file, setFile] = useState('')
	const [checkbox, setCheckbox] = useState(false)
	const [date, setDate] = useState('')
	const [fileName, setFileName] = useState('')

	const [errorData, setErrorData] = useState({
		name: true,
		phone: true,
		email: true,
		checkbox: true,
		company: true,
		description: true,
	})

	const requestForm = () => {
		const data = {
			name: validate('name', name) ? name : '',
			company: validate('company', company) && company,
			phone: validate('phone', phone) ? phone : '',
			email: validate('email', email) ? email : '',
			description: validate('description', description) && description,
			devs: devs.map(({ id, name, surname }) => ({ id, name, surname })),
			deadline: date,
			file: file,
		}

		if (!data.name || !data.phone || !data.email || !checkbox) {
			setErrorData({
				name: data.name ? true : false,
				phone: data.phone ? true : false,
				email: data.email ? true : false,
				checkbox: checkbox ? true : false,
				company: data.company === '' || data.company ? true : false,
				description: data.description === '' || data.description ? true : false,
			})

			showMessage(dispatch, {
				code: 'error',
				msg: eng ? 'Fill all necessary fields' : 'Заполните все необходимые поля',
			})
		} else {
			dispatch(addTeam(data, eng))
		}
	}

	useEffect(() => {
		if (result) {
			setName('')
			setContactInput('')
			setExtraInput('')
			setLinkInput('')
			setTextInput('')
			setFile('')
			setCheckbox(false)
			setDevs([])
			setDate('')
			setFileName('')
			setErrorData({
				name: true,
				phone: true,
				email: true,
				checkbox: true,
				company: true,
				description: true,
			})
			onClose(false)
		}
	}, [result, setDevs, onClose])

	return (
		<div className="confirm-request__wrapper">
			<Popup title={eng ? 'Confirm request' : 'Оформить заявку'} onClose={onClose} show={show}>
				<div>
					<div className="confirm-request">
						<div className="confirm-request__title h3">
							{eng ? 'Term of your project' : 'Срок вашего проекта'}
						</div>
						<div className="timeline">
							<div className="timeline_scroll-wrapper">
								<div className="timeline_scroll">
									<Timeline setDate={setDate} eng={eng} />
								</div>
							</div>
						</div>
						<div className="confirm-request__form-title h3">
							{eng ? 'Your data' : 'Ваши данные'}
						</div>
						<ContactForm
							eng={eng}
							btnColor="blue"
							btnPosition="right"
							requestForm={requestForm}
							name={name}
							setName={setName}
							contactInput={phone}
							setContactInput={setContactInput}
							extraInput={company}
							setExtraInput={setExtraInput}
							linkInput={email}
							setLinkInput={setLinkInput}
							textInput={description}
							setTextInput={setTextInput}
							file={file}
							setFile={setFile}
							checkbox={checkbox}
							setCheckbox={setCheckbox}
							fileName={fileName}
							setFileName={setFileName}
							errorData={errorData}
							white
							contact
						/>
					</div>
				</div>
			</Popup>
		</div>
	)
})

ConfirmRequest.propTypes = {
	onClose: PropTypes.func,
	teamDevs: PropTypes.bool,
	store: PropTypes.object,
}
