import React from 'react'
import { OvalFilterButton } from '../../../../../components/Buttons/OvalFilterButton/OvalFilterButton'

import './FilterItem.scss'

export const FilterItem = React.memo(({ title, skills, handler, active, place }) => {
	const list = skills?.map((item) => [item.key, item.value])

	return (
		<div className="filter">
			<div className="team__filter-subtitle text3">{title}</div>
			<div className="team__filter-box-item">
				{list.map((item, index) => {
					return (
						<OvalFilterButton
							key={`${item[0]}-${index}`}
							title={item[1]}
							checked={active(item[0])}
							onChange={handler(item[0])}
							place={place}
						/>
					)
				})}
			</div>
		</div>
	)
})
