import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './createTeam.scss'

export const CreateTeam = React.memo(({ text }) => (
	<Link to="/team">
		<button className="main-team__button h3">{text}</button>
	</Link>
))

CreateTeam.propTypes = {
	text: PropTypes.string,
}
