import { createSlice } from '@reduxjs/toolkit'

import requestsApi from '../../../api/requests-api'
import { showMessage } from '../functions'

const initialState = {
	result: null,
}

const requests = createSlice({
	name: 'requests',
	initialState,
	reducers: {
		setResult: (state, action) => {
			state.result = action.payload
		},
	},
})

const { reducer, actions } = requests

export const { setResult } = actions

// отправка формы с лидами
export const addRequest = (data, eng) => async (dispatch) => {
	const res = await requestsApi.addRequest(data)

	if (res.success) {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: '',
			msg: eng
				? 'Thank you, your application has been accepted and will be reviewed shortly'
				: 'Спасибо, ваша заявка принята и будет рассмотрена в ближайшее время',
		})
	} else {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: 'error',
			msg: eng
				? 'An error has occurred. Please try again or contact us via email client'
				: 'Произошла ошибка. Попробуйте повторить попытку или свяжитесь с нами через почтовый клиент',
		})
	}
	dispatch(setResult(false))
}

// отправка формы с резюме
export const addResume = (data, eng) => async (dispatch) => {
	const res = await requestsApi.addResume(data)

	if (res.success) {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: '',
			msg: eng
				? 'Thank you, your application has been accepted and will be reviewed shortly'
				: 'Спасибо, ваша заявка принята и будет рассмотрена в ближайшее время',
		})
	} else {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: 'error',
			msg: eng
				? 'An error has occurred. Please try again or contact us via email client'
				: 'Произошла ошибка. Попробуйте повторить попытку или свяжитесь с нами через почтовый клиент',
		})
	}
	dispatch(setResult(false))
}

// отправка формы с командой
export const addTeam = (data, eng) => async (dispatch) => {
	const res = await requestsApi.addTeam(data)

	if (res.success) {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: '',
			msg: eng
				? 'Thank you, your application has been accepted and will be reviewed shortly'
				: 'Спасибо, ваша заявка принята и будет рассмотрена в ближайшее время',
		})
	} else {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: 'error',
			msg: eng
				? 'An error has occurred. Please try again or contact us via email client'
				: 'Произошла ошибка. Попробуйте повторить попытку или свяжитесь с нами через почтовый клиент',
		})
	}
	dispatch(setResult(false))
}

// отправка формы с быстрого поиска
export const addQuickSearchTeam = (data, eng) => async (dispatch) => {
	const res = await requestsApi.addQuickSearchTeam(data)

	if (res.success) {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: '',
			msg: eng
				? 'Thank you, your application has been accepted and will be reviewed shortly'
				: 'Спасибо, ваша заявка принята и будет рассмотрена в ближайшее время',
		})
	} else {
		dispatch(setResult(res.success))
		showMessage(dispatch, {
			code: 'error',
			msg: eng
				? 'An error has occurred. Please try again or contact us via email client'
				: 'Произошла ошибка. Попробуйте повторить попытку или свяжитесь с нами через почтовый клиент',
		})
	}
	dispatch(setResult(false))
}

export default reducer
