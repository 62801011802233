import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import cn from 'classnames'

import { PagesHeader } from '../../../components/PagesHeader/PagesHeader'
import { OvalFilterButton } from '../../../components/Buttons/OvalFilterButton/OvalFilterButton'
import { Preloader } from '../../../components/Preloader/Preloader'

import {
	getEvents,
	getFilters,
	getCount,
	getLoading,
} from '../../../store/reducers/community/community-selector'
import { setFilters, setEvents, requestEvents } from '../../../store/reducers/community/community'

import { url } from '../../../api/api'

import headerBackground from '../../../assets/images/backgrounds/community-header.jpg'

import './Community.scss'

export const Community = React.memo(() => {
	const dispatch = useDispatch()
	const events = useSelector(getEvents)
	const filters = useSelector(getFilters)
	const count = useSelector(getCount)
	const loading = useSelector(getLoading)

	const { limit, offset, tag, lang } = filters

	const showMore = () => {
		dispatch(setFilters({ offset: offset + limit }))
	}

	const getTagName = (tag) => {
		const switchObj = {
			1: 'События',
			2: 'Обучение',
			3: 'Интервью',
		}
		return switchObj[tag]
	}

	const filterEvents = (chosen) => () => {
		if (chosen) {
			return dispatch(setFilters({ tag: chosen }))
		} else {
			return dispatch(setFilters({ tag: 4 }))
		}
	}

	useEffect(() => {
		dispatch(requestEvents())
	}, [dispatch, offset, lang])

	useEffect(
		() => () => {
			dispatch(setFilters({ tag: 4, offset: 0 }))
			dispatch(setEvents({ events: [] }))
		},
		[dispatch]
	)

	return (
		<div className="community-page">
			<PagesHeader image={headerBackground} title={'Сообщество'}>
				<p>
					X-Cluster - это IT-сообщество, нацеленное на создание комфортной среды для развития
					IT-специалистов.
				</p>
				<p>
					X-Cluster обладает необходимой инфраструктурой для привлечения и обучения кадров, а также
					имеет своей целью объединение компаний для развития IT-бизнеса.
				</p>

				<a
					href="https://x-cluster.com/"
					className="cluster-link"
					target="_blank"
					rel="noopener noreferrer"
				>
					Присоединиться
				</a>
			</PagesHeader>
			<div className="container">
				<div className="events-feed">
					<div className="events-feed__filters">
						<OvalFilterButton title="Все" checked={!tag} onChange={filterEvents()} />
						<OvalFilterButton title="События" checked={tag === 1} onChange={filterEvents(1)} />
						<OvalFilterButton title="Обучение" checked={tag === 2} onChange={filterEvents(2)} />
						<OvalFilterButton title="Интервью" checked={tag === 3} onChange={filterEvents(3)} />
					</div>
					<div className={cn('events-wrapper', { empty: !events.length && !loading })}>
						{events?.map((item, i) => (
							<Link key={i} to={`/community/${item.id}`}>
								<div className="event-item">
									<div className="event-item__img">
										<div className="img-wrapper">
											<div style={{ backgroundImage: `url(${url}/static/${item.preImage})` }}></div>
										</div>
										<div
											className="blur"
											style={{ backgroundImage: `url(${url}/static/${item.preImage})` }}
										></div>
									</div>

									<div className="event-item__info-wrapper">
										<div className="event-item__tag">
											<span>{getTagName(item.tag)}</span>
										</div>
										<div className="event-item__title">
											<span>{item.title}</span>
										</div>
										<div className="event-item__text">
											<span>{item.descrition}</span>
										</div>
									</div>
								</div>
							</Link>
						))}
					</div>
					{loading && <Preloader full />}
					{count > events.length && !!events.length && (
						<button className="main-team__button h3" onClick={() => showMore()}>
							<span>загрузить ещё</span>
						</button>
					)}
				</div>
			</div>
		</div>
	)
})

Community.propTypes = {
	store: PropTypes.object,
	eventId: PropTypes.func,
}
