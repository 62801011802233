import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ArrowButton } from "../../../components/Buttons/ArrowButton/ArrowButton";
import { Brands } from "../../../components/Brands/Brands";
// import { ArrowLink } from "../../../components/Buttons/ArrowLink/ArrowLink";
import { LinkBox } from "../../../components/InfoBoxes/LinkBox/LinkBox";
import { ContactFooter } from "../../../components/ContactFooter/ContactFooter";
import { CreateTeam } from "../../../components/Buttons/CreateTeam/CreateTeam";
import { BlueButton } from "../../../components/Buttons/BlueButton/BlueButton";
import { TechnologyCard } from "../../../components/TechnologyCard/TechnologyCard";

import whyPic from "../../../assets/images/main-why.jpg";
import find from "../../../assets/images/main-find.jpg";
// import community from "../../../assets/images/main-community.jpg";

import { requestEvents } from "../../../store/reducers/main/main";
import { addRequest } from "../../../store/reducers/requests/requests";
// import { getEvents } from "../../../store/reducers/main/main-selectors";
import { getResult } from "../../../store/reducers/requests/requests-selector";
import { showMessage } from "../../../store/reducers/functions";

import { ENG_MAIN } from "../../../StaticInfo/StaticInfo";

import { validate } from "../../functions";
// import { url } from "../../../api/api";

import "./main.scss";

export const EnMain = React.memo(({ open }) => {
  const dispatch = useDispatch();
  // const events = useSelector(getEvents);
  const result = useSelector(getResult);

  const deviceWidth = document.body.clientWidth;

  const [name, setName] = useState("");
  const [phone, setContactInput] = useState("");
  const [company, setExtraInput] = useState("");
  const [email, setLinkInput] = useState("");
  const [description, setTextInput] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const [openTech, setOpenTech] = useState(false);

  const [errorData, setErrorData] = useState({
    name: true,
    phone: true,
    email: true,
    checkbox: true,
    company: true,
    description: true,
  });

  const requestForm = () => {
    const data = {
      name: validate("name", name) ? name : "",
      company: validate("company", company) && company,
      phone: validate("phone", phone) ? phone : "",
      email: validate("email", email) ? email : "",
      description: validate("description", description) && description,
    };

    if (!data.name || !data.phone || !data.email || !checkbox) {
      setErrorData({
        name: data.name ? true : false,
        phone: data.phone ? true : false,
        email: data.email ? true : false,
        checkbox: checkbox ? true : false,
        company: data.company === "" || data.company ? true : false,
        description: data.description === "" || data.description ? true : false,
      });

      showMessage(dispatch, {
        code: "error",
        msg: "Fill all necessary fields",
      });
    } else {
      dispatch(addRequest(data, true));
    }
  };

  useEffect(() => {
    dispatch(requestEvents());
  }, [dispatch]);

  useEffect(() => {
    if (result) {
      setName("");
      setContactInput("");
      setExtraInput("");
      setLinkInput("");
      setTextInput("");
      setCheckbox(false);
      setErrorData({
        name: true,
        phone: true,
        email: true,
        checkbox: true,
        company: true,
        description: true,
      });
    }
  }, [result]);

  return (
    <div className="main-page">
      <section className="main-header">
        <div className="container">
          <div className="main-header__wrapper">
            <div>
              <h1 className="h1 main__title">
                WE WILL BUILD A HIGH PERFORMING TEAM FOR YOUR PROJECT
              </h1>
              <div className="text1 main-header__text">
                We will build a team within 10 days
              </div>
            </div>
            <ArrowButton
              text={"Learn more"}
              color={"blue"}
              position={"left"}
              openPopup={open}
            />
          </div>
        </div>
        <Brands eng />
      </section>
      <section className="main-who-we-are">
        <div className="container">
          <h2 className="main__title h1">who are we?</h2>
          <div className="main-who-we-are__wrapper">
            <div className="main-who-we-are__left-block">
              <div className="main-who-we-are__text title-text">
                X-Technology is a technical company that can implement your
                project of any complexity.
              </div>
              <div className="main-who-we-are__box adaptive">
                <div className="main-who-we-are__text text2">
                  <p>We work in three directions.</p>
                  <div className="main-who-we-are__text-subtitle">
                    Outsourcing:
                  </div>
                  <p>
                    we will help you to implement your project by delegating
                    authority to professionals and optimizing the costs of
                    required resources.
                  </p>
                  <div className="main-who-we-are__text-subtitle">
                    Dedicated team:
                  </div>
                  <p>
                    we will provide you with the opportunity to select a team
                    for your project, as well as undertake all of its
                    administrative and technical support.
                    <span>
                      In addition, we can select qualified specialists for your
                      staff.
                    </span>
                  </p>
                  <div className="main-who-we-are__text-subtitle">
                    Equipment development:
                  </div>
                  <p>
                    our team is engaged in the design and hardware development,
                    taking into account all your wishes.
                    <span>
                      Every day we improve our knowledge to become better than
                      yesterday. Create your own unique products with
                      X-Technology.
                    </span>
                  </p>
                </div>
              </div>
              {/* <div className="main-who-we-are__img">
                <iframe
                  className="who-we-are-video"
                  title="X-cluster"
                  src="https://www.youtube.com/embed/XKGEx2tPN1Y"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <div
                  className="blur"
                  style={{ backgroundImage: `url(${community})` }}
                ></div>
              </div> */}
              {/* <div className="main-who-we-are__quote">
								X-Technology: <br /> One step to make your dreams come true
							</div> */}
            </div>
            <div className="main-who-we-are__box">
              <div className="main-who-we-are__text text2">
                <p>We work in three directions.</p>
                <div className="main-who-we-are__text-subtitle">
                  Outsourcing:
                </div>
                <p>
                  we will help you to implement your project, delegating
                  authority to professionals and optimizing the costs of the
                  required resources.
                </p>
                <div className="main-who-we-are__text-subtitle">
                  Dedicated team:
                </div>
                <p>
                  we will provide you with the opportunity to select a team for
                  your project, as well as undertake all of its administrative
                  and technical support.
                  <span>
                    In addition, we can select qualified specialists for your
                    staff.
                  </span>
                </p>
                <div className="main-who-we-are__text-subtitle">
                  Equipment development:
                </div>
                <p>
                  our team is engaged in the design and hardware development,
                  taking into account all your wishes.
                  <span>
                    Every day we improve our knowledge to become better than
                    yesterday. Create your own unique products with
                    X-Technology.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-services">
        <div className="container">
          <h2 className="main__title h1">SERVICES</h2>
          <div className="main-services__wrapper">
            {ENG_MAIN.service.map((item) => (
              <LinkBox
                key={item.title}
                image={item.img}
                title={item.title}
                text={item.text}
                link={`/service#${item.id}`}
                linkText={"Learn more"}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="why">
        <div className="container">
          <div className="why__wrapper">
            <div className="why__box">
              <h2 className="main__title h1">WHY US?</h2>
              <ul className="why__list text2">
                <li className="why__item">
                  We provide <strong>a full range of services</strong>: turnkey
                  software development, hardware development, IT specialists
                  recruitment and establishing dedicated IT teams for particular
                  projects.
                </li>
                <li className="why__item">
                  We will <strong>quickly and accurately</strong> assess your
                  project in terms of time and cost.
                </li>
                <li className="why__item">
                  We have our{" "}
                  <strong>own team of developers and engineers</strong> to
                  quickly start developing your project.
                </li>
                <li className="why__item">
                  All <strong>legal, administrative and staff </strong>aspects
                  are covered.
                </li>
                <li className="why__item">
                  We start selecting a team <strong>30 minutes</strong> after
                  the contract is signed.
                </li>
                <li className="why__item">
                  <strong>X-Work</strong> recruiters undergo intensive IT
                  training, which allows them to be on the same page with
                  developers and conduct a high-quality technical screening of a
                  candidate.
                </li>
                <li className="why__item">
                  We cooperate with more than <strong>100</strong> partners, who
                  recommend interested candidates for vacancies of our agency on
                  a daily basis.
                </li>
                <li className="why__item">
                  We have an exclusive contract with <strong>X-Cluster</strong>:
                  community: access to a closed database of IT specialists and
                  an affiliate program of candidate’s recommendations.
                </li>
              </ul>
            </div>
            <div className="why__box">
              <picture>
                <source srcSet={whyPic} media="(max-width: 768px)" />
                <source srcSet={whyPic} media="(max-width: 1024px)" />
                <source srcSet={whyPic} />
                <img srcSet={whyPic} alt="why" className="why__img" />
              </picture>
              <div
                className="blur"
                style={{ backgroundImage: `url(${whyPic})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-team">
        <div className="container">
          <div className="main-team__title">
            <h2 className="main__title h1">BUILD YOUR TEAM</h2>
            <div className="main-team__title-text text2">
              We will select a team, taking into account all the peculiarities
              of your project. The <strong>X-Сluster</strong> team consists of
              more than 4000 professionals with different experience and
              qualifications.
            </div>
          </div>
          <div className="main-team__wrapper">
            <div className="main-team__scroll-wrapper">
              <div className="main-team__scroll-container">
                <div className="main-team__box">
                  {ENG_MAIN.team.map((item, index) => (
                    <div className="main-team__item" key={`${item.title}`}>
                      <div
                        className="main-team__item-img"
                        style={{ backgroundImage: `url(${item.img})` }}
                      >
                        <img src={item.img} alt="Разработчик" />
                        <div className="name h3">{item.title}</div>
                      </div>
                      <div className="main-team__item-title">
                        <span>{item.type}</span>
                      </div>
                      <ul className="main-team__item-list">
                        {item.skills.map((el, i) => (
                          <li
                            key={`${el}-${i}`}
                            className="main-team__item-skill text3"
                          >
                            {el}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="main-team__text text1">
              ... and <strong>4368</strong> other developers
            </div>
            <CreateTeam text={"Build a team"} />
          </div>
        </div>
      </section>
      <section className="main-tech">
        <div className="container">
          <h2 className="main__title h1">Technologies</h2>
          <div className="main-tech__wrapper">
            {ENG_MAIN.tech.map((item, i) => (
              <TechnologyCard
                key={`tech-${i}`}
                index={i}
                icon={item.icon}
                title={item.title}
                body={item.skills}
                deviceWidth={deviceWidth}
                active={openTech}
                onClick={() => setOpenTech(i)}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="main-find">
        <div className="container">
          <div className="main-find__wrapper">
            <div className="main-find__img">
              <picture>
                <source srcSet={find} media="(max-width: 1024px)" />
                <source srcSet={find} />
                <img srcSet={find} alt="why" className="why__img" />
              </picture>
              <div
                className="blur"
                style={{ backgroundImage: `url(${find})` }}
              ></div>
            </div>
            <div className="main-find__box">
              <div className="flex-container">
                <div>
                  <h2 className="main__title h1">
                    WHERE DO WE SEEK FOR CANDIDATES?{" "}
                  </h2>
                  <p className="main-find__text">
                    We use ~ 500 tools to find specialists. We also have an
                    exclusive contract with the X-Cluster IT community: access
                    to a closed database of IT specialists and an affiliate
                    program of candidate’s recommendations.
                  </p>
                </div>
                <BlueButton text={"DISCUSS YOUR PROJECT"} openPopup={open} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-selection">
        <div className="container">
          <h2 className="main__title h1">THE SELECTION PROCESS</h2>
          <div className="main-selection__wrapper">
            {ENG_MAIN.selection.map((item, i) => (
              <div className="main-selection__box" key={`selection-${i}`}>
                <div className="main-selection__title">{item.num}</div>
                <div
                  className="main-selection__text"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="main-community">
        <div className="container">
          <div className="main-community__wrapper">
            <div className="main-community__box">
              <h2 className="main__title h1">community</h2>
              <div className="main-community__text text2">
                <p>
                  It is an <strong>IT-community</strong> aimed at creating a
                  comfortable environment for growth of IT-specialists of any
                  competence and evolvement of the IT industry in general.
                </p>
                <p>
                  We create the right conditions for specialists by their
                  all-round development in accordance with current market
                  prospects and needs, and bring the
                  <strong> IT-community</strong> to a new level.
                </p>
                <p>
                  For this purpose we hold events, offer practice and work in IT
                  companies and support projects.
                </p>
                <p>
                  We provide the members of the community with access to the
                  infrastructure - a special platform where the participant can
                  learn the latest news, study educational materials and book a
                  free slot in coworking space.
                </p>
                <p>
                  The <strong>X-Cluster</strong> has its own bonus system, where
                  participants receive points for any activity that can be
                  exchanged for various benefits from partners (branded goods,
                  electronics, services, etc.).
                </p>
              </div>
            </div>
            <div className="main-community__img">
              <iframe
                className="community-video"
                title="X-cluster"
                src="https://www.youtube.com/embed/Js5QpWBdBAw"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div
                className="blur"
                style={{ backgroundImage: `url(${community})` }}
              ></div>
            </div>
          </div>
        </div>
      </section> */}
      {/* {!!events.length && (
				<section className="main__event">
					<div className="container">
						<div className="main__event__wrapper">
							<div className="main__event__title-wrapper">
								<h2 className="main__title h2">EVENTS</h2>
								<ArrowLink text={'ALL EVENTS '} path={'/community'} />
							</div>
							<div className="main__event__scroll-wrapper">
								<div className="main__event__scroll-container">
									<div className="main__event__box">
										{events.map((item) => (
											<LinkBox
												key={item.id}
												image={`${url}/static/${item.preImage}`}
												title={item.title}
												text={item.descrition}
												link={`/community/${item.id}`}
												linkText={'Learn More'}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)} */}
      <ContactFooter
        eng
        title={"CONTACT US"}
        requestForm={requestForm}
        name={name}
        setName={setName}
        contactInput={phone}
        setContactInput={setContactInput}
        extraInput={company}
        setExtraInput={setExtraInput}
        linkInput={email}
        setLinkInput={setLinkInput}
        textInput={description}
        setTextInput={setTextInput}
        checkbox={checkbox}
        setCheckbox={setCheckbox}
        errorData={errorData}
        map
      />
    </div>
  );
});
