import React from 'react'

export const TimelineItem = ({ range, index }) => {
	return (
		index < 23 && (
			<div
				className="segment"
				style={{
					backgroundColor: `${range > index ? '$color-blue' : 'rgba(55, 59, 79, 0.3)'}`,
				}}
			></div>
		)
	)
}
