import React from 'react'
import './policy.scss'

export const Personal = React.memo(() => (
	<section className="policy">
		<div className="policy__container">
			<h2 className="h2 policy__title">Согласие</h2>
			<div className="text2">
				<h2 className="policy__main-title">на обработку персональных данных</h2>
				<div className="policy__text">
					<p className="text2">
						Настоящим, в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных
						данных», Вы подтверждаете свое согласие на обработку ООО «Икс-Тэк» (юридический адрес:
						236000, Россия, г. Калининград, улица Генерал-лейтенанта Озерова, 17к1, 8 этаж, офис
						819) Ваших персональных данных.
					</p>
					<p className="text2">
						Настоящим согласием вы подтверждаете, что проинформированы о том, что под обработкой
						персональных данных понимаются действия с персональными данными, определённые в
						Федеральном законе № 152-ФЗ от 27.07.2006 «О персональных данных», а именно: сбор,
						запись, систематизация, накопление, хранение, уточнение (обновление, изменение),
						извлечение, использование, передача (предоставление, доступ), блокирование, удаление
						персональных данных, совершаемые использованием средств автоматизации ООО «Икс-Тэк».
					</p>
					<p className="text2">
						Данным согласием вы подтверждаете, что проинформированы о том, что обработка
						предоставляемых вами персональных данных может осуществляется в следующих целях:
						<ul>
							<li>- идентификация стороны в рамках оказания Услуги;</li>
							<li>- предоставление пользователю персонализированных Услуг;</li>
							<li>- улучшение качества Услуг и разработка новых;</li>
							<li>
								- проведение статистических и иных исследований, на основе обезличенных данных;
							</li>
							<li>
								- предоставление персональных данных пользователя правообладателям, дистрибьюторам
								или реселлерам программного обеспечения в целях регистрации программного обеспечения
								на имя пользователя или организации, интересы которой представляет пользователь;
							</li>
							<li>
								- предоставление пользователям Сайта безопасного и удобного функционала по его
								использованию, эффективному отображению информации;
							</li>
							<li>
								- эффективное исполнение заказов, договоров и иных обязательств, принятых ООО
								«Икс-Тэк» в качестве обязательных к исполнению перед пользователем;
							</li>
							<li>- обработка вопросов пользователей Сайта;</li>
							<li>- регистрация пользователей Веб-сайта на мероприятия;</li>
							<li>
								- осуществление и/или исполнение функций, полномочий и обязанностей, возложенных
								законодательством Российской Федерации на ООО «Икс-Тэк»;
							</li>
						</ul>
					</p>
					<p className="text2">
						Настоящее согласие распространяется на следующие персональные данные: фамилия, имя,
						отчество, дата рождения, адрес регистрации; контактный телефон, сведения об организации,
						должность, сведения об образовании, фотографии, номер факса, номер сотового телефона,
						адрес электронной почты, сведения о стране, городе, области проживания, сведения о
						банковской карте (номер, CVV, фамилия имя на латинице, год и месяц окончания действия
						карты).
					</p>
					<p className="text2">
						Срок действия Вашего согласия является неограниченным, однако, Вы вправе в любой момент
						отозвать настоящее согласие, путём направления письменного уведомления на адрес: 236000,
						Россия, г. Калининград, улица Генерал-лейтенанта Озерова, 17к1, 8 этаж, офис 819 в ООО
						«Икс-Тэк», с пометкой «отзыв согласия на обработку персональных данных». Удаление ваших
						персональных данных будет произведено ООО «Икс-Тэк» в течении 10 дней с момента
						получения данного уведомления.
					</p>
					<p className="text2">
						ООО «Икс-Тэк» гарантирует соблюдение следующих прав субъекта персональных данных: право
						на получение сведений о том, какие персональные данные субъекта персональных данных
						хранятся у ООО «Икс-Тэк»; право на удаление, уточнение или исправление хранящихся у ООО
						«Икс-Тэк» персональных данных; иные права, установленные действующим законодательством
						Российской Федерации. Обращаем Ваше внимание, что отзыв вашего согласия на обработку
						персональных данных влечёт за собой удаление вашей учётной записи с сайта X-Technology,
						расположенный на доменном имени x-technology.org, а также уничтожение записей,
						содержащих ваши персональные данные, в информационных системах обработки персональных
						данных ООО «Икс-Тэк», что может сделать невозможным пользование Интернет-сервисами ООО
						«Икс-Тэк».
					</p>
				</div>
			</div>
		</div>
	</section>
))
