import React from 'react'

import { url } from '../../../../../api/api'

export const HtmlRenderer = React.memo(({ bodyItem }) => (
	<div className="html-renderer">
		{bodyItem.type === 'text' && (
			<div className="event-text">
				<pre>{bodyItem.content}</pre>
			</div>
		)}
		{bodyItem.type === 'key' && <div className="event-key-text">{bodyItem.content}</div>}
		{bodyItem.type === 'h3' && <h3>{bodyItem.content}</h3>}
		{bodyItem.type === 'video' && (
			<iframe
				title="event-video"
				className="event-video"
				src={bodyItem.content}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
		)}

		{bodyItem.type === 'photo' && (
			<div className="event-img">
				<img src={`${url}/static/${bodyItem.content}`} alt={bodyItem.title} />
				<img className="blur" src={`${url}/static/${bodyItem.content}`} alt={bodyItem.title} />
			</div>
		)}
	</div>
))
