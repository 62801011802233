import React from "react";

import "./ContactButtons.scss";

export const ContactButtons = React.memo(
  ({ link, icon, alt }) => (
    // (alt === 'linkedin' || alt === 'instagram' || alt === 'youtube') && (
    <a href={link} target="_blank" rel="noreferrer">
      <button className="h3">
        <span style={{ background: `url(${icon}) left no-repeat` }}>{alt}</span>
      </button>
    </a>
  )
  // )
);
