import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { PagesHeader } from '../../../components/PagesHeader/PagesHeader'
import { Brands } from '../../../components/Brands/Brands'
import { ContactFooter } from '../../../components/ContactFooter/ContactFooter'
import { XLine } from '../../../components/X-line/XLine'
import { SpecializationBox } from '../../../components/InfoBoxes/SpecializationBox/SpecializationBox'

import { addRequest } from '../../../store/reducers/requests/requests'
import { getResult } from '../../../store/reducers/requests/requests-selector'
import { showMessage } from '../../../store/reducers/functions'

import { validate } from '../../functions'

import { EN_SERVICE } from '../../../StaticInfo/StaticInfo'

import './Services.scss'

import background from '../../../assets/images/backgrounds/services-header.png'
import commandImg from '../../../assets/images/choose_teams.jpg'
import outsourcingImg from '../../../assets/images/outsource.jpg'
import equipmentImg from '../../../assets/images/develop_instruments.jpg'

export const EnServices = React.memo(() => {
	const dispatch = useDispatch()
	const history = useHistory()

	const result = useSelector(getResult)

	const [name, setName] = useState('')
	const [phone, setContactInput] = useState('')
	const [company, setExtraInput] = useState('')
	const [email, setLinkInput] = useState('')
	const [description, setTextInput] = useState('')
	const [checkbox, setCheckbox] = useState(false)

	const [errorData, setErrorData] = useState({
		name: true,
		phone: true,
		email: true,
		checkbox: true,
		company: true,
		description: true,
	})

	const requestForm = () => {
		const data = {
			name: validate('name', name) ? name : '',
			company: validate('company', company) && company,
			phone: validate('phone', phone) ? phone : '',
			email: validate('email', email) ? email : '',
			description: validate('description', description) && description,
		}

		if (!data.name || !data.phone || !data.email || !checkbox) {
			setErrorData({
				name: data.name ? true : false,
				phone: data.phone ? true : false,
				email: data.email ? true : false,
				checkbox: checkbox ? true : false,
				company: data.company === '' || data.company ? true : false,
				description: data.description === '' || data.description ? true : false,
			})

			showMessage(dispatch, { code: 'error', msg: 'Fill all necessary fields' })
		} else {
			dispatch(addRequest(data, true))
		}
	}

	useEffect(() => {
		if (result) {
			setName('')
			setContactInput('')
			setExtraInput('')
			setLinkInput('')
			setTextInput('')
			setCheckbox(false)
			setErrorData({
				name: true,
				phone: true,
				email: true,
				checkbox: true,
				company: true,
				description: true,
			})
		}
	}, [result])

	return (
		<div className="services-page">
			{/* header */}
			<PagesHeader image={background} title={'SERVICES'}>
				<p>
					To realize your project, we offer you more than 4000 professionals with different
					experience and guaranteed qualification, who can work in dedicated teams and carry out a
					full cycle of project implementation.
				</p>
			</PagesHeader>
			<Brands eng />
			{/* command-block */}
			<div className="command-block" id="team">
				<div className="container">
					<div className="command-block__info-block">
						<div className="command-block__title h1">DEDICATED TEAM</div>
						<div className="services-page__image mobile-img">
							<picture>
								<source srcSet={commandImg} media="(max-width: 1440px)" />
								<source srcSet={commandImg} />
								<img srcSet={commandImg} alt="command" className="why__img" />
							</picture>
							<div className="blur" style={{ backgroundImage: `url(${commandImg})` }}></div>
						</div>
						<div className="command-block__text services-page__text">
							<span>
								<p>
									Our company offers you a flexible model of cooperation. We will choose a
									specialist or a multifunctional team that will be seamlessly combined with your
									internal team and will meet all your requirements.
								</p>
								<p>Manage your team, and we cover all infrastructure and administrative support.</p>
								<p>
									We can also provide our administrative specialists until your project is
									completed.
								</p>
							</span>
						</div>
					</div>
					<div className="services-page__image">
						<picture>
							<source srcSet={commandImg} media="(max-width: 1440px)" />
							<source srcSet={commandImg} />
							<img srcSet={commandImg} alt="command" className="why__img" />
						</picture>
						<div className="blur" style={{ backgroundImage: `url(${commandImg})` }}></div>
					</div>
					<div className="command-block__specialization-areas">
						<div className="command-block__subtitle services-page__subtitle">
							<span>EXPERTISE IN</span>
						</div>
						<div className="specialization-areas__block">
							{EN_SERVICE.special.map((item) => (
								<SpecializationBox key={`item-${item.title}`} title={item.title} icon={item.icon} />
							))}
						</div>
					</div>
				</div>
			</div>
			{/* outsourcing */}
			<div className="outsourcing-block" id="ousource">
				<div className="container">
					<div className="outsourcing-block__info-block">
						<div className="outsourcing-block__title h1">
							<span>OUTSOURCING</span>
						</div>
						<div className="outsourcing-block__text services-page__text">
							<span>
								<p>
									Our specialists have all the necessary qualifications and can elaborate an
									individual solution for any project.
								</p>
								<p>
									IT outsourcing can help to set up business processes. You will be able to achieve
									your goals faster and at a lower cost by transferring authority to professionals
									who can handle the difficult tasks and bring the project to a successful
									conclusion.
								</p>
								<p>
									Also you will not have to deal with employment and workplace preparation - we will
									take care of all the documents and infrastructure.
								</p>
							</span>
						</div>
						<div className="services-page__image">
							<picture>
								<source srcSet={outsourcingImg} media="(max-width: 1440px)" />
								<source srcSet={outsourcingImg} />
								<img srcSet={outsourcingImg} alt="command" className="why__img" />
							</picture>
							<div className="blur" style={{ backgroundImage: `url(${outsourcingImg})` }}></div>
						</div>
					</div>
					<div className="outsourcing-block__technologies">
						<div className="outsourcing-block__subtitle services-page__subtitle">
							<span>TECHNOLOGY STACK</span>
						</div>
						<div className="outsourcing-block__technologies-block">
							{EN_SERVICE.tech.map((item, i) => (
								<div key={`tech-${i}`} className="technologies__item">
									<div className="technologies__item-icon-wrapper">
										<img
											className="technologies__item-icon"
											src={item.icon}
											alt={`${item.title}`}
										/>
									</div>
									<div className="technologies__item-title">
										<span>{item.title}</span>
									</div>
								</div>
							))}
							<div className="technologies-block__last-item">
								<span>more</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* create-team__block */}
			<div className="create-team__block">
				<XLine
					title={'BUILD YOUR OWN TEAM FROM 4,365 SPECIALISTS'}
					button={'BUILD A TEAM'}
					openPopup={() => history.push('/team')}
				/>
			</div>
			{/* equipment design */}
			<div className="equipment-design__block" id="dev">
				<div className="container">
					<div className="equipment-design__info-block">
						<h2 className="equipment-design-title h1">
							<span>Development of hardware </span>
						</h2>
						<div className="equipment-design__text services-page__text">
							<span>
								Are you planning a project where you need non-standard equipment? Our team is
								engaged in constructing and manufacturing up-to-date hardware taking into account
								all your preferences.
								<p className="services-page__text-bold">
									Each project is unique and developed for the specific technical task for a
									customer.
								</p>
								We solve issues in an integrated way and manage the project on a turnkey basis.
							</span>
						</div>
					</div>
					<div className="services-page__image">
						<picture>
							<source srcSet={equipmentImg} media="(max-width: 1440px)" />
							<source srcSet={equipmentImg} />
							<img srcSet={equipmentImg} alt="command" className="why__img" />
						</picture>
						<div className="blur" style={{ backgroundImage: `url(${equipmentImg})` }}></div>
					</div>
				</div>
			</div>
			{/* selection process */}
			<div className="selection-process__block">
				<div className="container">
					<div className="selection-process-title h1">
						<span>SELECTION PROCESS</span>
					</div>
					<div className="selection-process__items-block">
						{EN_SERVICE.selection.map((item, i) => (
							<div className="selection-process__item" key={`selection-${i}`}>
								<div className="selection-process__item-icon">
									<img src={item.icon} alt="process" />
								</div>
								<div className="selection-process__item-title">
									<span>{item.text}</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<ContactFooter
				eng
				title={'CONTACT US'}
				requestForm={requestForm}
				name={name}
				setName={setName}
				contactInput={phone}
				setContactInput={setContactInput}
				extraInput={company}
				setExtraInput={setExtraInput}
				linkInput={email}
				setLinkInput={setLinkInput}
				textInput={description}
				setTextInput={setTextInput}
				checkbox={checkbox}
				setCheckbox={setCheckbox}
				errorData={errorData}
				map
			/>
		</div>
	)
})

EnServices.propTypes = {
	dispatch: PropTypes.func,
	store: PropTypes.object,
}
