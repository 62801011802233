import React, { useRef, useEffect } from 'react'
import cn from 'classnames'

import { delMessage } from '../../../store/reducers/global-messages/global-messages'
import { useDispatch } from 'react-redux'

export const GlobalMessage = React.memo(({ message = {} }) => {
	const dispatch = useDispatch()
	const messageRef = useRef()

	const { text, type } = message

	useEffect(() => {
		const timer = setTimeout(() => {
			messageRef.current.classList.remove('visible')
		}, 5000)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(delMessage({ type }))
		}, 5150)

		return () => {
			clearTimeout(timer)
		}
	}, [dispatch, type])

	return (
		<div ref={messageRef} className={cn('global-message visible', { [`${type}`]: type })}>
			<span>{text}</span>
		</div>
	)
})
