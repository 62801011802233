import { createSlice } from '@reduxjs/toolkit'

import eventsApi from '../../../api/events-api'

const initialState = {
	isLoading: false,
	events: [],
}

const mainEvents = createSlice({
	name: 'mainEvents',
	initialState,
	reducers: {
		setEvents: (state, action) => {
			state.events = action.payload.events
		},
	},
})

const { reducer, actions } = mainEvents

export const { setEvents } = actions

// получение списка последний событий для главной

export const requestEvents = () => async (dispatch) => {
	const res = await eventsApi.requestEvents(0, 3)
	if (res.success) {
		dispatch(setEvents({ events: res.result.events }))
	}
}

export default reducer
