import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ArrowButton } from '../../components/Buttons/ArrowButton/ArrowButton'
import { Brands } from '../../components/Brands/Brands'
import { ArrowLink } from '../../components/Buttons/ArrowLink/ArrowLink'
import { LinkBox } from '../../components/InfoBoxes/LinkBox/LinkBox'
import { ContactFooter } from '../../components/ContactFooter/ContactFooter'
import { CreateTeam } from '../../components/Buttons/CreateTeam/CreateTeam'
import { BlueButton } from '../../components/Buttons/BlueButton/BlueButton'
import { TechnologyCard } from '../../components/TechnologyCard/TechnologyCard'

import whyPic from '../../assets/images/main-why.jpg'
import find from '../../assets/images/main-find.jpg'
import community from '../../assets/images/main-community.jpg'

import { requestEvents } from '../../store/reducers/main/main'
import { addRequest } from '../../store/reducers/requests/requests'
import { getEvents } from '../../store/reducers/main/main-selectors'
import { getResult } from '../../store/reducers/requests/requests-selector'
import { showMessage } from '../../store/reducers/functions'

import { MAIN } from '../../StaticInfo/StaticInfo'

import { validate } from '../functions'
import { url } from '../../api/api'

import './main.scss'

export const Main = React.memo(({ open }) => {
	const dispatch = useDispatch()
	const events = useSelector(getEvents)
	const result = useSelector(getResult)

	const deviceWidth = document.body.clientWidth

	const [name, setName] = useState('')
	const [phone, setContactInput] = useState('')
	const [company, setExtraInput] = useState('')
	const [email, setLinkInput] = useState('')
	const [description, setTextInput] = useState('')
	const [checkbox, setCheckbox] = useState(false)

	const [openTech, setOpenTech] = useState(false)

	const [errorData, setErrorData] = useState({
		name: true,
		phone: true,
		email: true,
		checkbox: true,
		company: true,
		description: true,
	})

	const requestForm = () => {
		const data = {
			name: validate('name', name) ? name : '',
			company: validate('company', company) && company,
			phone: validate('phone', phone) ? phone : '',
			email: validate('email', email) ? email : '',
			description: validate('description', description) && description,
		}

		if (!data.name || !data.phone || !data.email || !checkbox) {
			setErrorData({
				name: data.name ? true : false,
				phone: data.phone ? true : false,
				email: data.email ? true : false,
				checkbox: checkbox ? true : false,
				company: data.company === '' || data.company ? true : false,
				description: data.description === '' || data.description ? true : false,
			})

			showMessage(dispatch, { code: 'error', msg: 'Заполните все необходимые поля' })
		} else {
			dispatch(addRequest(data))
		}
	}

	useEffect(() => {
		dispatch(requestEvents())
	}, [dispatch])

	useEffect(() => {
		if (result) {
			setName('')
			setContactInput('')
			setExtraInput('')
			setLinkInput('')
			setTextInput('')
			setCheckbox(false)
			setErrorData({
				name: true,
				phone: true,
				email: true,
				checkbox: true,
				company: true,
				description: true,
			})
		}
	}, [result])

	return (
		<div className="main-page">
			<section className="main-header">
				<div className="container">
					<div className="main-header__wrapper">
						<div>
							<h1 className="h1 main__title">
								Подберем команду <span className="nonbreak">IT-специалистов</span>
							</h1>
							<div className="text1 main-header__text">Соберем команду для проекта за 10 дней</div>
						</div>
						<ArrowButton
							text={'Узнать условия'}
							color={'blue'}
							position={'left'}
							openPopup={open}
						/>
					</div>
				</div>
				<Brands />
			</section>
			<section className="main-who-we-are">
				<div className="container">
					<h2 className="main__title h1">кто мы?</h2>
					<div className="main-who-we-are__wrapper">
						<div className="main-who-we-are__left-block">
							<div className="main-who-we-are__text title-text">
								X-Technology - техническая компания, которая сможет реализовать Ваш проект любого
								уровня сложности.
							</div>
							<div className="main-who-we-are__box adaptive">
								<div className="main-who-we-are__text text2">
									<p>Мы работаем в трех направлениях.</p>
									<div className="main-who-we-are__text-subtitle">Аутсорсинг:</div>
									<p>
										мы поможем Вам реализовать свой проект, передав полномочия профессионалам и
										оптимизируя расходы требуемых ресурсов.
									</p>
									<div className="main-who-we-are__text-subtitle">Выделенная команда:</div>
									<p>
										мы предоставим Вам возможность подобрать команду под Ваш проект, а также возьмем
										на себя всю его административную и техническую поддержку.
										<span>
											Помимо этого, мы можем подобрать квалифицированных специалистов к Вам в штат.
										</span>
									</p>
									<div className="main-who-we-are__text-subtitle">Разработка оборудования:</div>
									<p>
										наша команда занимается проектированием и разработкой современной электроники и
										IoT с учетом всех Ваших пожеланий.
										<span>
											Каждый день мы совершенствуем свои знания, чтобы становиться лучше, чем вчера.
											Создавайте свои уникальные продукты с компанией X-Technology.
										</span>
									</p>
								</div>
							</div>
							<div className="main-who-we-are__img">
								<iframe
									className="who-we-are-video"
									title="X-cluster"
									src="https://www.youtube.com/embed/XKGEx2tPN1Y"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
								<div className="blur" style={{ backgroundImage: `url(${community})` }}></div>
							</div>
							{/* <div className="main-who-we-are__quote">
								X-Technology: <br /> One step to make your dreams come true
							</div> */}
						</div>
						<div className="main-who-we-are__box">
							<div className="main-who-we-are__text text2">
								<p>Мы работаем в трех направлениях.</p>
								<div className="main-who-we-are__text-subtitle">Аутсорсинг:</div>
								<p>
									мы поможем Вам реализовать свой проект, передав полномочия профессионалам и
									оптимизируя расходы требуемых ресурсов.
								</p>
								<div className="main-who-we-are__text-subtitle">Выделенная команда:</div>
								<p>
									мы предоставим Вам возможность подобрать команду под Ваш проект, а также возьмем
									на себя всю его административную и техническую поддержку.
									<span>
										Помимо этого, мы можем подобрать квалифицированных специалистов к Вам в штат.
									</span>
								</p>
								<div className="main-who-we-are__text-subtitle">Разработка оборудования:</div>
								<p>
									наша команда занимается проектированием и разработкой современной электроники и
									IoT с учетом всех Ваших пожеланий.
									<span>
										Каждый день мы совершенствуем свои знания, чтобы становиться лучше, чем вчера.
										Создавайте свои уникальные продукты с компанией X-Technology.
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="main-services">
				<div className="container">
					<h2 className="main__title h1">Сервисы</h2>
					<div className="main-services__wrapper">
						{MAIN.service.map((item) => (
							<LinkBox
								key={item.title}
								image={item.img}
								title={item.title}
								text={item.text}
								link={`/service#${item.id}`}
								linkText={'Узнать больше'}
							/>
						))}
					</div>
				</div>
			</section>
			<section className="why">
				<div className="container">
					<div className="why__wrapper">
						<div className="why__box">
							<h2 className="main__title h1">Почему мы?</h2>
							<ul className="why__list text2">
								<li className="why__item">
									Мы оказываем <strong>полный спектр услуг</strong>: разработка под ключ
									программного обеспечения, разработка железа, поиск ИТ-персонала и создание
									выделенных ИТ-команд;
								</li>
								<li className="why__item">
									<strong>Быстро и точно</strong> оценим ваш проект по срокам и стоимости.
								</li>
								<li className="why__item">
									У нас есть <strong>собственная команда разработчиков, инженеров и учёных </strong>
									для быстрого старта разработки Вашего проекта;
								</li>
								<li className="why__item">
									Все <strong>юридические, административные</strong> и <strong>кадровые </strong>
									аспекты мы берём на себя;
								</li>
								<li className="why__item">
									Начинаем подбирать команду через <strong>30 минут</strong> после заключения
									договора;
								</li>
								<li className="why__item">
									Рекрутеры <strong>X-Work</strong> проходят интенсивное IT-обучение, что позволяет
									им говорить с разработчиками на одном языке и проводить качественный технический
									скрининг кандидатов;
								</li>
								<li className="why__item">
									С нами сотрудничают <strong> &gt;100</strong> партнёров, которые ежедневно
									рекомендуют заинтересованных кандидатов на вакансии нашего агентства;
								</li>
								<li className="why__item">
									У нас есть эксклюзивный контракт с IT-сообществом <strong>X-Cluster</strong>:
									доступ к закрытой базе IT-специалистов и партнёрской программе рекомендаций
									кандидатов.
								</li>
							</ul>
						</div>
						<div className="why__box">
							<picture>
								<source srcSet={whyPic} media="(max-width: 768px)" />
								<source srcSet={whyPic} media="(max-width: 1024px)" />
								<source srcSet={whyPic} />
								<img srcSet={whyPic} alt="why" className="why__img" />
							</picture>
							<div className="blur" style={{ backgroundImage: `url(${whyPic})` }}></div>
						</div>
					</div>
				</div>
			</section>
			<section className="main-team">
				<div className="container">
					<div className="main-team__title">
						<h2 className="main__title h1">Создай свою команду</h2>
						<div className="main-team__title-text text2">
							Мы подберем команду, учитывая все тонкости вашего проекта. В&nbsp;команде{' '}
							<strong>X-Сluster</strong> больше 4000 профессионалов с разным опытом и квалификацией.
						</div>
					</div>
					<div className="main-team__wrapper">
						<div className="main-team__scroll-wrapper">
							<div className="main-team__scroll-container">
								<div className="main-team__box">
									{MAIN.team.map((item, index) => (
										<div className="main-team__item" key={`${item.title}`}>
											<div
												className="main-team__item-img"
												style={{ backgroundImage: `url(${item.img})` }}
											>
												<img src={item.img} alt="Разработчик" />
												<div className="name h3">{item.title}</div>
											</div>
											<div className="main-team__item-title">
												<span>{item.type}</span>
											</div>
											<ul className="main-team__item-list">
												{item.skills.map((el, i) => (
													<li key={`${el}-${i}`} className="main-team__item-skill text3">
														{el}
													</li>
												))}
											</ul>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="main-team__text text1">
							...и <strong>4368</strong> других разработчиков
						</div>
						<CreateTeam text={'Создать команду'} />
					</div>
				</div>
			</section>
			<section className="main-tech">
				<div className="container">
					<h2 className="main__title h1">Технологии</h2>
					<div className="main-tech__wrapper">
						{MAIN.tech.map((item, i) => (
							<TechnologyCard
								key={`tech-${i}`}
								index={i}
								icon={item.icon}
								title={item.title}
								body={item.skills}
								deviceWidth={deviceWidth}
								active={openTech}
								onClick={() => setOpenTech(i)}
							/>
						))}
					</div>
				</div>
			</section>
			<section className="main-find">
				<div className="container">
					<div className="main-find__wrapper">
						<div className="main-find__img">
							<picture>
								<source srcSet={find} media="(max-width: 1024px)" />
								<source srcSet={find} />
								<img srcSet={find} alt="why" className="why__img" />
							</picture>
							<div className="blur" style={{ backgroundImage: `url(${find})` }}></div>
						</div>
						<div className="main-find__box">
							<div className="flex-container">
								<div>
									<h2 className="main__title h1">Где мы ищем?</h2>
									<p className="main-find__text">
										Мы используем ~500 инструментов для поиска специалистов. А также у нас есть
										эксклюзивный контракт с IT-сообществом X-Cluster: доступ к закрытой базе
										IT-специалистов и партнёрской У нас есть эксклюзивный контракт с IT-сообществом
										X-Cluster: доступ к закрытой базе .
									</p>
								</div>
								<BlueButton text={'Обсудить проект'} openPopup={open} />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="main-selection">
				<div className="container">
					<h2 className="main__title h1">Процесс подбора</h2>
					<div className="main-selection__wrapper">
						{MAIN.selection.map((item, i) => (
							<div className="main-selection__box" key={`selection-${i}`}>
								<div className="main-selection__title">{item.num}</div>
								<div
									className="main-selection__text"
									dangerouslySetInnerHTML={{ __html: item.text }}
								></div>
							</div>
						))}
					</div>
				</div>
			</section>
			<section className="main-community">
				<div className="container">
					<div className="main-community__wrapper">
						<div className="main-community__box">
							<h2 className="main__title h1">Сообщество</h2>
							<div className="main-community__text text2">
								<p>
									Это <strong>IT-сообщество</strong>, нацеленное на создание комфортной среды для
									развития IT-специалистов любого уровня и развития IT-индустрии в целом.
								</p>
								<p>
									Мы хотим создать комфортные условия для специалистов путём их комплексного
									развития в соответствии с текущими перспективами и потребностями рынка и вывести
									<strong> IT-сообщество</strong> на новый уровень.
								</p>
								<p>
									Для этого мы проводим мероприятия, предлагаем практику и работу в IT-компаниях и
									поддерживаем проекты.
								</p>
								<p>
									Участникам сообщества мы предоставляем доступ к инфраструктуре - специальной
									платформе, где участник сможет узнавать последние новости, изучать образовательные
									материалы, забронировать бесплатное место в коворкинге.
								</p>
								<p>
									В <strong>X-Cluster</strong> действует своя бонусная система, где за любую
									активность участники получают баллы, которые можно обменивать на различные бонусы
									от партнёров (фирменные вещи, электроника, услуги и т.д.).
								</p>
							</div>
						</div>
						<div className="main-community__img">
							<iframe
								className="community-video"
								title="X-cluster"
								src="https://www.youtube.com/embed/Js5QpWBdBAw"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
							<div className="blur" style={{ backgroundImage: `url(${community})` }}></div>
						</div>
					</div>
				</div>
			</section>
			{!!events.length && (
				<section className="main__event">
					<div className="container">
						<div className="main__event__wrapper">
							<div className="main__event__title-wrapper">
								<h2 className="main__title h2">События</h2>
								<ArrowLink text={'Все события'} path={'/community'} />
							</div>
							<div className="main__event__scroll-wrapper">
								<div className="main__event__scroll-container">
									<div className="main__event__box">
										{events.map((item) => (
											<LinkBox
												key={item.id}
												image={`${url}/static/${item.preImage}`}
												title={item.title}
												text={item.descrition}
												link={`/community/${item.id}`}
												linkText={'Читать далее'}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			<ContactFooter
				title={'Cвяжитесь с\u00a0нами'}
				requestForm={requestForm}
				name={name}
				setName={setName}
				contactInput={phone}
				setContactInput={setContactInput}
				extraInput={company}
				setExtraInput={setExtraInput}
				linkInput={email}
				setLinkInput={setLinkInput}
				textInput={description}
				setTextInput={setTextInput}
				checkbox={checkbox}
				setCheckbox={setCheckbox}
				errorData={errorData}
				map
			/>
		</div>
	)
})
