import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "./Filter/Filter";
import { Preloader } from "../../../components/Preloader/Preloader";

import {
  getLocalList,
  getFilters,
  getIsLoading,
} from "../../../store/reducers/constructor/constructor-selectors";
import {
  setFilters,
  setUsers,
} from "../../../store/reducers/constructor/constructor";

import "./TeamFilter.scss";

export const TeamFilter = React.memo(({ sidebar, eng }) => {
  const dispatch = useDispatch();

  const localList = useSelector(getLocalList);
  const isLoading = useSelector(getIsLoading);
  const { skills, specializations } = useSelector(getFilters);
  const [showFilter, setShowFilter] = useState(false);

  // фильтр специализаций

  const localSpecList = localList.specializations
    ?.filter(({ key }) => key !== "blockchain_dev")
    .map((item) => [item.key, item.value]);

  const activeCheckbox = (value) => {
    return specializations.includes(value) ? true : false;
  };

  const handleSpecialization = (value) => () => {
    dispatch(
      setFilters({
        specializations: specializations.includes(value)
          ? specializations.filter((item) => item !== value)
          : [...specializations, value],
        offset: 0,
      })
    );
    dispatch(setUsers({ users: [] }));
  };

  // фильтр технологий

  const localTechList = localList.techs?.filter(
    ({ title }) => title !== "Blockchain"
  );

  const activeSkills = (value) => {
    return skills.includes(value) ? true : false;
  };
  const handleSkills = (value) => () => {
    dispatch(
      setFilters({
        skills: skills.includes(value)
          ? skills.filter((item) => item !== value)
          : [...skills, value],
        offset: 0,
      })
    );

    dispatch(setUsers({ users: [] }));
  };

  return (
    <div className={`team__filter ${sidebar && showFilter ? "show" : "hide"}`}>
      {sidebar && (
        <button
          className="team__filter-click"
          onClick={() => setShowFilter(!showFilter)}
        ></button>
      )}
      {!!isLoading && !Object.keys(localList).length ? (
        <Preloader />
      ) : (
        <Filter
          eng={eng}
          localSpecList={localSpecList}
          localTechList={localTechList}
          handleSpecialization={handleSpecialization}
          handleSkills={handleSkills}
          activeCheckbox={activeCheckbox}
          activeSkills={activeSkills}
          place={"filter"}
        />
      )}
    </div>
  );
});
