import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { GlobalMessage } from './GlobalMessage/GlobalMessage'

import { getGlobalMessages } from '../../store/reducers/global-messages/global-messages-selector'

import './GlobalMessages.scss'

export const GlobalMessages = React.memo(() => {
	const messages = useSelector(getGlobalMessages)

	return (
		<div className={cn('global-messages', { visible: messages.length })}>
			{messages.map((message) => (
				<GlobalMessage key={message.type} message={message} />
			))}
		</div>
	)
})
