import React from "react";

import numbers from "../../assets/images/icons/2099.svg";
import clover from "../../assets/images/icons/clover.svg";
// import basicChain from '../../assets/images/icons/basicchain.svg'
import ctrlToGo from "../../assets/images/icons/ctrltogo.svg";

import "./brands.scss";

export const Brands = React.memo(({ eng }) => (
  <div className="brands">
    <div className="brands__blur">
      <div className="container">
        <div className="brands__wrapper">
          <div className="brands__title">
            <span>
              {eng
                ? "TRUSTED BY LEADING BRANDS AND STARTUPS"
                : "Нам доверяют лидирующие бренды и стартапы"}
            </span>
          </div>
          <div className="brands__scroll-wrapper">
            <div className="brands__scroll-container">
              <div className="brands__box">
                <div className="brands__list">
                  <img src={ctrlToGo} alt="ctrlToGo" className="brands__img" />
                  <img src={clover} alt="clover" className="brands__img" />
                  <img
                    src={numbers}
                    alt="2099"
                    className="brands__img extra-sm"
                  />
                  {/* <img src={basicChain} alt="basicChain" className="brands__img" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));
