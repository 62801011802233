import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import cn from "classnames";

import { TeamFilter } from "../../pages/Team/TeamFilter/TeamFilter";
import { Popup } from "../../components/Popup/Popup";
// import { LangDropdown } from "./LangDropdown/LangDropdown";

import logo from "../../assets/images/icons/technology.svg";

import "./header.scss";

export const Header = React.memo(({ open, lang, setLang }) => {
  const location = useLocation();

  const [isBurgerActive, ActivateBurger] = useState(false);
  const [filter, setFilter] = useState(false);

  const eng = lang === "EN";

  if (isBurgerActive) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = null;
  }

  const deviceWidth = document.body.clientWidth;

  const pathname = location.pathname;

  useEffect(() => {
    setFilter(false);
  }, [pathname]);

  return (
    <header className="header">
      {filter && (
        <Popup show={filter} onClose={() => setFilter(false)}>
          <TeamFilter />
        </Popup>
      )}
      <div className="header-container">
        <div className="header__logo">
          <NavLink to="/">
            {/* <div className="logo-suffix"> */}
            <img src={logo} alt="x-tech" />
            {/* <span>FZCO</span> */}
            {/* </div> */}
          </NavLink>
        </div>
        {deviceWidth < 768 && pathname === "/team" && (
          <button
            className="header__filter text2"
            onClick={() => setFilter(true)}
          >
            {eng ? "Filters" : "Фильтрация"}
          </button>
        )}
        <div className={cn({ "burger-menu__wrapper": isBurgerActive })}>
          <nav
            className={cn("header__nav", { "active-burger": isBurgerActive })}
          >
            <ul className="header__nav-wrapper">
              <li className="header__nav-item">
                <NavLink
                  onClick={() => ActivateBurger(false)}
                  to="/service"
                  className="header__nav-link text2"
                  activeClassName="header__nav-link-active"
                >
                  <span>{eng ? "Services" : "Сервисы"}</span>
                </NavLink>
              </li>
              {/* <li className="header__nav-item">
                <NavLink
                  onClick={() => ActivateBurger(false)}
                  to="/community"
                  className="header__nav-link text2"
                  activeClassName="header__nav-link-active"
                >
                  <span>{eng ? "Community" : "Сообщество"}</span>
                </NavLink>
              </li> */}
              <li className="header__nav-item">
                <NavLink
                  onClick={() => ActivateBurger(false)}
                  to="/career"
                  className="header__nav-link text2"
                  activeClassName="header__nav-link-active"
                >
                  <span>{eng ? "Career" : "Карьера"}</span>
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  onClick={() => ActivateBurger(false)}
                  to="/company"
                  className="header__nav-link text2"
                  activeClassName="header__nav-link-active"
                >
                  <span>{eng ? "About us" : "Компания"}</span>
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  onClick={() => ActivateBurger(false)}
                  to="/team"
                  className="header__nav-link text2"
                  activeClassName="header__nav-link-active"
                >
                  <span>
                    {eng ? "Team constructor" : "Конструктор команды"}
                  </span>
                </NavLink>
              </li>
            </ul>
            <button className="header__button h3" onClick={open}>
              {eng ? "Learn more" : "Узнать условия"}
            </button>
          </nav>
        </div>
      </div>

      <button className="header__button h3" onClick={open}>
        {eng ? "Learn more" : "Узнать условия"}
      </button>

      {/* <LangDropdown lang={lang} setLang={setLang} /> */}
      <button
        onClick={() => ActivateBurger(!isBurgerActive)}
        className={cn("open__burger", { active: isBurgerActive })}
      >
        <span></span>
      </button>
    </header>
  );
});

Header.propTypes = {
  open: PropTypes.func,
  lang: PropTypes.node,
  setLang: PropTypes.func,
};
