import React from "react";
import "./policy.scss";

export const EnPersonal = React.memo(() => (
  <section className="policy">
    <div className="policy__container">
      <h2 className="h2 policy__title">Data Processing Agreement</h2>
      <div className="text2">
        <div className="policy__text">
          <h3 className="h3">Section A – Key Terms</h3>
          <p className="text2">
            <b>Controller(s)</b>
            <br />
            X-Technology-FZCO, Dubai Silicon Oasis, DDP, Building A1, Dubai,
            United Arab Emirates
            <br />
            Contact: Aleksandr Ivanov (manager@x-tek.ru)
          </p>
          <p className="text2">
            <b>Processor(s)</b>
            <br />
            X-Technology-FZCO, Dubai Silicon Oasis, DDP, Building A1, Dubai,
            United Arab Emirates
            <br />
            Contact: Aleksandr Ivanov (manager@x-tek.ru)
            <br />
            (together with the Controller(s) the "<b>Parties</b>")
          </p>
          <p className="text2">
            <b>Processing Purpose</b>
            <br />
          </p>
          <ul>
            <li>
              identification of the party within the framework of rendering the
              Service;{" "}
            </li>
            <li>providing the user with personalized Services; </li>
            <li>
              improving the quality of the Services and developing new ones;{" "}
            </li>
            <li>
              conducting statistical and other studies based on anonymised data;{" "}
            </li>
            <li>
              providing the user’s personal data to the right holders,
              distributors or resellers of the software for the purpose of
              registering the software in the name of the user or the
              organization the user represents;{" "}
            </li>
            <li>
              providing users of the Site with safe and convenient functionality
              for its use, effective display of information.
            </li>
          </ul>
          <p className="text2">
            <b>Duration of processing</b>
            <br />
            Only as long as necessary for the Processing Purpose
          </p>
          <p className="text2">
            <b>Categories of data subjects</b>
            <br />
            Only as long as necessary for the Processing Purpose
          </p>
          <p className="text2">
            <b>Categories of personal data</b>
            <br />
            Birthday/age, Contact data (e-mail, phone), IP address, Name, and
            Nationality
          </p>
          <p className="text2">
            <b>Place of storage & processing</b>
            <br />
            At the business address of the data processor and its approved
            sub-processors as indicated in this Data Processing Agreement
          </p>
          <p className="text2">
            <b>On-premise audits</b>
            <br />
            No
          </p>
          <p className="text2">
            <b>Sub-processors</b>
            <br />
            Sub-processors are listed here: https://x-technology.org/
          </p>
        </div>
        <div className="policy__text">
          <h3 className="h3">Section B – Legal Terms</h3>
          <p className="text2">
            <b>1. Purpose and scope</b>
          </p>
          <ul>
            <li>
              The purpose of this Data Processing Agreement (the "<b>DPA</b>")
              is to ensure compliance with Article 28(3) and (4) of the EU
              General Data Protection Regulation ("<b>GDPR</b>").
            </li>
            <li>
              This DPA applies with respect to the processing of personal data
              as specified in Section A.
            </li>
          </ul>
          <p className="text2">
            <b>2. Interpretation</b>
          </p>
          <ul>
            <li>
              Where this DPA uses the terms defined in the GDPR, as applicable,
              those terms shall have the same meaning as in that law.
            </li>
            <li>
              This DPA shall be read and interpreted in the light of the
              provisions of the GDPR, as applicable.
            </li>
            <li>
              These Clauses shall not be interpreted in a way that conflicts
              with rights and obligations provided for in the GDPR, as
              applicable, or prejudices the fundamental rights or freedoms of
              the data subjects.
            </li>
          </ul>
          <p className="text2">
            <b>3. Hierarchy</b>
            <br />
            In the event of a conflict between this DPA and the provisions of
            any other agreement between the Parties existing at the time when
            this DPA are agreed or entered into thereafter, this DPA shall
            prevail, except where explicitly agreed otherwise in text form.
          </p>
          <p className="text2">
            <b>4. Description of processing(s)</b>
            <br />
            The details of the processing operations, and in particular the
            categories of personal data and the purposes of processing for which
            the personal data is processed on behalf of the data controller, are
            specified in Section A.
          </p>
          <p className="text2">
            <b>5. Obligations of the Parties</b>
          </p>
          <div className="shift">
            <p className="text2">
              <b>5.1. General</b>
            </p>
            <ul>
              <li>
                The data processor shall process personal data only on
                documented instructions from the data controller, unless
                required to do so by Union. Such instructions are specified in
                Section A. Subsequent instructions may also be given by the data
                controller throughout the duration of the processing of personal
                data. Such instructions shall always be documented.
              </li>
              <li>
                The data processor shall immediately inform the data controller
                if instructions given by the data controller, in the opinion of
                the data processor, infringe applicable Union data protection
                provisions.
              </li>
            </ul>
            <p className="text2">
              <b>5.2. Purpose limitation</b>
              <br />
              The data processor shall process the personal data only for the
              specific purpose(s) of the processing, as set out in Section A.
            </p>
            <p className="text2">
              <b>5.3. Erasure of return of data</b>
            </p>
            <ul>
              <li>
                Processing by the data processor shall only take place for the
                duration specified in Section A.
              </li>
              <li>
                Upon termination of the provision of personal data processing
                services, the data processor shall delete all personal data
                processed on behalf of the data controller and certify to the
                data controller that it has done so and delete existing copies
                unless Union requires storage of the personal data.
              </li>
            </ul>
            <p className="text2">
              <b>5.4. Security of processing</b>
            </p>
            <ul>
              <li>
                The data processor shall implement the technical and
                organizational measures specified in Section C to ensure the
                security of the personal data, including protection against
                accidental or unlawful destruction, loss, alteration,
                unauthorized disclosure or access to that data (personal data
                breach). In assessing the appropriate level of security, they
                shall in particular take due account of the risks involved in
                the processing, the nature of the personal data and the nature,
                scope, context and purposes of processing.
              </li>
              <li>
                In the event of a personal data breach concerning data processed
                by the data processor, it shall notify the data controller
                without undue delay and at the latest within 48 hours after
                having become aware of the breach. Such notification shall
                contain the details of a contact point where more information
                concerning the personal data breach can be obtained, a
                description of the nature of the breach (including, where
                possible, categories and approximate number of data subjects and
                data records concerned), its likely consequences and the
                measures taken or proposed to be taken to mitigate its possible
                adverse effects. Where, and insofar as, it is not possible to
                provide all information at the same time, the initial
                notification shall contain the information then available and
                further information shall be provided as it becomes available
                without undue delay.
              </li>
              <li>
                The data processor shall cooperate in good faith with and assist
                the data controller in any way necessary to enable the data
                controller to notify, where relevant, the competent data
                protection authority and the affected data subjects, taking into
                account the nature of processing and the information available
                to the data processor.
              </li>
              <li>
                The data processor shall grant access to the data to members of
                its personnel only to the extent strictly necessary for the
                implementation, management and monitoring of the contract. The
                data processor shall ensure that persons authorized to process
                the personal data received have committed themselves to
                confidentiality or are under an appropriate statutory obligation
                of confidentiality.
              </li>
              <li>
                If the processing involves personal data revealing racial or
                ethnic origin, political opinions, religious or philosophical
                beliefs, or trade union membership, genetic or biometric data
                for the purpose of uniquely identifying a natural person, data
                concerning health or a person’s sex life or sexual orientation,
                or data relating to criminal convictions and offences (special
                categories of data), the data processor shall apply specific
                restrictions and/or additional safeguards as reasonably required
                by the data controller from time to time.
              </li>
            </ul>
            <p className="text2">
              <b>5.5. Documentation and compliance</b>
            </p>
            <ul>
              <li>
                The Parties shall be able to demonstrate compliance with this
                DPA.
              </li>
              <li>
                The data processor shall deal promptly and properly with all
                reasonable inquiries from the data controller that relate to the
                processing under this DPA.
              </li>
              <li>
                The data processor shall make available to the data controller
                all information necessary to demonstrate compliance with the
                obligations set out in this DPA and that are stemming directly
                from the GDPR and at the data controller’s request, allow for
                and contribute to reviews of data files and documentation or of
                audits of the processing activities covered by these Clauses, in
                particular if there are indications of non-compliance.
              </li>
              <li>
                The data controller may choose to conduct the audit by itself,
                to mandate, at its own cost, an independent auditor or to rely
                on an independent audit mandated by the data processor. Where
                the data processor mandates an audit, it has to bear the costs
                of the independent auditor. The data controller's audit, access,
                and inspection rights under this Clause are limited to the data
                processor's records only (including inter-alia the registers of
                personal data processing activities, the registers of recipients
                of personal data) and does not apply to Processor’s physical
                premises. Any audit and request for information shall be limited
                to information necessary for the purposes of this DPA and shall
                give due regard to the data processor's confidentiality
                obligations and legitimate interest to protect business secrets.
              </li>
              <li>
                The data processor and data controller shall make the
                information referred to in this Clause, including the results of
                any audits, available to the competent supervisory authority on
                request if and to the extent required by the GDPR, as
                applicable.
              </li>
            </ul>
            <p className="text2">
              <b>5.6. Use of sub-processors</b>
            </p>
            <ul>
              <li>
                The data processor has the data controller’s general
                authorization for the engagement of sub-processors. The list of
                sub-processors of the data processor can be found in Section A.
                The data processor shall inform in text form the data controller
                of any intended changes to that list through the addition or
                replacement of sub-processors at least 30 days in advance,
                thereby giving the data controller the opportunity to object to
                such changes prior to the engagement of the concerned
                sub-processor(s). Such objection shall not be unreasonably
                raised. The Parties shall keep the list up to date..
              </li>
              <li>
                Where the data processor engages a sub-processor for carrying
                out specific processing activities (on behalf of the data
                controller), it shall do so by way of a contract which imposes
                on the sub-processor the same obligations as the ones imposed on
                the data processor under this DPA. The data processor shall
                ensure that the sub-processor complies with the obligations to
                which the data processor is subject pursuant to this DPA, the
                GDPR.
              </li>
              <li>
                The data processor shall provide, at the data controller’s
                request, a copy of such a sub-processor agreement and subsequent
                amendments to the data controller.
              </li>
              <li>
                The data processor shall remain fully responsible to the data
                controller for the performance of the sub-processor’s
                obligations under its contract with the data processor. The data
                processor shall notify the data controller of any failure by the
                sub-processor to fulfil its obligations under that contract.
              </li>
            </ul>
            <p className="text2">
              <b>5.7. International transfers</b>
            </p>
            <ul>
              <li>
                Any transfer of data to a "<b>Third Country</b>" (any country
                outside of the EU) or an international organization by the data
                processor shall be undertaken only if authorized in accordance
                with Section A and shall take place in compliance with Chapter V
                of the GDPR.
              </li>
              <li>
                The data controller agrees that where the data processor engages
                a sub-processor in accordance with Clause # for carrying out
                specific processing activities (on behalf of the data
                controller) in a Third Country and those processing activities
                involve transfer of personal data within the meaning of the
                GDPR, as applicable, the processor and the sub-processor may use
                standard contractual clauses adopted by the Commission on the
                basis of Article 46(2) of the GDPR in order to comply with the
                requirements of Chapter V of the GDPR, provided the conditions
                for the use of those clauses are met.
              </li>
            </ul>
          </div>
          <p className="text2">
            <b>6. Data Subject Rights</b>
          </p>
          <ul>
            <li>
              The data processor shall promptly notify the data controller about
              any request received directly from the data subject. It shall not
              respond to that request itself, unless and until it has been
              authorized to do so by the data controller
            </li>
            <li>
              The data processor shall assist the data controller in fulfilling
              its obligations to respond to data subjects’ requests for the
              exercise of their rights, namely:
              <ul>
                <li>
                  the right to be informed when personal data are collected from
                  the data subject,
                </li>
                <li>
                  the right to be informed when personal data have not been
                  obtained from the data subject,
                </li>
                <li>the right of access by the data subject,</li>
                <li>the right to rectification,</li>
                <li>the right to erasure (‘the right to be forgotten’),</li>
                <li>the right to restriction of processing,</li>
                <li>
                  the notification obligation rectification or erasure of
                  personal data or restriction of processing,
                </li>
                <li>the right to data portability,</li>
                <li>the right to object,</li>
                <li>
                  the right not to be subject to a decision based solely on
                  automated processing, including profiling.
                </li>
              </ul>
            </li>
            <li>
              In addition to the data processor’s obligation to assist the data
              controller pursuant to Clause 6(b), the data processor shall
              furthermore assist the data controller in ensuring compliance with
              the following obligations, taking into account the nature of the
              processing and the information available to the data processor:
            </li>
            <li>
              The obligation to notify a personal data breach to the competent
              supervisory authority without undue delay after having become
              aware of it, (unless the personal data breach is unlikely to
              result in a risk to the rights and freedoms of natural persons);
            </li>
            <li>
              the obligation to communicate without undue delay the personal
              data breach to the data subject, when the personal data breach is
              likely to result in a high risk to the rights and freedoms of
              natural persons;
            </li>
            <li>
              the obligation to carry out an assessment of the impact of the
              envisaged processing operations on the protection of personal data
              (a ‘data protection impact assessment’) where a type of processing
              is likely to result in a high risk to the rights and freedoms of
              natural persons;
            </li>
            <li>
              the obligation to consult the competent supervisory authority
              prior to processing where a data protection impact assessment
              indicates that the processing would result in a high risk in the
              absence of measures taken by the data controller to mitigate the
              risk.
            </li>
            <li>
              The Parties shall set out in Section C the appropriate technical
              and organizational measures by which the data processor is
              required to assist the data controller in the application of this
              Clause as well as the scope and the extent of the assistance
              required.
            </li>
          </ul>
          <p className="text2">
            <b>7. Notification of personal data breaches</b>
          </p>
          <ul>
            <li>
              In the event of a personal data breach, the data processor shall
              cooperate in good faith with and assist the data controller in any
              way necessary for the data controller to comply with its
              obligations under Articles 33 and 34 of the GDPR, as applicable,
              taking into account the nature of processing and the information
              available to the processor.
            </li>
            <li>
              The data processor shall assist the data controller in notifying
              the personal data breach to the competent supervisory authority,
              where relevant. The data processor shall be required to assist in
              obtaining in particular the following information which, pursuant
              to Article 33(3) of the GDPR, as applicable, shall be stated in
              the data controller’s notification:
              <ul>
                <li>
                  The nature of the personal data including where possible, the
                  categories and approximate number of data subjects concerned,
                  and the categories and approximate number of personal data
                  records concerned;
                </li>
                <li>the likely consequences of the personal data breach;</li>
                <li>
                  the measures taken or proposed to be taken by the data
                  controller to address the personal data breach, including,
                  where appropriate, measures to mitigate its possible adverse
                  effects.
                </li>
              </ul>
            </li>
          </ul>
          <p className="text2">
            <b>8. Termination</b>
          </p>
          <ul>
            <li>
              Without prejudice to any provisions of the GDPR, as applicable, in
              the event that the data processor is in breach of its obligations
              under this DPA, the data controller may instruct the data
              processor to temporarily suspend the processing of personal data
              until the latter complies with this DPA or the contract is
              terminated. The data processor shall promptly inform the data
              controller in case it is unable to comply with this DPA, for
              whatever reason.
            </li>
            <li>
              The data controller shall be entitled to terminate this DPA where:
              <ul>
                <li>
                  the processing of personal data by the data processor has been
                  temporarily suspended by the data controller pursuant to point
                  (a), data processor's breach is material, and compliance with
                  this DPA is not restored within a reasonable time and in any
                  event within one month;
                </li>
                <li>
                  the data processor is in substantial or persistent breach of
                  this DPA or its obligations under the GDPR, as applicable, and
                  such breach cannot be reasonably expected to be remedied;
                </li>
                <li>
                  the data processor fails to comply with a binding decision of
                  a competent court or the competent supervisory authority
                  regarding its obligations under this DPA or under the GDPR, as
                  applicable.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="policy__text">
          <h3 className="h3">Section C – TOMs</h3>
          <p className="text2">
            Description of the technical and organizational security measures
            implemented by the data processor(s):
          </p>
          <p className="text2">
            <b>1. Organizational security measures</b>
          </p>
          <div className="shift">
            <p className="text2">
              <b>1.1. Security Management</b>
            </p>
            <ul>
              <li>
                Security policy and procedures: The data processor has a
                documented security policy with regard to the processing of
                personal data.
              </li>
              <li>
                Roles and responsibilities:
                <ul>
                  <li>
                    Roles and responsibilities related to the processing of
                    personal data is clearly defined and allocated in accordance
                    with the security policy.
                  </li>
                  <li>
                    During internal re-organizations or terminations and change
                    of employment, revocation of rights and responsibilities
                    with respective hand-over procedures is clearly defined.
                  </li>
                </ul>
              </li>
              <li>
                Access Control Policy: Specific access control rights are
                allocated to each role involved in the processing of personal
                data, following the need-to-know principle.
              </li>
              <li>
                Resource/asset management: The data processor has a register of
                the IT resources used for the processing of personal data
                (hardware, software, and network). A specific person is assigned
                the task of maintaining and updating the register (e.g. IT
                officer).
              </li>
              <li>
                Change management: The data processor makes sure that all
                changes to the IT system are registered and monitored by a
                specific person (e.g. IT or security officer). Regular
                monitoring of this process takes place.
              </li>
            </ul>
            <p className="text2">
              <b>1.2. Incident response and business continuity</b>
            </p>
            <ul>
              <li>
                Incidents handling / Personal data breaches:
                <ul>
                  <li>
                    An incident response plan with detailed procedures is
                    defined to ensure effective and orderly response to
                    incidents pertaining personal data.
                  </li>
                  <li>
                    The data processor will report without undue delay to the
                    controller any security incident that has resulted in a
                    loss, misuse or unauthorized acquisition of any personal
                    data.
                  </li>
                </ul>
              </li>
              <li>
                Business continuity: The data processor has established the main
                procedures and controls to be followed in order to ensure the
                required level of continuity and availability of the IT system
                processing personal data (in the event of an incident/personal
                data breach).
              </li>
            </ul>
            <p className="text2">
              <b>1.3. Human resources</b>
            </p>
            <ul>
              <li>
                Confidentiality of personnel: The data processor ensures that
                all employees understand their responsibilities and obligations
                related to the processing of personal data. Roles and
                responsibilities are clearly communicated during the
                pre-employment and/or induction process.
              </li>
              <li>
                Training: The data processor ensures that all employees are
                adequately informed about the security controls of the IT system
                that relate to their everyday work. Employees involved in the
                processing of personal data are also properly informed about
                relevant data protection requirements and legal obligations
                through regular awareness campaigns.
              </li>
            </ul>
          </div>
          <p className="text2">
            <b>2. Technical security measures</b>
          </p>
          <div className="shift">
            <p className="text2">
              <b>2.1. Access control and authentication</b>
            </p>
            <ul>
              <li>
                An access control system applicable to all users accessing the
                IT system is implemented. The system allows creating, approving,
                reviewing, and deleting user accounts.
              </li>
              <li>
                The use of common user accounts is avoided. In cases where this
                is necessary, it is ensured that all users of the common account
                have the same roles and responsibilities.
              </li>
              <li>
                When granting access or assigning user roles, the “need-to-know
                principle” shall be observed in order to limit the number of
                users having access to personal data only to those who require
                it for achieving the Processor’s processing purposes.
              </li>
              <li>
                Where authentication mechanisms are based on passwords, the data
                processor requires the password to be at least eight characters
                long and conform to very strong password control parameters
                including length, character complexity, and non-repeatability.
              </li>
              <li>
                The authentication credentials (such as user ID and password)
                shall never be transmitted unprotected over the network.
              </li>
            </ul>
            <p className="text2">
              <b>2.2. Logging and monitoring</b>
              <br />
              Log files are activated for each system/application used for the
              processing of personal data. They include all types of access to
              data (view, modification, deletion).
            </p>
            <p className="text2">
              <b>2.3. Security of data at rest</b>
            </p>
            <ul>
              <li>
                Server/Database security:
                <ul>
                  <li>
                    Database and applications servers are configured to run
                    using a separate account, with minimum OS privileges to
                    function correctly.
                  </li>
                  <li>
                    Database and applications servers only process the personal
                    data that are actually needed to process in order to achieve
                    its processing purposes.
                  </li>
                </ul>
              </li>
              <li>
                Workstation security:
                <ul>
                  <li>
                    Users are not able to deactivate or bypass security
                    settings.
                  </li>
                  <li>
                    Anti-virus applications and detection signatures is
                    configured on a regular basis.
                  </li>
                  <li>
                    Users don't have privileges to install or deactivate
                    unauthorized software applications.
                  </li>
                  <li>
                    The system has session time-outs when the user has not been
                    active for a certain time period.
                  </li>
                  <li>
                    Critical security updates released by the operating system
                    developer is installed regularly.
                  </li>
                </ul>
              </li>
            </ul>
            <p className="text2">
              <b>2.4. Network/Communication security</b>
            </p>
            <ul>
              <li>
                Whenever access is performed through the Internet, communication
                is encrypted through cryptographic protocols.
              </li>
              <li>
                Traffic to and from the IT system is monitored and controlled
                through firewalls and intrusion detection systems.
              </li>
            </ul>
            <p className="text2">
              <b>2.5. Back-ups</b>
            </p>
            <ul>
              <li>
                Backup and data restore procedures are defined, documented, and
                clearly linked to roles and responsibilities.
              </li>
              <li>
                Backups are given an appropriate level of physical and
                environmental protection consistent with the standards applied
                on the originating data.
              </li>
              <li>Execution of backups is monitored to ensure completeness.</li>
            </ul>
            <p className="text2">
              <b>2.6. Mobile/Portable devices</b>
            </p>
            <ul>
              <li>
                Mobile and portable device management procedures are defined and
                documented establishing clear rules for their proper use.
              </li>
              <li>
                Mobile devices that are allowed to access the information system
                are pre-registered and pre-authorized.
              </li>
            </ul>
            <p className="text2">
              <b>2.7. Application lifecycle security</b>
              <br />
              During the development lifecycle, best practice, state of the art
              and well acknowledged secure development practices or standards
              are followed.
            </p>
            <p className="text2">
              <b>2.8. Access Data deletion/disposal</b>
            </p>
            <ul>
              <li>
                Software-based overwriting will be performed on media prior to
                their disposal. In cases where this is not possible (CD's,
                DVD's, etc.) physical destruction will be performed.
              </li>
              <li>
                Shredding of paper and portable media used to store personal
                data is carried out.
              </li>
            </ul>
            <p className="text2">
              <b>2.9. Physical security</b>
              <br />
              The physical perimeter of the IT system infrastructure is not
              accessible by non-authorized personnel. Appropriate technical
              measures (e.g. intrusion detection system, chip-card operated
              turnstile, single-person security entry system, locking system) or
              organizational measures (e.g. security guard) shall be set in
              place to protect security areas and their access points against
              entry by unauthorized persons.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
));
