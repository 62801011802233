import React from 'react'
import PropTypes from 'prop-types'

import './bluebutton.scss'

export const BlueButton = React.memo(({ text, openPopup }) => (
	<button className="blue-button h3" onClick={openPopup}>
		{text}
	</button>
))

BlueButton.propTypes = {
	text: PropTypes.string,
	openPopup: PropTypes.func,
}
