import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { TextareaField } from '../../Form/TextareaField/TextareaField'
import { InputField } from '../../Form/InputField/InputField'
import { ArrowButton } from '../../Buttons/ArrowButton/ArrowButton'

import './ContactForm.scss'

export const ContactForm = React.memo(
	({
		contact,
		btnColor,
		btnPosition,
		name,
		setName,
		contactInput,
		setContactInput,
		extraInput,
		setExtraInput,
		linkInput,
		setLinkInput,
		textInput,
		setTextInput,
		checkbox,
		setCheckbox,
		requestForm,
		white,
		errorData,
		eng,
	}) => {
		return (
			<div className="contact-us__form-wrapper">
				<div className={cn('contact-us__form', { white })}>
					<form className="contact-us__form-body">
						<div className="contact-us__form-input">
							<InputField
								title={eng ? 'Name' : 'Ваше имя'}
								name={'name'}
								value={name}
								onChange={setName}
								active={name !== ''}
								error={!errorData.name}
							/>
						</div>
						<div className="contact-us__form-input">
							<InputField
								title={contact ? (eng ? 'Phone' : 'Телефон') : 'E-mail'}
								name={'contact'}
								value={contactInput}
								onChange={setContactInput}
								error={contact ? !errorData.phone : !errorData.email}
								active
							/>
						</div>
						<div className="contact-us__form-input">
							<InputField
								title={contact ? (eng ? 'Company' : 'Компания') : eng ? 'Surname' : 'Фамилия'}
								name={'extra'}
								value={extraInput}
								onChange={setExtraInput}
								error={contact ? !errorData.company : !errorData.surname}
								active
							/>
						</div>
						<div className="contact-us__form-input">
							<InputField
								title={contact ? 'E-mail' : eng ? 'Link to your CV' : 'Ссылка на резюме'}
								name={'link'}
								value={linkInput}
								onChange={setLinkInput}
								error={contact ? !errorData.email : !errorData.link}
								active
							/>
						</div>
						<TextareaField
							title={eng ? 'Cover Letter' : 'Опишите Вашу задачу'}
							name={'description'}
							value={textInput}
							onChange={setTextInput}
							error={!errorData.description}
						/>
					</form>
					<div className="contact-us__subform">
						<div className={cn('contact-us__form-checkbox', { error: !errorData.checkbox })}>
							<label htmlFor="privacy-checkbox">
								<input
									type="checkbox"
									id="privacy-checkbox"
									name="privacy"
									checked={checkbox}
									onChange={() => setCheckbox(!checkbox)}
								/>
								<div className="checkbox-element"></div>
								<div className="contact-us__form-checkbox__label">
									{eng ? (
										<span>
											I have read and accept
											<Link to="/policy" target="_blank" rel="noopener noreferrer">
												the Privacy Policy
											</Link>
											<br></br>and I consent to
											<Link to="/personal" target="_blank" rel="noopener noreferrer">
												the processing of personal data
											</Link>
										</span>
									) : (
										<span>
											Я прочитал и принимаю
											<Link to="/policy" target="_blank" rel="noopener noreferrer">
												Политику конфиденциальности
											</Link>
											<br></br>и даю согласие на
											<Link to="/personal" target="_blank" rel="noopener noreferrer">
												Обработку персональных данных
											</Link>
										</span>
									)}
								</div>
							</label>
						</div>
					</div>
					<ArrowButton
						text={eng ? 'Send' : contact ? 'Отправить' : 'Отправить резюме'}
						color={btnColor}
						position={btnPosition}
						openPopup={() => requestForm()}
						submit
					/>
				</div>
			</div>
		)
	}
)

ContactForm.propTypes = {
	btnText: PropTypes.string,
	btnColor: PropTypes.string,
	btnPosition: PropTypes.string,
	formTheme: PropTypes.string,
	formId: PropTypes.string,
	onClose: PropTypes.func,
	quickSearch: PropTypes.bool,
	teamDevs: PropTypes.bool,
	footer: PropTypes.bool,
	eng: PropTypes.bool,
}
