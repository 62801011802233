import React from "react";
import cn from "classnames";

import { ContactSocial } from "./ContactSocial/ContactSocial";
import { ContactButtons } from "./ContactSocial/ContactButtons/ContactButtons";
import { ContactAddress } from "./ContactAddress/ContactAddress";
import { ContactForm } from "./ContactForm/ContactForm";

import { ContactSocialArr } from "../../StaticInfo/StaticInfo";

import "./ContactFooter.scss";

export const ContactFooter = React.memo(
  ({
    title,
    map,
    requestForm,
    name,
    setName,
    contactInput,
    setContactInput,
    extraInput,
    setExtraInput,
    linkInput,
    setLinkInput,
    textInput,
    setTextInput,
    checkbox,
    setCheckbox,
    errorData,
    eng,
  }) => (
    <div className="contact-us__wrapper">
      <div className="container">
        <div className={cn("contact-us__body", { show: map })}>
          <div className="contact-us__contacts-wrapper">
            <div className="contact-us__contacts">
              <div className="contact-us__contacts-title h1">{title}</div>
              {map && <ContactAddress eng={eng} />}
              {!map && (
                <div className="career-page__footer-text text2">
                  <span>
                    {eng
                      ? "Send us your CV and we will review it soon"
                      : "Отправьте нам ваше резюме и мы рассмотрим его в ближайшее время"}
                  </span>
                </div>
              )}
              <div className="contact-us__contacts-social">
                <p className="contact-us__contacts-social-title">
                  {eng ? "Our social Media" : "Мы в социальных сетях"}
                </p>
                {map && (
                  <ul className="contact-us__contacts-social-wrapper">
                    {ContactSocialArr.map((social) => (
                      <ContactSocial
                        key={social.alt}
                        link={social.link}
                        icon={social.icon}
                        alt={social.alt}
                      />
                    ))}
                  </ul>
                )}
                {!map && (
                  <div className="buttons-wrapper">
                    {ContactSocialArr.map((social) => (
                      <ContactButtons
                        key={social.alt}
                        link={social.link}
                        icon={social.icon}
                        alt={social.alt}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <ContactForm
            eng={eng}
            contact={map}
            btnText={eng ? "send" : "отправить"}
            btnColor={"white"}
            btnPosition={"left"}
            formId={"contactUs"}
            requestForm={requestForm}
            name={name}
            setName={setName}
            contactInput={contactInput}
            setContactInput={setContactInput}
            extraInput={extraInput}
            setExtraInput={setExtraInput}
            linkInput={linkInput}
            setLinkInput={setLinkInput}
            textInput={textInput}
            setTextInput={setTextInput}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            errorData={errorData}
            footer
          />
        </div>
      </div>
    </div>
  )
);
