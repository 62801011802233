import React from "react";
import PropTypes from "prop-types";

import { PagesRouter } from "./layout/PagesRouter/PagesRouter";
import { GlobalMessages } from "./components/GlobalMessages/GlobalMessages";

import "./assets/style/style.scss";
import "./app.scss";

export const App = React.memo(() => {
  // const [lang, setLang] = useState(localStorage.getItem("lang") || "RU");
  const lang = "RU";
  const setLang = () => {};

  return (
    <div className="app">
      <PagesRouter lang={lang} setLang={setLang} />
      <GlobalMessages />
    </div>
  );
});

App.propTypes = {
  store: PropTypes.object,
};
