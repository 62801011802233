import React from 'react'
import './LinkBox.scss'

export const LinkBox = React.memo(({ image, title, text, link, linkText }) => (
	<a href={link} rel="noreferrer" className="link-box">
		<div className="link-box__img">
			<div className="img-wrapper">
				<img src={image} alt="team" />
			</div>
			<div className="blur" style={{ backgroundImage: `url(${image})` }}></div>
		</div>
		<div className="link-box__item">
			<div className="link-box__title h2">{title}</div>
			<div className="link-box__text text2">{text}</div>
			<div className="pseudo-link">
				<span>{linkText}</span>
			</div>
		</div>
	</a>
))
