import { createSlice } from "@reduxjs/toolkit";

import eventsApi from "../../../api/events-api";

const initialState = {
  isLoading: false,
  events: [],
  filters: {
    offset: 0,
    limit: 10,
    tag: 4,
    lang: "",
  },
  event: {},
  count: 0,
  loading: false,
};

const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload.events;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setEvent: (state, action) => {
      state.event = action.payload.event;
    },
    setCount: (state, action) => {
      state.count = action.payload.count;
    },
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
  },
});

const { reducer, actions } = events;

export const { setEvents, setFilters, setEvent, setCount, setLoading } =
  actions;

export const requestEvents = () => async (dispatch, getState) => {
  const {
    events: {
      events,
      filters: { offset, limit, tag, lang },
    },
  } = getState();

  dispatch(setLoading({ loading: true }));
  const res = await eventsApi.requestEvents(offset, limit, tag, lang);
  if (res.success) {
    dispatch(setEvents({ events: [...events, ...res.result.events] }));
    dispatch(setCount({ count: res.result.count }));
  }

  dispatch(setLoading({ loading: false }));
};

export const requestEvent = (id) => async (dispatch) => {
  const res = await eventsApi.requestEvent(id);

  if (res.success) {
    dispatch(setEvent({ event: res.result }));
  }
};

export default reducer;
