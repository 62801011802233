import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { OvalFilterButton } from '../../../../components/Buttons/OvalFilterButton/OvalFilterButton'
import { TeamSkills } from '../TeamSkills/TeamSkills'

import {
	getLocalList,
	getFilters,
} from '../../../../store/reducers/constructor/constructor-selectors'

import './TeamCard.scss'

export const TeamCard = React.memo(
	({
		user,
		dragOver,
		dragEnter,
		dragStart,
		dropHandler,
		moveMemberToOtherList,
		nameOfBoard,
		dragLeave,
		eng,
	}) => {
		const { specializations, techs } = useSelector(getLocalList)
		const filters = useSelector(getFilters)
		const [preview, showFullList] = useState(false)

		// список всех скиллов
		const newSkills = techs?.map((skill) => skill.tech_skills).flat()

		const senior = user.skills.filter((item) => item.value === 3)
		const middle = user.skills.filter((item) => item.value === 2)
		const junior = user.skills.filter((item) => item.value === 1)

		const renameSkills = (specSkill) => {
			const newSkill = newSkills?.filter((item) => specSkill.key === item.key)
			return newSkill[0].value
		}

		const checkButton = (item) => {
			return filters.skills.includes(item.key) ? true : false
		}

		// специализация
		const findSpecialization = (items) => {
			if (items.length) {
				return items?.reduce((prev, cur) => (prev.value >= cur.value ? prev : cur))
			}
		}

		const renameSpecialization = (item) => {
			const newSpec = specializations?.filter((spec) => spec.key === item.key)
			return newSpec[0].value
		}

		// вывод ограниченного списка навыков
		const makeAPreviewList = (item) => item.filter((value, index) => index < 11)

		useEffect(() => {
			showFullList(false)
		}, [user])

		return (
			<div
				className="team__list"
				draggable={true}
				onDragStart={() => dragStart(user, nameOfBoard)}
				onDragEnter={(e) => dragEnter(e)}
				onDragOver={(e) => dragOver(e)}
				onDrop={(e) => dropHandler(e, nameOfBoard, user)}
				onDragLeave={(e) => dragLeave(e)}
			>
				<div className="team__list-title">
					<span className="h3">{`${user.name} ${
						!!user.surname ? user.surname[0] + '.' : ''
					}`}</span>
					<span className="text2">
						{!!user.specializations.length
							? renameSpecialization(findSpecialization(user.specializations))
							: eng
							? 'Specialist'
							: 'Специалист'}
					</span>
				</div>
				<div className={`team__skills ${preview ? 'open' : ''}`}>
					{!preview && (
						<>
							{makeAPreviewList(user.skills)?.map((item, i) => (
								<div className="team__skills-item" key={`all-skills-${i}`}>
									<OvalFilterButton
										title={renameSkills(item)}
										place={'card'}
										checked={checkButton(item)}
										readonly
									/>
								</div>
							))}
							<button className="text3 team__skills-button" onClick={() => showFullList(true)}>
								{eng
									? user.skills.length > 12
										? `${user.skills.length - 11} more`
										: 'more'
									: user.skills.length > 12
									? `еще ${user.skills.length - 11}`
									: 'еще'}
							</button>
						</>
					)}

					{preview && (
						<>
							{senior.length !== 0 && (
								<TeamSkills
									skillsList={senior}
									title={'Senior'}
									rename={renameSkills}
									checked={checkButton}
									readonly
								/>
							)}
							{middle.length !== 0 && (
								<TeamSkills
									skillsList={middle}
									title={'Middle'}
									rename={renameSkills}
									checked={checkButton}
									readonly
								/>
							)}
							{junior.length !== 0 && (
								<TeamSkills
									skillsList={junior}
									title={'Junior'}
									rename={renameSkills}
									checked={checkButton}
									readonly
								/>
							)}
							<button
								className={`text3 team__skills-button ${preview ? 'open' : ''}`}
								onClick={() => showFullList(false)}
							>
								{eng ? 'Hide' : 'Скрыть'}
							</button>
						</>
					)}
				</div>
				<button className="team__list-button" onClick={() => moveMemberToOtherList(user)} />
			</div>
		)
	}
)
