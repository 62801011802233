import React from 'react'
import PropTypes from 'prop-types'

import './ArrowButton.scss'

export const ArrowButton = React.memo(({ text, color, position, submit, openPopup }) => (
	<div className={`arrow-button__wrapper ${position}`}>
		<button
			className={`arrow-button ${color}`}
			onClick={openPopup}
			type={submit ? 'submit' : 'button'}
		>
			<p className="arrow-button__text">{text}</p>
			<div className="arrow-wrapper">
				<div className={`arrow ${color}`}></div>
			</div>
		</button>
	</div>
))

ArrowButton.propTypes = {
	text: PropTypes.string,
	color: PropTypes.string,
	position: PropTypes.string,
	openPopup: PropTypes.func,
	submit: PropTypes.bool,
}
