import React from 'react'

import './SpecializationBox.scss'

export const SpecializationBox = React.memo(({ title, icon }) => (
	<div className="specialization-box">
		<div
			className="specialization-box__icon"
			style={{ background: `url(${icon}) center 5px no-repeat` }}
		></div>
		<div className="specialization-box__title">
			<span>{title}</span>
		</div>
	</div>
))
