import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { TeamCard } from '../TeamList/TeamCard/TeamCard'
import { EmptyDropzone } from './EmptyDropzone/EmptyDropzone'
import { QuickSearch } from '../../../components/QuickSearch/QuickSearch'
import { FoundSpecialists } from '../FoundSpecialists/FoundSpecialists'
import { getUsers } from '../../../store/reducers/constructor/constructor-selectors'

import './TeamDropzone.scss'

export const TeamDropzone = React.forwardRef(
	(
		{
			addDev,
			dev,
			members,
			setMembers,
			dragOver,
			dropCard,
			dragEnter,
			dragStart,
			dropHandler,
			dragLeave,
			setNewRequest,
			device,
			eng,
		},
		ref
	) => {
		const users = useSelector(getUsers)

		const [quickSearch, setQuickSearch] = useState(false)

		const removeNewMember = (user) => {
			setMembers([...members, user])
			addDev(dev.filter((item) => item.id !== user.id))
		}

		return (
			<div className="teams__drop">
				<FoundSpecialists
					dev={dev.length}
					addDev={addDev}
					setMembers={setMembers}
					device={device}
					dropzone
					eng={eng}
				/>

				<div className="team__dropzone">
					<div
						className="team__wrapper-drop"
						ref={ref}
						onDragOver={(e) => dragOver(e)}
						onDrop={() => dropCard('drop')}
					>
						<div className="drop__wrapper">
							{!dev.length && <EmptyDropzone eng={eng} setQuickSearch={setQuickSearch} />}
							{dev.length !== 0 && (
								<div className="drop__wrapper-devs">
									{dev.map((item, index) => (
										<TeamCard
											eng={eng}
											key={`drop-${index}`}
											user={item}
											addDev={addDev}
											dev={dev}
											dragOver={dragOver}
											dragEnter={dragEnter}
											dragStart={dragStart}
											dropHandler={dropHandler}
											dragLeave={dragLeave}
											moveMemberToOtherList={removeNewMember}
											nameOfBoard={'drop'}
										/>
									))}
								</div>
							)}
						</div>
					</div>
					{dev.length !== 0 && (
						<button className="send-request" onClick={() => setNewRequest(true)}>
							<span className="h3">{eng ? 'Make a request ' : 'Оформить заявку'}</span>
						</button>
					)}
				</div>
				{device <= 768 && dev.length !== 0 && (
					<button
						className="team__drop-clear"
						onClick={() => {
							addDev([])
							setMembers(users)
						}}
					>
						{eng ? 'Clear' : 'Очистить список'}
					</button>
				)}

				{device <= 768 && !!dev.length && (
					<div className="quicksearch text2">
						<span>
							{eng ? 'or' : 'или'}
							<br />
							{eng ? 'use a quick search' : 'воспользуйтесь быстрым поиском'}
						</span>
						<button
							className="header__button quicksearch-button h3"
							onClick={() => setQuickSearch(true)}
						>
							{eng ? 'Quick search' : 'Быстрый поиск'}
						</button>
					</div>
				)}
				{quickSearch && (
					<QuickSearch
						onClose={() => setQuickSearch(false)}
						device={device}
						show={quickSearch}
						eng={eng}
					/>
				)}
			</div>
		)
	}
)
