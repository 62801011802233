export const validateName = (value) => {
	const name = /^[A-zА-яЁё]+$/i.test(value)

	return name
}

export const validatePhone = (value) => {
	const phone = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(value)

	return phone
}

export const validateCompany = (value) => {
	const company = value ? /^(?![\d+_@.-]+$)[A-zА-яЁё0-9+_@."-]*$/.test(value) : true

	return company
}

export const validateEmail = (value) => {
	const email = /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/.test(value)

	return email
}

export const validateDescription = (value) => {
	const description = value && true
	// const description = value ? /^.*?(?=[\^#%&$\*:<>\?/\{\|\}]).*$/i.test(value) : true

	return description
}

export const validateSurname = (value) => {
	const surname = value ? /^[A-zА-яЁё]+$/i.test(value) : true

	return surname
}

export const validateLink = (value) => {
	const link = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,6}\.?)(\/[\w.]*)*\/?$/.test(value)

	return link
}

export const validatePrivacy = (value) => {
	return value
}

export const validate = (type, value) => {
	const switchObj = {
		name: validateName(value),
		phone: validatePhone(value),
		company: value ? validateCompany(value) : true,
		email: validateEmail(value),
		description: value ? validateDescription(value) : true,
		surname: value ? validateSurname(value) : true,
		link: validateLink(value),
		privacy: validatePrivacy(value),
	}

	return switchObj[type]
}
