import instance from './api'

const requestsApi = {
	async addRequest(data) {
		const response = await instance.post('/lead/submit', data)

		return response.data
	},

	async addResume(data) {
		const response = await instance.post('/resume/submit', data)

		return response.data
	},

	async addTeam(data) {
		const response = await instance.post('/team/create', data)

		return response.data
	},

	async addQuickSearchTeam(data) {
		const response = await instance.post('/qs/team/create', data)

		return response.data
	},
}

export default requestsApi
