import React from 'react'
import { HtmlRenderer } from './HtmlRenderer/HtmlRenderer'
import './EventBody.scss'

export const EventBody = React.memo(({ block }) => {
	return (
		<div className="event-body">
			{block?.map((item, i) => {
				return <HtmlRenderer bodyItem={item} key={i} />
			})}
		</div>
	)
})
