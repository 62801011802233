import React from 'react'

import './FilterContent.scss'

export const FilterContent = React.memo(({ title, children }) => {
	return (
		<div className="team__filter-box">
			<div className="team__filter-title">{title}</div>
			<div className="team__filter-item">{children}</div>
		</div>
	)
})
