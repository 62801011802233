import React from 'react'
import PropTypes from 'prop-types'

import './XLine.scss'

export const XLine = React.memo(({ title, button, openPopup }) => (
	<div className="x-line__block">
		<div className="page-wrapper">
			<div className="x-line__block-title">
				<span>{title}</span>
			</div>
			<button className="main-team__button h3" onClick={openPopup}>
				<span>{button}</span>
			</button>
		</div>
		<div className="x-line__x-logo"></div>
		{/* <div className="pages-header__image-plug"></div> */}
		<div className="x-line__x-logo-substrate"></div>
	</div>
))

XLine.propTypes = {
	title: PropTypes.string,
	button: PropTypes.string,
}
