import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PagesHeader } from "../../../components/PagesHeader/PagesHeader";
import { ContactFooter } from "../../../components/ContactFooter/ContactFooter";
import { CompanyPopup } from "./CompanyPopup/CompanyPopup";

import { addRequest } from "../../../store/reducers/requests/requests";
import { getResult } from "../../../store/reducers/requests/requests-selector";
import { showMessage } from "../../../store/reducers/functions";

import { validate } from "../../functions";

// import { EN_COMPANY } from "../../../StaticInfo/StaticInfo";

import headerBackground from "../../../assets/images/company_header.jpg";
import aboutUsImg from "../../../assets/images/company_about.jpg";
import aboutUsImg1400 from "../../../assets/images/Content/Company/aboutUsImg1400.jpg";
import numbers from "../../../assets/images/icons/2099-big.svg";
import clover from "../../../assets/images/icons/clover-big.svg";
// import basicChain from "../../../assets/images/icons/basicchain-big.svg";
import ctrlToGo from "../../../assets/images/icons/ctrltogo-big.svg";

import "./Company.scss";

export const EnCompany = React.memo(() => {
  const dispatch = useDispatch();

  const result = useSelector(getResult);

  const [isResumePopupOpen, togglePopup] = useState(false);
  const [name, setName] = useState("");
  const [phone, setContactInput] = useState("");
  const [company, setExtraInput] = useState("");
  const [email, setLinkInput] = useState("");
  const [description, setTextInput] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const [errorData, setErrorData] = useState({
    name: true,
    phone: true,
    email: true,
    checkbox: true,
    company: true,
    description: true,
  });

  const requestForm = () => {
    const data = {
      name: validate("name", name) ? name : "",
      company: validate("company", company) && company,
      phone: validate("phone", phone) ? phone : "",
      email: validate("email", email) ? email : "",
      description: validate("description", description) && description,
    };

    if (!data.name || !data.phone || !data.email || !checkbox) {
      setErrorData({
        name: data.name ? true : false,
        phone: data.phone ? true : false,
        email: data.email ? true : false,
        checkbox: checkbox ? true : false,
        company: data.company === "" || data.company ? true : false,
        description: data.description === "" || data.description ? true : false,
      });

      showMessage(dispatch, {
        code: "error",
        msg: "Fill all necessary fields",
      });
    } else {
      dispatch(addRequest(data, true));
    }
  };

  useEffect(() => {
    if (result) {
      setName("");
      setContactInput("");
      setExtraInput("");
      setLinkInput("");
      setTextInput("");
      setCheckbox(false);
      setErrorData({
        name: true,
        phone: true,
        email: true,
        checkbox: true,
        company: true,
        description: true,
      });
    }
  }, [result]);

  if (isResumePopupOpen) {
    return (
      <CompanyPopup open={isResumePopupOpen} close={() => togglePopup(false)} />
    );
  }

  return (
    <div className="company-page">
      <PagesHeader image={headerBackground} title={"Company"}>
        <p>
          X-Technology has all the technical and human resources to execute
          projects of any complexity. Every project for us is a new step in the
          future. Every employee for us is the architect of our future.
        </p>
      </PagesHeader>
      <div className="container">
        <div className="company-page__about-us">
          <div className="about-us__info">
            <div className="about-us__title h1">
              <span>ABOUT US</span>
            </div>
            <div className="about-us__text text2">
              <span>
                X-Technology has over 17 years of experience in IT industry,
                financial technology and cryptography solutions. Implements
                IT-projects of any complexity. The team has already successfully
                done more than 150 projects.
                {/* X-Technology has over 17 years of experience in IT industry and
                specializes in blockchain, financial technology and cryptography
                solutions. Implements IT-projects of any complexity. The team
                has already successfully done more than 150 projects. */}
              </span>
            </div>
            <div className="about-us__items-block">
              <div className="about-us__item">
                <span>Expertise and professional competence</span>
              </div>
              <div className="about-us__item">
                <span>Partnership with leading IT companies</span>
              </div>
              <div className="about-us__item">
                <span>IT industry development</span>
              </div>
            </div>
          </div>
          <div className="about-us__img">
            <picture>
              <source srcSet={aboutUsImg1400} media="(max-width: 1440px)" />
              <source srcSet={aboutUsImg} />
              <img srcSet={aboutUsImg} alt="about-us-img" />
            </picture>
            <div
              className="blur"
              style={{ backgroundImage: `url(${aboutUsImg})` }}
            ></div>
          </div>
        </div>
        <div className="our-partners">
          <div className="our-partners__title h1">
            <span>OUR PARTNERS</span>
          </div>
          <div className="partners__list">
            <img src={ctrlToGo} alt="ctrlToGo" className="partner__img" />
            <img src={clover} alt="clover" className="partner__img" />
            <img src={numbers} alt="2099" className="partner__img" />
            {/* <img src={basicChain} alt="basicChain" className="partner__img" /> */}
          </div>
        </div>
        {/* <div className="company-page__team">
          <div className="team-title h1">
            <span>Team</span>
          </div>
          <div className="team-wrapper">
            {EN_COMPANY.people.map((item, i) => (
							<div key={`team-${i}`} className="team-item">
								<img src={item.img} alt="face-img" className="team-photo" />
								<div className="team-info">
									<div className="team-info__name h2">
										<span>{item.name}</span>
									</div>
									<div className="team-info__file text2">
										<span>{item.career}</span>
									</div>
								</div>
							</div>
						))}
            <div className="team-item__send-box">
              <div className="team-item__send-text">
                BECOME A PART OF OUR TEAM{" "}
              </div>
              <button
                className="main-team__button h3"
                onClick={() => togglePopup(true)}
              >
                <span>SEND YOUR CV</span>
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className="where-we-r">
          <div className="where-we-r__title h1">
            <span>OUR OFFICE</span>
          </div>
          <div className="where-we-r__text text2">
            <span>
              To be based in the westernmost point of Russia is not only
              convenient, but also has many benefits. We are in the center of
              Europe, which allows us to communicate directly with European
              partners, to jointly implement projects and to establish a strong
              connection.
            </span>
          </div>
        </div> */}
      </div>
      <ContactFooter
        eng
        title={"contact us"}
        requestForm={requestForm}
        name={name}
        setName={setName}
        contactInput={phone}
        setContactInput={setContactInput}
        extraInput={company}
        setExtraInput={setExtraInput}
        linkInput={email}
        setLinkInput={setLinkInput}
        textInput={description}
        setTextInput={setTextInput}
        checkbox={checkbox}
        setCheckbox={setCheckbox}
        errorData={errorData}
        map
      />
    </div>
  );
});
