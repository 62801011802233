import React from 'react'
import PropTypes from 'prop-types'
import { OvalFilterButton } from '../../../../components/Buttons/OvalFilterButton/OvalFilterButton'

import './TeamSkills.scss'

export const TeamSkills = React.memo(({ skillsList, title, rename, checked, tech, readonly }) => (
	<div className="team__skills-item-box">
		<div className="team__skills-item-title text3">{title}</div>
		<div className="team__skills-items">
			{skillsList.map((item, key) => (
				<div className="team__skills-item" key={`${item}-${key}`}>
					<OvalFilterButton
						title={rename(item)}
						place={'card-item'}
						checked={checked(item)}
						readonly={readonly}
					/>
				</div>
			))}
		</div>
	</div>
))

TeamSkills.propTypes = {
	skillsList: PropTypes.array,
	title: PropTypes.string,
	tech: PropTypes.array,
	check: PropTypes.func,
}
