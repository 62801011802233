import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import { ContactUsPopup } from "../../components/ContactUsPopup/ContactUsPopup";
import { Header } from "../header/Header";
import { Main } from "../../pages/Main/main";
import { Services } from "../../pages/Serv/services";
import { Events } from "../../pages/Events/Events";
import { Career } from "../../pages/Career/career";
import { Company } from "../../pages/Company/company";
import { Team } from "../../pages/Team/team";
import { Policy } from "../../pages/Policy/Policy";
import { Personal } from "../../pages/Policy/Personal";
import { Page404 } from "../../pages/Page404/Page404";
import { Footer } from "../footer/footer";

import { EnMain } from "../../pages/Eng/Main/EnMain";
import { EnServices } from "../../pages/Eng/Serv/EnServices";
// import { EnEvents } from "../../pages/Eng/Events/EnEvents";
import { EnCareer } from "../../pages/Eng/Career/EnCareer";
import { EnCompany } from "../../pages/Eng/Company/EnCompany";
import { EnPolicy } from "../../pages/Eng/Policy/EnPolicy";
import { EnPersonal } from "../../pages/Eng/Policy/EnPersonal";
import { EnPage404 } from "../../pages/Eng/Page404/EnPage404";
import { Preloader } from "../../components/Preloader/Preloader";

import "../../assets/style/style.scss";
import "./PagesRouter.scss";

export const PagesRouter = React.memo(({ lang, setLang }) => {
  const [isLoading, setisLoading] = useState(false);
  const [openPopUp, setOpenPopup] = useState(false);

  useEffect(() => {
    setisLoading(true);
    setTimeout(() => setisLoading(false), 300);
  }, [lang]);

  return (
    <>
      <Header open={() => setOpenPopup(true)} lang={lang} setLang={setLang} />
      <div className="app__main">
        {openPopUp && (
          <ContactUsPopup onClose={setOpenPopup} show={openPopUp} lang={lang} />
        )}
        {lang === "EN" ? (
          <Switch>
            <Route
              exact
              path="/"
              render={() => <EnMain open={() => setOpenPopup(true)} />}
            />
            <Route path="/service" render={() => <EnServices />} />
            {/* <Route
							path="/community/:id?"
							render={() => <EnEvents open={() => setOpenPopup(true)} />}
						/> */}
            <Route path="/career" render={() => <EnCareer />} />
            <Route path="/company" render={() => <EnCompany />} />
            <Route path="/team" render={() => <Team lang={lang} />} />
            <Route path="/policy" render={() => <EnPolicy />} />
            <Route path="/personal" render={() => <EnPersonal />} />

            <Route path="*" render={() => <EnPage404 />} />
          </Switch>
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Main open={() => setOpenPopup(true)} />}
            />
            <Route path="/service" render={() => <Services />} />
            <Route
              path="/community/:id?"
              render={() => <Events open={() => setOpenPopup(true)} />}
            />
            <Route path="/career" render={() => <Career />} />
            <Route path="/company" render={() => <Company />} />
            <Route path="/team" render={() => <Team lang={lang} />} />
            <Route path="/policy" render={() => <Policy />} />
            <Route path="/personal" render={() => <Personal />} />

            <Route path="*" render={() => <Page404 />} />
          </Switch>
        )}
        {isLoading && <Preloader full />}
      </div>
      <Footer lang={lang} />
    </>
  );
});

PagesRouter.propTypes = {
  store: PropTypes.object,
  lang: PropTypes.node,
  setLang: PropTypes.func,
};
