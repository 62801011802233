import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Event } from './Event/Event'
import { Community } from './Community/Community'

import {
	requestEvent,
	setFilters,
	setEvents,
	requestEvents,
} from '../../store/reducers/community/community'
import { getEvent, getFilters } from '../../store/reducers/community/community-selector'

export const Events = React.memo(({ open }) => {
	const match = useRouteMatch()
	const id = match.params.id

	const dispatch = useDispatch()
	const event = useSelector(getEvent)
	const { tag } = useSelector(getFilters)

	useEffect(() => {
		if (!!id) dispatch(requestEvent(id))
	}, [dispatch, id])

	useEffect(() => {
		dispatch(setEvents({ events: [] }))
		dispatch(setFilters({ offset: 0, lang: 'ru' }))
		dispatch(requestEvents())
	}, [dispatch, tag])

	useEffect(
		() => () => {
			dispatch(setFilters({ tag: 4 }))
		},
		[dispatch]
	)

	return (
		<div className="community-page">
			{Object.keys(event).length && id ? (
				<Event id={id} event={event} open={open} />
			) : (
				<Community />
			)}
		</div>
	)
})
