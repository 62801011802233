import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import { BlueButton } from '../../../components/Buttons/BlueButton/BlueButton'
import { EventBody } from './EventBody/EventBody'
import { EventGallery } from './EventGallery/EventGallery'

import { url } from '../../../api/api'

import { requestEvents, setFilters, setEvents } from '../../../store/reducers/community/community'
import { getEvents, getFilters } from '../../../store/reducers/community/community-selector'

import './Event.scss'

export const Event = React.memo(({ open, event }) => {
	const dispatch = useDispatch()

	const sameEvents = useSelector(getEvents).filter((item) => item.id !== event.id)
	const { tag } = useSelector(getFilters)

	const { block, gallery, preImage, tag: eventTag, title } = event

	const getTagName = (tag) => {
		const switchObj = {
			1: 'События',
			2: 'Обучение',
			3: 'Интервью',
		}
		return switchObj[tag]
	}

	useEffect(() => {
		dispatch(setFilters({ tag: eventTag, limit: 4 }))
	}, [dispatch, eventTag])

	useEffect(() => {
		dispatch(requestEvents())
	}, [dispatch, tag])

	useEffect(
		() => () => {
			dispatch(setFilters({ tag: 4, limit: 10 }))
			dispatch(setEvents({ events: [] }))
		},
		[dispatch]
	)

	return (
		<>
			<section className="event">
				<div className="container">
					<div className="event__wrapper">
						<div className="event__img event__img-mobile">
							<img src={`${url}/static/${preImage}`} alt={title} />
							<div
								className="blur"
								style={{ backgroundImage: `url(${url}/static/${preImage})` }}
							></div>
						</div>
						<div className="event__id">{getTagName(eventTag)}</div>
						<h1 className="main__title h1">{title}</h1>
						<div className="event__text">
							<div className="event__img">
								<img src={`${url}/static/${preImage}`} alt={title} />
								<div
									className="blur"
									style={{ backgroundImage: `url(${url}/static/${preImage})` }}
								></div>
							</div>
							<EventBody block={block} />
							<EventGallery gallery={gallery} />
						</div>
					</div>
					<div className="event__modal">
						<div className="event__modal-title h2">Заинтересованы в наших услугах?</div>
						<BlueButton text={'Обсудить проект'} openPopup={open} />
					</div>
				</div>
			</section>
			{!!sameEvents.length && (
				<section className="event-like">
					<div className="container">
						<h2 className="main__title h2">Похожие</h2>
						<div className="event-like__wrapper">
							{sameEvents.map(
								(same, index) =>
									index < 3 && (
										<Link to={`/community/${same.id}`} key={same.id}>
											<div className="event-like__box">
												<div className="main-find__img">
													<img src={`${url}/static/${same.preImage}`} alt="same1" />
													<div
														className="blur"
														style={{ backgroundImage: `url(${url}/static/${same.preImage})` }}
													></div>
												</div>
												<div className="event-like__item">
													<div className="event-like__id">{getTagName(same.tag)}</div>
													<div className="event-like__title h2">{same.title}</div>
													<div className="event-like__text">{same.descrition}</div>
												</div>
											</div>
										</Link>
									)
							)}
						</div>
					</div>
				</section>
			)}
		</>
	)
})

Event.propTypes = {
	open: PropTypes.func,
}
