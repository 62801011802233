import React from 'react'
import './policy.scss'

export const Policy = React.memo(() => (
	<section className="policy">
		<div className="policy__container">
			<h2 className="h2 policy__title">Политика конфиденциальности</h2>
			<div className="text2">
				<h2 className="policy__main-title">
					персональных данных посетителей сайта X-Technology в информационно-телекоммуникационной
					сети &quot;Интернет&quot;
				</h2>
				<div className="policy__text">
					<h3 className="h3">1. Общие положения</h3>
					<p className="text2">
						1.1. Настоящая Политика конфиденциальности персональных данных посетителей сайта в
						информационно-телекоммуникационной сети &quot;Интернет&quot; (далее - Политика
						конфиденциальности) разработана в соответствии с положениями Конституции Российской
						Федерации, Федерального закона от 27 июля 2006 г. №149-ФЗ &quot;Об информации,
						информационных технологиях и о защите информации&quot;, Федерального закона от 27 июля
						2006 г. N 152-ФЗ &quot;О персональных данных&quot; (далее - Федеральный закон) и другими
						нормативными правовыми актами.
					</p>
					<p className="text2">
						1.2. В настоящей Политике конфиденциальности используются следующие понятия:
						<ul>
							<li>
								<b>Сайт</b> - X-Technology, расположенный на доменном имени X-Technology.org,
								предоставляющий услуги по поиску и подбору специалистов в отрасли информационных
								технологий для лиц, имеющих потребность в услугах (работах), оказываемых
								(выполняемых) специалистами в отрасли информационных технологий.
							</li>
							<li>
								<b>Администрация сайта</b> - уполномоченные на управление сайтом работники,
								определяющие состав персональных данных Пользователей сайта, цели сбора персональных
								данных, их обработку и хранение.
							</li>
							<li>
								<b>Пользователь сайта</b> - физическое лицо, пользователь услуг сайта, субъект
								персональных данных, добровольно зарегистрировавшийся на сайте и предоставивший
								необходимые персональные данные при регистрации.
							</li>
							<li>
								<b>Персональные данные</b> - любая информация, относящаяся к прямо или косвенно
								определенному или определяемому физическому лицу (субъекту персональных данных),
								которая сама по себе или в сочетании с другой информацией, имеющейся в распоряжении
								сайта, позволяет идентифицировать личность пользователя.
							</li>
							<li>
								<b>Обработка персональных данных</b> - любое действие (операция) или совокупность
								действий (операций), совершаемых с использованием средств автоматизации или без
								использования таких средств с персональными данными, включая сбор, запись,
								систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
								использование, передачу (распространение, предоставление, доступ), обезличивание,
								блокирование, удаление, уничтожение персональных данных.
							</li>
						</ul>
					</p>
					<p className="text2">
						1.3. Настоящая Политика конфиденциальности устанавливает порядок получения, защиты,
						хранения, обработки и передачи персональных данных Пользователей сайта, действует в
						отношении всей информации, которую администрация сайта может получить о Пользователях во
						время использования ими сайта. Настоящая Политика конфиденциальности не распространяется
						на другие сайты и не применяется в отношении сайтов третьих лиц. Администрация сайта не
						несет ответственность за сайты третьих лиц, на которые Пользователи могут перейти по
						ссылкам, доступным на сайте.
					</p>
					<p className="text2">
						1.4. К персональным данным Пользователей сайта относятся: Фамилия, имя, отчество, дата
						рождения, адрес регистрации; контактный телефон, сведения об организации, должность,
						сведения об образовании, фотографии, номер факса, номер сотового телефона, адрес
						электронной почты, сведения о стране, городе, области проживания, сведения о банковской
						карте (номер, CVV, фамилия имя на латинице, год и месяц окончания действия карты), а
						также иные сведения о Пользователе сайта, которые он добровольно указывает при
						регистрации на сайте. Все персональные данные о Пользователях администрация сайта может
						получить только от них самих. Персональные данные Пользователей сайта являются
						конфиденциальной информацией и не могут быть использованы администрацией сайта или любым
						иным лицом в личных целях.
					</p>
					<p className="text2">
						1.5. Цели обработки персональных данных Пользователей сайта:
						<ul>
							<li>
								- Предоставление Пользователю сайта возможности взаимодействовать с Сайтом и
								установления обратной связи с Пользователем сайта для дальнейшего предоставления
								интересующей его информации;
							</li>
							<li>- Идентификация стороны в рамках оказания Услуги;</li>
							<li>- Предоставление Пользователю сайта персонализированных Услуг;</li>
							<li>- Улучшение качества Услуг и разработка новых;</li>
							<li>
								- Проведение статистических и иных исследований, на основе обезличенных данных;
							</li>
							<li>
								- Предоставление персональных данных Пользователя сайта правообладателям,
								дистрибьюторам или реселлерам программного обеспечения в целях регистрации
								программного обеспечения на имя Пользователя сайта или организации, интересы которой
								представляет пользователь;
							</li>
							<li>
								- Предоставление пользователям Сайта безопасного и удобного функционала по его
								использованию, эффективному отображению информации;
							</li>
							<li>
								- Эффективное исполнение заказов, договоров и иных обязательств, принятых ООО
								«Икс-Тэк» в качестве обязательных к исполнению перед Пользователем сайта;
							</li>
							<li>- Обработка вопросов Пользователей сайта;</li>
							<li>- Регистрация Пользователей сайта на мероприятия;</li>
							<li>
								- Осуществление и/или исполнение функций, полномочий и обязанностей, возложенных
								законодательством Российской Федерации на ООО «Икс-Тэк»;
							</li>
						</ul>
						<ul>
							<li></li>
							<li></li>
							<li></li>
						</ul>
					</p>
					<p className="text2">
						1.6. Администрация сайта обеспечивает Пользователям свободный бесплатный доступ к своим
						персональным данным, включая право на получение копий любой записи, содержащей их
						персональные данные, за исключением случаев, предусмотренных законодательством.
					</p>
					<p className="text2">
						1.7. Администрация сайта разрабатывает меры защиты персональных данных Пользователей
						сайта.
					</p>
				</div>

				<div className="policy__text">
					<h3 className="h3">
						2. Хранение, обработка и передача персональных данных пользователей сайта
					</h3>
					<p className="text2">
						2.1. Обработка персональных данных Пользователей сайта осуществляется исключительно в
						целях, указанных в п. 1.5. настоящей Политики конфиденциальности.
					</p>
					<p className="text2">
						2.2. Персональные данные Пользователей сайта хранятся в электронном виде в
						информационной системе персональных данных сайта, а также в архивных копиях баз данных
						сайта. При хранении персональных данных Пользователей сайта соблюдаются организационные
						и технические меры, обеспечивающие их сохранность и исключающие несанкционированный
						доступ к ним. К обработке персональных данных Пользователей сайта могут иметь доступ
						только работники администрации сайта, допущенные к работе с персональными данными
						Пользователей сайта и подписавшие соглашение о неразглашении персональных данных
						Пользователей сайта. Перечень работников сайта, имеющих доступ к персональным данным
						Пользователей сайта, утверждается приказом Генерального директора ООО «Икс- Тэк».
					</p>
					<p className="text2">
						2.3. Обработка персональных данных на сайте осуществляется автоматизированным способом.
					</p>
					<p className="text2">
						2.4. Администрация сайта может передавать персональные данные Пользователей сайта
						третьим лицам, только если это необходимо в целях предупреждения угрозы их жизни и
						здоровью, а также в случаях, установленных законодательством.
					</p>
					<p className="text2">
						2.5. Администрация сайта обязана предоставлять персональные данные Пользователей только
						уполномоченным лицам и только в той части, которая необходима им для выполнения их
						трудовых обязанностей, в соответствии с настоящей Политикой конфиденциальности и
						законодательством РФ.
					</p>
					<p className="text2">
						2.6. При передаче персональных данных Пользователей сайта администрация сайта
						предупреждает лиц, получающих данную информацию, о том, что эти данные могут быть
						использованы лишь в целях, для которых они сообщены, и требует от этих лиц письменное
						подтверждение соблюдения этого условия.
					</p>
					<p className="text2">
						2.7. Иные права, обязанности, действия работников администрации сайта, в трудовые
						обязанности которых входит обработка персональных данных Пользователей сайта,
						определяются должностными инструкциями.
					</p>
					<p className="text2">
						2.8. Все сведения о передаче персональных данных Пользователей сайта учитываются для
						контроля правомерности использования данной информации лицами, ее получившими.
					</p>
					<p className="text2">
						2.9. В целях повышения качества сервиса и обеспечения возможности правовой защиты
						администрация сайта вправе хранить лог-файлы о действиях, совершенных Пользователями в
						рамках использования сайта.
					</p>
				</div>

				<div className="policy__text">
					<h3 className="h3">
						3. Требования к помещениям, в которых производится обработка персональных данных
					</h3>
					<p className="text2">
						3.1. Размещение оборудования информационных систем персональных данных, специального
						оборудования и охрана помещений, в которых ведется работа с персональными данными,
						организация режима обеспечения безопасности в этих помещениях должны обеспечивать
						сохранность носителей персональных данных и средств защиты информации, а также исключать
						возможность неконтролируемого проникновения или пребывания в этих помещениях посторонних
						лиц.
					</p>
					<p className="text2">
						3.2. Помещения, в которых располагаются технические средства информационных систем
						персональных данных или хранятся носители персональных данных, должны соответствовать
						требованиям пожарной безопасности, установленным действующим законодательством
						Российской Федерации.
					</p>
					<p className="text2">
						3.3. Определение уровня специального оборудования помещения осуществляется специально
						создаваемой комиссией. По результатам определения класса и обследования помещения на
						предмет его соответствия такому классу составляются акты.
					</p>
					<p className="text2">
						3.4. Кроме указанных мер по специальному оборудованию и охране помещений, в которых
						устанавливаются криптографические средства защиты информации или осуществляется их
						хранение, реализуются дополнительные требования, определяемые методическими документами
						Федеральной службы безопасности России.
					</p>
				</div>

				<div className="policy__text">
					<h3 className="h3">4. Права и обязанности администрации сайта</h3>
					<p className="text2">
						4.1. Администрация сайта вправе устанавливать требования к составу персональных данных
						Пользователей, которые должны обязательно предоставляться для использования сайта, при
						этом администрация сайта руководствуется настоящей Политикой, Конституцией РФ, иными
						федеральными законами.
					</p>
					<p className="text2">
						4.2. Администрация сайта не осуществляет проверку достоверности предоставляемых
						Пользователями персональных данных, полагая, что они действуют добросовестно и
						поддерживают информацию о своих персональных данных в актуальном состоянии.
					</p>
					<p className="text2">
						4.3. Администрация сайта не несет ответственности за добровольную передачу
						Пользователями своих контактных данных, пароля или логина третьим лицам.
					</p>
					<p className="text2">
						4.4. Администрация сайта не вправе получать и обрабатывать персональные данные
						Пользователей о их политических, религиозных и иных убеждениях и частной жизни.
					</p>
					<p className="text2">
						4.5. Администрация сайта обязана за свой счет обеспечить защиту персональных данных
						Пользователей сайта от неправомерного использования или утраты в порядке, установленном
						законодательством РФ.
					</p>
					<p className="text2">
						4.6. Администрация сайта обязана принимать меры, необходимые и достаточные для
						обеспечения выполнения обязанностей, предусмотренных Федеральным законом и принятыми в
						соответствии с ним нормативными правовыми актами. Администрация сайта самостоятельно
						определяет состав и перечень мер, необходимых и достаточных для обеспечения выполнения
						обязанностей, предусмотренных Федеральным законом и принятыми в соответствии с ним
						нормативными правовыми актами. К таким мерам могут, в частности, относиться:
						<ul>
							<li>1) назначение ответственного за организацию обработки персональных данных;</li>
							<li>
								2) издание документов, определяющих его политику в отношении обработки персональных
								данных, локальных актов по вопросам обработки персональных данных, а также локальных
								актов, устанавливающих процедуры, направленные на предотвращение и выявление
								нарушений законодательства Российской Федерации, устранение последствий таких
								нарушений;
							</li>
							<li>
								3) применение правовых, организационных и технических мер по обеспечению
								безопасности персональных данных;
							</li>
							<li>
								4) осуществление внутреннего контроля и (или) аудита соответствия обработки
								персональных данных Федеральному закону и принятым в соответствии с ним нормативным
								правовым актам, требованиям к защите персональных данных, политике сайта в отношении
								обработки персональных данных, локальным актам сайта;
							</li>
							<li>
								5) оценка вреда, который может быть причинен субъектам персональных данных в случае
								нарушения Федерального закона, соотношение указанного вреда и принимаемых сайтом
								мер, направленных на обеспечение выполнения обязанностей, предусмотренных
								Федеральным законом;
							</li>
							<li>
								6) ознакомление работников сайта, непосредственно осуществляющих обработку
								персональных данных, с положениями законодательства Российской Федерации о
								персональных данных, в том числе требованиями к защите персональных данных,
								документами, определяющими политику сайта в отношении обработки персональных данных,
								локальными актами по вопросам обработки персональных данных, и (или) обучение
								указанных работников.
							</li>
						</ul>
					</p>
				</div>

				<div className="policy__text">
					<h3 className="h3">5. Права пользователей на защиту своих персональных данных</h3>
					<p className="text2">
						5.1. Пользователи в целях обеспечения защиты своих персональных данных, хранящихся на
						сайте, имеют право:
						<ul>
							<li>
								- получать полную информацию о своих персональных данных, их обработке, хранении и
								передаче;
							</li>
							<li>- определять своих представителей для защиты своих персональных данных;</li>
							<li>
								- требовать исключения или исправления неверных или неполных персональных данных, а
								также данных, обработанных с нарушениями настоящего Положения и законодательства РФ;
							</li>
							<li>
								- требовать от администрации сайта извещения всех лиц, которым ранее были сообщены
								неверные или неполные персональные данные Пользователей, обо всех произведенных в
								них исключениях, исправлениях или дополнениях.
							</li>
						</ul>
						При отказе администрации сайта исключить или исправить персональные данные Пользователей
						Пользователи вправе заявить администрации сайта в письменном виде о своем несогласии с
						соответствующим обоснованием.
					</p>
					<p className="text2">
						5.2. Пользователи вправе самостоятельно ограничить сбор информации третьими лицами,
						используя стандартные настройки конфиденциальности применяемого ими для работы с сайтом
						интернет-браузера, а также в любое время изменить, удалить или дополнить представленные
						ими персональные данные.
					</p>
					<p className="text2">
						5.3. Если Пользователи считают, что обработка их персональных данных осуществляется с
						нарушением требований Федерального закона или иным образом нарушает их права и свободы,
						они вправе обжаловать действия или бездействие администрации сайта в уполномоченный
						орган по защите прав субъектов персональных данных или в судебном порядке.
					</p>
					<p className="text2">
						5.4. Пользователи вправе в любой момент самостоятельно отредактировать в своем Личном
						кабинете предоставленные ими при регистрации или авторизации персональные данные.
					</p>
					<p className="text2">
						5.5. Пользователи не должны отказываться от своих прав на сохранение и защиту тайны.
					</p>
				</div>

				<div className="policy__text">
					<h3 className="h3">6. Порядок уничтожения, блокирования персональных данных</h3>
					<p className="text2">
						6.1. В случае выявления неправомерной обработки персональных данных при обращении
						Пользователей администрация сайта обязана осуществить блокирование неправомерно
						обрабатываемых персональных данных, относящихся к этим Пользователям, с момента такого
						обращения на период проверки.
					</p>
					<p className="text2">
						6.2. В случае выявления неточных персональных данных при обращении Пользователей
						администрация сайта обязана осуществить блокирование персональных данных, относящихся к
						этим Пользователям, с момента такого обращения на период проверки, если блокирование
						персональных данных не нарушает права и законные интересы пользователей или третьих лиц.
					</p>
					<p className="text2">
						6.3. В случае подтверждения факта неточности персональных данных администрация сайта на
						основании сведений, представленных Пользователями, или иных необходимых документов
						обязана уточнить персональные данные в течение 10 рабочих дней со дня представления
						таких сведений и снять блокирование персональных данных.
					</p>
					<p className="text2">
						6.4. В случае выявления неправомерной обработки персональных данных, осуществляемой
						сайтом, администрация сайта в срок, не превышающий 10 рабочих дней с даты этого
						выявления, обязана прекратить неправомерную обработку персональных данных.
					</p>
					<p className="text2">
						6.5. В случае если обеспечить правомерность обработки персональных данных невозможно,
						администрация сайта в срок, не превышающий 10 рабочих дней с даты выявления
						неправомерной обработки персональных данных, обязана уничтожить такие персональные
						данные.
					</p>
					<p className="text2">
						6.6. Об устранении допущенных нарушений или об уничтожении персональных данных
						администрация сайта обязана уведомить Пользователей.
					</p>
					<p className="text2">
						6.7. В случае достижения цели обработки персональных данных администрация сайта обязана
						прекратить обработку персональных данных и уничтожить персональные данные в срок, не
						превышающий 30 дней с даты достижения цели обработки персональных данных.
					</p>
					<p className="text2">
						6.8. В случае отзыва Пользователями согласия на обработку их персональных данных
						администрация сайта обязана прекратить их обработку и в случае, если сохранение
						персональных данных более не требуется для целей обработки персональных данных,
						уничтожить персональные данные в срок, не превышающий 30 дней с даты поступления
						указанного отзыва.
					</p>
					<p className="text2">
						6.9. В случае отсутствия возможности уничтожения персональных данных в течение срока,
						указанного в пунктах 6.4 – 6.8 настоящей Политики конфиденциальности, администрация
						сайта осуществляет блокирование таких персональных данных и обеспечивает уничтожение
						персональных данных в срок не более чем 6 месяцев, если иной срок не установлен
						федеральными законами.
					</p>
				</div>
				<div className="policy__text">
					<h3 className="h3">
						7. Ответственность за нарушение норм, регулирующих обработку и защиту персональных
						данных пользователей сайта
					</h3>
					<p className="text2">
						7.1. Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту
						персональных данных Пользователей сайта, привлекаются к дисциплинарной, материальной,
						гражданско-правовой, административной и уголовной ответственности в порядке,
						установленном действующим законодательством РФ.
					</p>
					<p className="text2">
						7.2. Моральный вред, причиненный Пользователям сайта вследствие нарушения их прав,
						нарушения правил обработки персональных данных, установленных Федеральным законом, а
						также требований к защите персональных данных, установленных в соответствии с
						Федеральным законом, подлежит возмещению в соответствии с законодательством Российской
						Федерации. Возмещение морального вреда осуществляется независимо от возмещения
						имущественного вреда и понесенных Пользователями убытков.
					</p>
				</div>

				<div className="policy__text">
					<h3 className="h3">8. Изменение политики конфиденциальности</h3>
					<p className="text2">
						8.1. Настоящая Политика конфиденциальности может быть изменена или прекращена
						администрацией сайта в одностороннем порядке без предварительного уведомления
						Пользователя. Новая редакция Политики конфиденциальности вступает в силу с момента ее
						размещения на сайте, если иное не предусмотрено новой редакцией Политики
						конфиденциальности.
					</p>
					<p className="text2">
						8.2. Действующая редакция Политики конфиденциальности находится на сайте в
						информационно-телекоммуникационной сети &quot;Интернет&quot; по адресу:
						<a href="http://x-technology.ru/policy"> http://x-technology.ru/policy</a>.
					</p>
				</div>
			</div>
		</div>
	</section>
))
