import React from "react";
import { ArrowLink } from "../../../components/Buttons/ArrowLink/ArrowLink";

import "./Page404.scss";

export const EnPage404 = React.memo(() => {
  return (
    <div className="page-404">
      <div className="page-404__left-column">
        <div className="page-404__left-column-vincent"></div>
      </div>
      <div className="page-404__right-column">
        <div className="page-404__right-column-title">
          <span>404</span>
        </div>
        <div className="page-404__right-column-subtitle">
          <span>Failed to find page</span>
        </div>
        {/* <div className="page-404__right-column-text">
					<span>Тут небольшей текст описывающий возможные причины ошибки. В две строки</span>
				</div> */}
        <ArrowLink path="/" text="Go to main page" />
      </div>
    </div>
  );
});
