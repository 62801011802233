import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import './Popup.scss'

export const Popup = React.memo(({ show = false, children, title, onClose }) => {
	const ref = useRef(null)

	if (show) {
		document.body.style.overflow = 'hidden'
	}

	const closePopup = () => {
		document.body.style.overflow = null
		onClose(false)
	}

	const hideModalKey = useCallback(
		(event) => {
			if (event.key === 'Escape') {
				document.body.style.overflow = null
				onClose(false)
			}
		},
		[onClose]
	)

	const hideModalClick = useCallback(
		(event) => {
			if (ref.current && !ref.current.contains(event.target) && event.target.tagName !== 'IMG') {
				document.body.style.overflow = null
				onClose(false)
			}
		},
		[onClose]
	)

	useEffect(() => {
		document.addEventListener('keydown', (e) => {
			hideModalKey(e)
		})

		document.addEventListener('click', (e) => {
			if (e.target.id !== 'remove-icon') {
				hideModalClick(e)
			}
		})

		return () => {
			document.removeEventListener('keydown', (e) => {
				hideModalKey(e)
			})

			document.removeEventListener('click', (e) => {
				hideModalClick(e)
			})
		}
	}, [hideModalKey, hideModalClick])

	return (
		<div className="popup-plug">
			<div ref={ref} className="popup-wrapper">
				<div className="popup-title">{title}</div>
				<button className="popup-close" onClick={() => closePopup()}></button>
				{children}
			</div>
		</div>
	)
})

Popup.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	onClose: PropTypes.func,
}
