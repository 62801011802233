import React from 'react'
import cn from 'classnames'

import { url } from '../../../../../api/api'

export const GalleryImg = React.memo(({ item, index, mobile, showGallery = () => {} }) => {
	return (
		<button
			className={cn('event__gallery-item', { wide: index < 2 && mobile > 480 })}
			onClick={showGallery}
		>
			<img src={item !== '' && `${url}/static/${item}`} alt={`gallery`} />
			<div className="blur" style={{ backgroundImage: `url(${url}/static/${item})` }}></div>
		</button>
	)
})
