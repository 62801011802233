import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { BlueButton } from '../Buttons/BlueButton/BlueButton'

import './TechnologyCard.scss'

export const TechnologyCard = React.memo(
	({ icon, title, body, button, openPopup, deviceWidth, active, onClick, index }) => (
		<div className="techno-card__box" onClick={onClick}>
			<div className="techno-card__img">
				<img src={icon} alt="tech" />
			</div>
			<div className="techno-card__item">
				<h2 className="techno-card__title h2">
					<span>{title}</span>
				</h2>
				<div className="techno-card__text text2">{body}</div>
				<BlueButton text={button} openPopup={openPopup} />
			</div>
			{deviceWidth < 1025 && (
				<div
					className={cn('techno-card__text techno-card__text-hover text2 ', {
						active: index === active,
					})}
				>
					{body}
				</div>
			)}
		</div>
	)
)

TechnologyCard.propTypes = {
	icon: PropTypes.node,
	title: PropTypes.node,
	body: PropTypes.node,
	tag: PropTypes.node,
	button: PropTypes.node,
	openPopup: PropTypes.func,
}
