import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	messages: [],
}

const globalMessages = createSlice({
	name: 'globalMessages',
	initialState,
	reducers: {
		addMessage: (state, action) => {
			const { messages } = state
			state.messages = [
				...(messages.length < 3 ? messages : messages.slice(1, 3)),
				{
					text: action.payload.text,
					type: action.payload.type,
				},
			]
		},
		delMessage: (state, action) => {
			state.messages = state.messages.filter((message) => message.type !== action.payload.type)
		},
	},
})

const { reducer, actions } = globalMessages

export const { addMessage, delMessage } = actions

export default reducer
