import React from 'react'
import { FilterContent } from '../FilterContent/FilterContent'
import { SpecialisationItem } from '../../../../components/SpecialisationItem/SpecialisationItem'
import { FilterItem } from './FilterItem/FilterItem'

export const Filter = React.memo(
	({
		localTechList,
		localSpecList,
		handleSpecialization,
		handleSkills,
		activeCheckbox,
		activeSkills,
		place,
		eng,
	}) => {
		return (
			<div className="team__filter-wrapper">
				<FilterContent title={eng ? 'Specializations' : 'Специализации'}>
					{localSpecList?.map((item, index) => (
						<SpecialisationItem
							key={`${item[0]}-${index}`}
							item={item}
							index={index}
							handler={handleSpecialization}
							active={activeCheckbox}
						/>
					))}
				</FilterContent>
				<FilterContent title={eng ? 'Technologies' : 'Технологии'}>
					{localTechList?.map((item, index) => (
						<FilterItem
							key={`${item.title}-${index}`}
							skills={item.tech_skills}
							handler={handleSkills}
							active={activeSkills}
							title={item.title}
							place={place}
						/>
					))}
				</FilterContent>
			</div>
		)
	}
)
