import instance from './api'
import instanceCluster from './api-cluster'

const constructorApi = {
	async requestUsers(data) {
		const response = await instance.post('/devs', data)
		return response.data
	},

	async requestFilters(lang) {
		const response = await instanceCluster.post(`/support/translate?type=skill&lang=${lang}`)

		return response.data
	},

	async sendQuickSearchTeam(data) {
		const response = await instance.post('/qs/team/create', data)

		return response.data
	},

	async sendTeam(data) {
		const response = await instance.post('/team/create', data)

		return response.data
	},
}

export default constructorApi
