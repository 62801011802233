import axios from 'axios'

//временная запись
const hash = window.location.host.includes('localhost')

export const url = hash ? process.env.REACT_APP_HTTPS_URL : '/backend'

const instance = axios.create({ baseURL: url, timeout: 10000 })

export default instance
