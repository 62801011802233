import React from 'react'
import cn from 'classnames'

import './TextareaField.scss'

export const TextareaField = React.memo(({ name, value, title, onChange, error }) => (
	<div
		className={cn('contact-us__form-textarea', { full: value !== '' && value !== null }, { error })}
	>
		<textarea
			className="contact-us__form-idea"
			autoComplete="off"
			value={value}
			onChange={({ target: { value } }) => onChange(value)}
			name={name}
		/>
		<label htmlFor={name}>{title}</label>
	</div>
))
