import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './arrowlink.scss'

export const ArrowLink = React.memo(({ small, path, text, eventId }) => (
	<Link to={path}>
		<button className={`arrow-link ${small ? 'sm' : 'h3'}`} onClick={eventId}>
			{text}
		</button>
	</Link>
))

ArrowLink.propTypes = {
	small: PropTypes.bool,
	path: PropTypes.string,
	text: PropTypes.string,
	eventId: PropTypes.func,
}
