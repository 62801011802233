import service1 from "../assets/images/choose_teams_main.jpg";
import service2 from "../assets/images/outsource_main.jpg";
import service3 from "../assets/images/develop_instruments_main.jpg";
import team1 from "../assets/images/main-team1.jpg";
import team2 from "../assets/images/main-team2.jpg";
import team3 from "../assets/images/main-team3.jpg";
import team4 from "../assets/images/main-team4.jpg";
import tech1 from "../assets/images/icons/tech1.svg";
import tech2 from "../assets/images/icons/tech2.svg";
import tech3 from "../assets/images/icons/tech3.svg";
import tech4 from "../assets/images/icons/tech4.svg";
import tech5 from "../assets/images/icons/tech5.svg";
import tech6 from "../assets/images/icons/tech6.svg";
import tech7 from "../assets/images/icons/tech7.svg";
import tech8 from "../assets/images/icons/tech8.svg";
import htmlCSS from "../assets/images/icons/technologies/HTML5_CS3.svg";
import cSharp from "../assets/images/icons/technologies/logos_c-sharp.svg";
import cPlusPlus from "../assets/images/icons/technologies/logos_c-plusplus.svg";
import firebase from "../assets/images/icons/technologies/vscode-icons_file-type-firebase.svg";
import flutter from "../assets/images/icons/technologies/logos_flutter.svg";
import jquery from "../assets/images/icons/technologies/cib_jquery.svg";
import JS from "../assets/images/icons/technologies/cib_javascript.svg";
import php from "../assets/images/icons/technologies/logos_php.svg";
import python from "../assets/images/icons/technologies/logos_python.svg";
import qt from "../assets/images/icons/technologies/simple-icons_qt.svg";
import react from "../assets/images/icons/technologies/logos_react.svg";
import redux from "../assets/images/icons/technologies/bx_bxl-redux.svg";
import swift from "../assets/images/icons/technologies/vscode-icons_file-type-swift.svg";
import search from "../assets/images/icons/search.svg";
import check from "../assets/images/icons/check.svg";
import education from "../assets/images/icons/education.svg";
import warranty from "../assets/images/icons/warranty.svg";

// фотографии команды
import boss from "../assets/images/employees/Ivanov.jpg";
import lex from "../assets/images/employees/Shukin.jpg";
import god from "../assets/images/employees/Perevozshikov.jpg";
import ega from "../assets/images/employees/Koshelenko.jpg";
import den from "../assets/images/employees/Batalin.jpg";
// import nofoto from '../assets/images/backgrounds/no_photo.png'

// иконки соцсетей
// import fb from '../assets/images/icons/facebook_m.svg'
// import insta from '../assets/images/icons/instagram_m.svg'
// import yoube from "../assets/images/icons/youtube_m.svg";
import vk from "../assets/images/icons/vk_m.svg";
// import linkedin from "../assets/images/icons/linkedin.svg";

export const MAIN = {
  service: [
    {
      id: "team",
      img: service1,
      title: "Выделенная команда",
      text: "Подберем квалифицированных специалистов на любом этапе создания Вашего проекта. Суммарный опыт нашей команды более 10 лет.",
    },
    {
      id: "ousource",
      img: service2,
      title: "Аутсорсинг",
      text: "Мы имеем богатый опыт создания проектов под ключ и можем предоставить Вам полный цикл разработки и поддержки Вашего проекта или инфраструктуры.",
    },
    {
      id: "dev",
      img: service3,
      title: "Разработка электроники",
      text: "Проводим R&D, проектируем и разрабатываем электронику с учётом всех ваших пожеланий. X-Lab — это команда инженеров, учёных, разработчиков и других талантливых людей.",
    },
  ],
  team: [
    {
      img: team1,
      title: "Александр С.",
      type: "Front-end разработчик",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
    {
      img: team2,
      title: "Дмитрий П.",
      type: "Front-end разработчик",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
    {
      img: team3,
      title: "Наталья Л.",
      type: "Front-end разработчик",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
    {
      img: team4,
      title: "Евгений Ш.",
      type: "Front-end разработчик",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
  ],
  tech: [
    {
      icon: tech1,
      title: "Front-End",
      skills:
        "HTML5, CSS3, JavaScript, jQuery, React, Redux, WebGL, Vue, Three.JS, Sass, Less, Flutter Desktop, React Native",
    },
    {
      icon: tech2,
      title: "Back-end",
      skills: "FireBase, PHP, Golang, Python, Node JS, Nginx, Apache, Docker",
    },
    {
      icon: tech3,
      title: "Приложения",
      skills: "C++, C#, QT, GTK, Java, Unity, Laravel, Jest, Joomla",
    },
    {
      icon: tech4,
      title: "Мобильные приложения",
      skills: "Android Studio, iOS Swift, Flutter",
    },
    {
      icon: tech5,
      title: "Базы данных",
      skills:
        "Oracle, Delphi, MySQL, LightSQL, MongoDB, MariaDB, PostgreSQL, SQLite, LevelDB, DynamoDB, NoSQL",
    },
    // {
    //   icon: tech6,
    //   title: "Блокчейн",
    //   skills: "BasicChain, Ethereum, Bitcoin, Waves",
    // },
    {
      icon: tech7,
      title: "Дизайн и мультимедиа",
      skills:
        "Прототипирование, Продуктовый дизайн, Веб-дизайн, Графический дизайн, Motion дизайн",
    },
    {
      icon: tech8,
      title: "Геймдев",
      skills:
        "Геймдизайн, Прототипирование, Unity, Unreal Engine 4, Арт, Нарративный дизайн, Дизайн интерфейсов",
    },
  ],
  selection: [
    {
      num: 1,
      text: "Мы знакомимся с вашим проектом и его целями",
    },
    {
      num: 2,
      text: "Подбираем подходящую для вашего проекта команду",
    },
    {
      num: 3,
      text: "Проводим с командой мозговой штурм и включаемся в проект",
    },
    {
      num: 4,
      text: "Сопровождаем команду <br />на протяжении всего проекта",
    },
  ],
};

export const ENG_MAIN = {
  service: [
    {
      id: "team",
      img: service1,
      title: "DEDICATED TEAM",
      text: "We will select qualified specialists at any stage of your project. The total experience of our team is more than 10 years.",
    },
    {
      id: "ousource",
      img: service2,
      title: "Outsourcing",
      text: "We have a long experience in creating turnkey projects and can provide you with a full cycle of development and support of your project or infrastructure.",
    },
    {
      id: "dev",
      img: service3,
      title: "Development of hardware ",
      text: "We implement R&D, design and develop electronics according to your wishes. X-Lab is a team of engineers, scientists, developers and other talented people.",
    },
  ],
  team: [
    {
      img: team1,
      title: "Alexander S.",
      type: "Front-end developer",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
    {
      img: team2,
      title: "Dmitriy P.",
      type: "Front-end developer",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
    {
      img: team3,
      title: "Natalia L.",
      type: "Front-end developer",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
    {
      img: team4,
      title: "Eugeniy S.",
      type: "Front-end developer",
      skills: [
        "HTML5",
        "CSS3",
        "WebGL",
        "jQuery",
        "JavaScript",
        "Flutter Desktop",
        "React Native",
      ],
    },
  ],
  tech: [
    {
      icon: tech1,
      title: "Front-End",
      skills:
        "HTML5, CSS3, JavaScript, jQuery, React, Redux, WebGL, Vue, Three.JS, Sass, Less, Flutter Desktop, React Native",
    },
    {
      icon: tech2,
      title: "Back-end",
      skills: "FireBase, PHP, Golang, Python, Node JS, Nginx, Apache, Docker",
    },
    {
      icon: tech3,
      title: "APPLICATIONS",
      skills: "C++, C#, QT, GTK, Java, Unity, Laravel, Jest, Joomla",
    },
    {
      icon: tech4,
      title: "MOBILE APPS",
      skills: "Android Studio, iOS Swift, Flutter",
    },
    {
      icon: tech5,
      title: "DATABASES",
      skills:
        "Oracle, Delphi, MySQL, LightSQL, MongoDB, MariaDB, PostgreSQL, SQLite, LevelDB, DynamoDB, NoSQL",
    },
    // {
    //   icon: tech6,
    //   title: "BLOCKCHAIN",
    //   skills: "BasicChain, Ethereum, Bitcoin, Waves",
    // },
    {
      icon: tech7,
      title: "DESIGN AND MULTIMEDIA",
      skills:
        "Wireframes, Product design, Web-design, Graphical design, Motion design",
    },
    {
      icon: tech8,
      title: "GAMEDEV",
      skills:
        "Game design and prototyping, Unity, Unreal Engine 4, Narrative design, Interface design",
    },
  ],
  selection: [
    {
      num: 1,
      text: "We review your project and determine its goals",
    },
    {
      num: 2,
      text: "We pick the right team for your project",
    },
    {
      num: 3,
      text: " Once we brainstorm with our team, we start working on your project",
    },
    {
      num: 4,
      text: "Accompany the team throughout the project",
    },
  ],
};

export const SERVICE = {
  special: [
    {
      icon: tech1,
      title: "Front-End",
    },
    {
      icon: tech2,
      title: "Back-end",
    },
    {
      icon: tech3,
      title: "Приложения",
    },
    {
      icon: tech4,
      title: "Мобильные приложения",
    },
    {
      icon: tech5,
      title: "Базы данных",
    },
    {
      icon: tech6,
      title: "Блокчейн",
    },
    {
      icon: tech7,
      title: "Дизайн и мультимедиа",
    },
    {
      icon: tech8,
      title: "Геймдев",
    },
  ],
  tech: [
    {
      icon: htmlCSS,
      title: "HTML5 / CS3",
    },
    {
      icon: JS,
      title: "JavaScript",
    },
    {
      icon: jquery,
      title: "JQuery",
    },
    {
      icon: react,
      title: "React",
    },
    {
      icon: redux,
      title: "Redux",
    },
    {
      icon: firebase,
      title: "FireBase",
    },
    {
      icon: php,
      title: "PHP",
    },
    {
      icon: python,
      title: "Python",
    },
    {
      icon: cPlusPlus,
      title: "C++",
    },
    {
      icon: cSharp,
      title: "C#",
    },
    {
      icon: qt,
      title: "QT",
    },
    {
      icon: swift,
      title: "Swift",
    },
    {
      icon: flutter,
      title: "Flutter",
    },
  ],
  selection: [
    {
      icon: search,
      text: "находим",
    },
    {
      icon: check,
      text: "проверяем",
    },
    {
      icon: education,
      text: "обучаем",
    },
    {
      icon: warranty,
      text: "Гарантируем профессионализм",
    },
  ],
};

export const EN_SERVICE = {
  special: [
    {
      icon: tech1,
      title: "Front-End",
    },
    {
      icon: tech2,
      title: "Back-end",
    },
    {
      icon: tech3,
      title: "APPLICATIONS",
    },
    {
      icon: tech4,
      title: "MOBILE APPS",
    },
    {
      icon: tech5,
      title: "DATABASES",
    },
    // {
    //   icon: tech6,
    //   title: "BLOCKCHAIN",
    // },
    {
      icon: tech7,
      title: "DESIGN AND MULTIMEDIA",
    },
    {
      icon: tech8,
      title: "GAMEDEV",
    },
  ],
  tech: [
    {
      icon: htmlCSS,
      title: "HTML5 / CS3",
    },
    {
      icon: JS,
      title: "JavaScript",
    },
    {
      icon: jquery,
      title: "JQuery",
    },
    {
      icon: react,
      title: "React",
    },
    {
      icon: redux,
      title: "Redux",
    },
    {
      icon: firebase,
      title: "FireBase",
    },
    {
      icon: php,
      title: "PHP",
    },
    {
      icon: python,
      title: "Python",
    },
    {
      icon: cPlusPlus,
      title: "C++",
    },
    {
      icon: cSharp,
      title: "C#",
    },
    {
      icon: qt,
      title: "QT",
    },
    {
      icon: swift,
      title: "Swift",
    },
    {
      icon: flutter,
      title: "Flutter",
    },
  ],
  selection: [
    {
      icon: search,
      text: "SEARCH",
    },
    {
      icon: check,
      text: "CHECK",
    },
    {
      icon: education,
      text: "TRAIN",
    },
    {
      icon: warranty,
      text: "COMPETENCE IS GUARANTEED",
    },
  ],
};

export const CAREER = {
  tech: [
    {
      icon: tech1,
      title: "Front-End",
      skills:
        "HTML5, CSS3, JavaScript, jQuery, React, Redux, WebGL, Vue, Three.JS, Sass, Less, Flutter Desktop, React Native",
    },
    {
      icon: tech2,
      title: "Back-end",
      skills: "FireBase, PHP, Golang, Python, Node JS, Nginx, Apache, Docker",
    },
    {
      icon: tech3,
      title: "Приложения",
      skills: "C++, C#, QT, GTK, Java, Unity, Laravel, Jest, Joomla",
    },
    {
      icon: tech4,
      title: "Мобильные приложения",
      skills: "Android Studio, iOS Swift, Flutter",
    },
    {
      icon: tech5,
      title: "Базы данных",
      skills:
        "Oracle, Delphi, MySQL, LightSQL, MongoDB, MariaDB, PostgreSQL, SQLite, LevelDB, DynamoDB, NoSQL",
    },
    // {
    //   icon: tech6,
    //   title: "Блокчейн",
    //   skills: "BasicChain, Ethereum, Bitcoin, Waves",
    // },
    {
      icon: tech7,
      title: "Дизайн и мультимедиа",
      skills:
        "Прототипирование, Продуктовый дизайн, Веб-дизайн, Графический дизайн, Motion дизайн",
    },
    {
      icon: tech8,
      title: "Геймдев",
      skills:
        "Геймдизайн, Прототипирование, Unity, Unreal Engine 4, Арт, Нарративный дизайн, Дизайн интерфейсов",
    },
  ],
  questions: [
    {
      question: "Предлагает ли X-Technology стажировку?",
      answer:
        "Следуя философии нашей компании, мы всегда стараемся содействовать начинающим специалистам и готовы помогать в развитии. Поэтому, если ты - junior, то наши опытные разработчики направят тебя, а мы поможем в самореализации.",
    },
    {
      question: "Как можно попасть в команду X-Technology?",
      answer:
        "В первую очередь мы ищем талантливых специалистов, которые находятся в постоянном развитии и поиске интересных и сложных задач. Для того чтобы попасть к нам в команду, необходимо отправить нам Ваше резюме, и мы рассмотрим его в ближайшее время. После рассмотрения мы свяжемся с Вами и проведем собеседование.",
    },
    {
      question: "Может ли X-Technology помочь мне с переездом?",
      answer:
        "Компанией X-Technology в рамках собственной внутренней программы по переселению IT-специалистов был перевезен уже не один десяток сотрудников. Вы можете стать одним из этих кандидатов. Для этого Вам необходимо подать заявку, пройти собеседование, и, в некоторых случаях, тестовый период (испытательный срок).",
    },
    {
      question:
        "Какие формы сотрудничества (варианты\\способы оформления) возможны?",
      answer:
        "Официальное трудоустройство в соответствии с ТК РФ. Также мы работаем как с ИП, так и с самозанятыми.",
    },
    {
      question: "Предпочтителен ли формат офисной работы?",
      answer:
        "Практика показывает, что человеку очень важно менять обстановку. Поэтому мы не привязываем наших сотрудников к конкретному офису, а предлагаем целую сеть распределенных офисов, в рамках программы X-Cluster.",
    },
  ],
};

export const EN_CAREER = {
  tech: [
    {
      icon: tech1,
      title: "Front-End",
      skills:
        "HTML5, CSS3, JavaScript, jQuery, React, Redux, WebGL, Vue, Three.JS, Sass, Less, Flutter Desktop, React Native",
    },
    {
      icon: tech2,
      title: "Back-end",
      skills: "FireBase, PHP, Golang, Python, Node JS, Nginx, Apache, Docker",
    },
    {
      icon: tech3,
      title: "APPLICATIONS",
      skills: "C++, C#, QT, GTK, Java, Unity, Laravel, Jest, Joomla",
    },
    {
      icon: tech4,
      title: "MOBILE APPS",
      skills: "Android Studio, iOS Swift, Flutter",
    },
    {
      icon: tech5,
      title: "DATABASES",
      skills:
        "Oracle, Delphi, MySQL, LightSQL, MongoDB, MariaDB, PostgreSQL, SQLite, LevelDB, DynamoDB, NoSQL",
    },
    // {
    //   icon: tech6,
    //   title: "BLOCKCHAIN",
    //   skills: "BasicChain, Ethereum, Bitcoin, Waves",
    // },
    {
      icon: tech7,
      title: "DESIGN AND MULTIMEDIA",
      skills:
        "Wireframes, Product design, Web-design, Graphical design, Motion design",
    },
    {
      icon: tech8,
      title: "GAMEDEV",
      skills:
        "Game design and prototyping, Unity, Unreal Engine 4, Narrative design, Interface design",
    },
  ],
  questions: [
    {
      question: "DOES X-TECHNOLOGY OFFER AN INTERNSHIP?",
      answer:
        "Following the philosophy of our company, we always try to help beginners and are ready to help in improvement. Therefore, if you are a junior, our experienced developers will guide you in self-fulfillment.",
    },
    {
      question: "HOW DO YOU GET INTO THE X-TECHNOLOGY TEAM?",
      answer:
        "First of all we are looking for talented specialists who are progressive and searching for interesting and difficult tasks. In order to join our team, it is necessary to send us your CV and we will consider it as soon as possible. After consideration, we will contact you and place an interview.",
    },
    {
      question: "CAN X-TECHNOLOGY HELP ME WITH RELOCATION?",
      answer:
        "X-Technology, as part of its own IT relocation program, has moved more than a dozen employees. You could be one of those candidates. To do this, you need to submit an application, undergo an interview, and in some cases, a test period. ",
    },
    // {
    //   question: "WHAT FORMS OF COOPERATION ARE POSSIBLE?",
    //   answer:
    //     "Official employment in accordance with Russian Federation Law. We also work with both sole proprietors and self-employed people.",
    // },
    {
      question: "IS THE FORMAT OF OFFICE WORK PREFERABLE?",
      answer:
        "Experience shows that it is very important for a person to change their lifestyle. Therefore, we do not bind our employees to a specific office, but offer a whole network of distributed offices, within the X-Cluster community. ",
    },
  ],
};

export const COMPANY = {
  people: [
    {
      img: boss,
      name: "Александр Иванов",
      career: "Генеральный директор",
    },
    {
      img: lex,
      name: "Алексей Щукин",
      career: "Исполнительный директор",
    },
    {
      img: den,
      name: "Денис Баталин",
      career: "Финансовый директор",
    },
    {
      img: god,
      name: "Дмитрий Перевощиков",
      career: "Технический директор",
    },
    {
      img: ega,
      name: "Егор Кошеленко",
      career: "Teamlead",
    },
  ],
};

export const EN_COMPANY = {
  people: [
    {
      img: boss,
      name: "ALEXANDER IVANOV",
      career: "CEO",
    },
    {
      img: lex,
      name: "ALEXEY SCHUKIN ",
      career: "COO",
    },
    {
      img: den,
      name: "DENIS BATALIN",
      career: "CFO",
    },
    {
      img: god,
      name: "Dmitriy Perevozschikov",
      career: "CTO",
    },
    {
      img: ega,
      name: "Egor Koshelenko",
      career: "Teamlead",
    },
  ],
};

export const ContactSocialArr = [
  // {
  //   link: "https://www.linkedin.com/company/x-technology-org/",
  //   icon: linkedin,
  //   alt: "linkedin",
  // },
  // {
  // 	link: 'https://www.facebook.com/xcluster39/',
  // 	icon: fb,
  // 	alt: 'facebook',
  // },
  // {
  // 	link: 'https://www.instagram.com/x__cluster/',
  // 	icon: insta,
  // 	alt: 'instagram',
  // },
  // {
  //   link: "https://www.youtube.com/@x-technologyitcompany790/featured",
  //   icon: yoube,
  //   alt: "youtube",
  // },
  {
    link: "https://vk.com/x_welcome",
    icon: vk,
    alt: "Вконтакте",
  },
];
