import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { PagesHeader } from '../../components/PagesHeader/PagesHeader'
import { Brands } from '../../components/Brands/Brands'
import { ContactFooter } from '../../components/ContactFooter/ContactFooter'
import { XLine } from '../../components/X-line/XLine'
import { SpecializationBox } from '../../components/InfoBoxes/SpecializationBox/SpecializationBox'

import { addRequest } from '../../store/reducers/requests/requests'
import { getResult } from '../../store/reducers/requests/requests-selector'
import { showMessage } from '../../store/reducers/functions'

import { validate } from '../functions'

import { SERVICE } from '../../StaticInfo/StaticInfo'

import './Services.scss'

import background from '../../assets/images/backgrounds/services-header.png'
import commandImg from '../../assets/images/choose_teams.jpg'
import outsourcingImg from '../../assets/images/outsource.jpg'
import equipmentImg from '../../assets/images/develop_instruments.jpg'

export const Services = React.memo(() => {
	const dispatch = useDispatch()
	const history = useHistory()

	const result = useSelector(getResult)

	const [name, setName] = useState('')
	const [phone, setContactInput] = useState('')
	const [company, setExtraInput] = useState('')
	const [email, setLinkInput] = useState('')
	const [description, setTextInput] = useState('')
	const [checkbox, setCheckbox] = useState(false)

	const [errorData, setErrorData] = useState({
		name: true,
		phone: true,
		email: true,
		checkbox: true,
		company: true,
		description: true,
	})

	const requestForm = () => {
		const data = {
			name: validate('name', name) ? name : '',
			company: validate('company', company) && company,
			phone: validate('phone', phone) ? phone : '',
			email: validate('email', email) ? email : '',
			description: validate('description', description) && description,
		}

		if (!data.name || !data.phone || !data.email || !checkbox) {
			setErrorData({
				name: data.name ? true : false,
				phone: data.phone ? true : false,
				email: data.email ? true : false,
				checkbox: checkbox ? true : false,
				company: data.company === '' || data.company ? true : false,
				description: data.description === '' || data.description ? true : false,
			})

			showMessage(dispatch, { code: 'error', msg: 'Заполните все необходимые поля' })
		} else {
			dispatch(addRequest(data))
		}
	}

	useEffect(() => {
		if (result) {
			setName('')
			setContactInput('')
			setExtraInput('')
			setLinkInput('')
			setTextInput('')
			setCheckbox(false)
			setErrorData({
				name: true,
				phone: true,
				email: true,
				checkbox: true,
				company: true,
				description: true,
			})
		}
	}, [result])

	return (
		<div className="services-page">
			{/* header */}
			<PagesHeader image={background} title={'Сервисы'}>
				<p>
					Для решения задач Вашего проекта мы предлагаем Вам более 4000 профессионалов с разным
					опытом и гарантированной квалификацией, которые могут работать в рамках выделенных команд
					и осуществлять разработку под ключ.
				</p>
			</PagesHeader>
			<Brands />
			{/* command-block */}
			<div className="command-block" id="team">
				<div className="container">
					<div className="command-block__info-block">
						<div className="command-block__title h1">Выделенная команда</div>
						<div className="services-page__image mobile-img">
							<picture>
								<source srcSet={commandImg} media="(max-width: 1440px)" />
								<source srcSet={commandImg} />
								<img srcSet={commandImg} alt="command" className="why__img" />
							</picture>
							<div className="blur" style={{ backgroundImage: `url(${commandImg})` }}></div>
						</div>
						<div className="command-block__text services-page__text">
							<span>
								<p>
									Наша компания предлагает Вам гибкую модель сотрудничества. Мы подберем специалиста
									или многофункциональную команду, которая будет органично сочетаться с вашей
									внутренней командой и будет соответствовать всем Вашим требованиям.
								</p>
								<p>
									Вы полностью управляете Вашей командой, а мы берем на себя всю инфраструктуру и
									административную поддержку.
								</p>
								<p>
									Также мы можем предоставить своих специалистов для управления проектом под ключ.
								</p>
							</span>
						</div>
					</div>
					<div className="services-page__image">
						<picture>
							<source srcSet={commandImg} media="(max-width: 1440px)" />
							<source srcSet={commandImg} />
							<img srcSet={commandImg} alt="command" className="why__img" />
						</picture>
						<div className="blur" style={{ backgroundImage: `url(${commandImg})` }}></div>
					</div>
					<div className="command-block__specialization-areas">
						<div className="command-block__subtitle services-page__subtitle">
							<span>специалисты в областях</span>
						</div>
						<div className="specialization-areas__block">
							{SERVICE.special.map((item) => (
								<SpecializationBox key={`item-${item.title}`} title={item.title} icon={item.icon} />
							))}
						</div>
					</div>
				</div>
			</div>
			{/* outsourcing */}
			<div className="outsourcing-block" id="ousource">
				<div className="container">
					<div className="outsourcing-block__info-block">
						<div className="outsourcing-block__title h1">
							<span>Аутсорсинг</span>
						</div>
						<div className="outsourcing-block__text services-page__text">
							<span>
								<p>
									Наши специалисты обладают всеми необходимыми квалификациями и могут подобрать
									качественное индивидуальное решение для любого проекта.
								</p>
								<p>
									IT-аутсорсинг поможет наладить бизнес-процессы. Вы сможете достичь целей быстрее и
									с меньшими расходами, передав полномочия профессионалам, которые справятся с
									трудными задачами и доведут проект до успешного завершения.
								</p>
								<p>
									А также Вам не придётся заниматься трудоустройством и подготовкой рабочих мест –
									мы возьмем на себя все вопросы по оформлению и предоставлению инфраструктуры.{' '}
								</p>
							</span>
						</div>
						<div className="services-page__image">
							<picture>
								<source srcSet={outsourcingImg} media="(max-width: 1440px)" />
								<source srcSet={outsourcingImg} />
								<img srcSet={outsourcingImg} alt="command" className="why__img" />
							</picture>
							<div className="blur" style={{ backgroundImage: `url(${outsourcingImg})` }}></div>
						</div>
					</div>
					<div className="outsourcing-block__technologies">
						<div className="outsourcing-block__subtitle services-page__subtitle">
							<span>Технологии</span>
						</div>
						<div className="outsourcing-block__technologies-block">
							{SERVICE.tech.map((item, i) => (
								<div key={`tech-${i}`} className="technologies__item">
									<div className="technologies__item-icon-wrapper">
										<img
											className="technologies__item-icon"
											src={item.icon}
											alt={`${item.title}`}
										/>
									</div>
									<div className="technologies__item-title">
										<span>{item.title}</span>
									</div>
								</div>
							))}
							<div className="technologies-block__last-item">
								<span>и далее</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* create-team__block */}
			<div className="create-team__block">
				<XLine
					title={'Создайте свою команду из 4365 специалистов'}
					button={'Создать команду'}
					openPopup={() => history.push('/team')}
				/>
			</div>
			{/* equipment design */}
			<div className="equipment-design__block" id="dev">
				<div className="container">
					<div className="equipment-design__info-block">
						<h2 className="equipment-design-title h1">
							<span>разработкА оборудования</span>
						</h2>
						<div className="equipment-design__text services-page__text">
							<span>
								Планируете реализацию проекта, для которого вам необходимо нестандартное
								оборудование? Наша команда занимается проектированием и изготовлением аппаратного
								обеспечения для современной электроники с учетом всех ваших пожеланий.
								<p className="services-page__text-bold">
									Каждый проект является индивидуальным и разрабатывается под конкретное техническое
									задание Заказчика.
								</p>
								Мы решаем поставленные задачи комплексно и осуществляем управление проектом под
								ключ.
							</span>
						</div>
					</div>
					<div className="services-page__image">
						<picture>
							<source srcSet={equipmentImg} media="(max-width: 1440px)" />
							<source srcSet={equipmentImg} />
							<img srcSet={equipmentImg} alt="command" className="why__img" />
						</picture>
						<div className="blur" style={{ backgroundImage: `url(${equipmentImg})` }}></div>
					</div>
				</div>
			</div>
			{/* selection process */}
			<div className="selection-process__block">
				<div className="container">
					<div className="selection-process-title h1">
						<span>Процесс подбора</span>
					</div>
					<div className="selection-process__items-block">
						{SERVICE.selection.map((item, i) => (
							<div className="selection-process__item" key={`selection-${i}`}>
								<div className="selection-process__item-icon">
									<img src={item.icon} alt="process" />
								</div>
								<div className="selection-process__item-title">
									<span>{item.text}</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<ContactFooter
				title={`Cвяжитесь с\u00a0нами`}
				requestForm={requestForm}
				name={name}
				setName={setName}
				contactInput={phone}
				setContactInput={setContactInput}
				extraInput={company}
				setExtraInput={setExtraInput}
				linkInput={email}
				setLinkInput={setLinkInput}
				textInput={description}
				setTextInput={setTextInput}
				checkbox={checkbox}
				setCheckbox={setCheckbox}
				errorData={errorData}
				map
			/>
		</div>
	)
})

Services.propTypes = {
	dispatch: PropTypes.func,
	store: PropTypes.object,
}
