import React, { useState } from 'react'

import { Slider } from '../../../../components/Slider/Slider'
import { GalleryImg } from './GalleryImg/GalleryImg'

import './EventGallery.scss'

export const EventGallery = React.memo(({ gallery }) => {
	const [isGalleryOpen, showGallery] = useState(false)
	const [currentPhoto, setCurrentPhoto] = useState(0)
	const [fullPhoto, toggleFullProto] = useState(false)

	const mobile = document.body.clientWidth

	const openSlider = (index) => () => {
		showGallery(true)
		setCurrentPhoto(index)
	}

	const closeSlider = () => {
		showGallery(false)
		setCurrentPhoto(0)
	}

	return (
		<>
			{isGalleryOpen && (
				<Slider
					onClose={closeSlider}
					gallery={gallery}
					currentPhoto={currentPhoto}
					show={isGalleryOpen}
				/>
			)}
			{!!gallery.length && (
				<div className="event__gallery">
					<h2 className="h2">Галерея</h2>
					<div className="event__gallery-wrapper">
						{mobile > 480 &&
							(fullPhoto ? gallery : gallery?.slice(0, 5))?.map((item, index) => (
								<GalleryImg
									key={item}
									item={item}
									index={index}
									showGallery={openSlider(index)}
									mobile={mobile}
								/>
							))}
						{mobile <= 480 &&
							gallery?.map((item, index) => (
								<GalleryImg
									key={item}
									item={item}
									index={index}
									showGallery={openSlider(index)}
									mobile={mobile}
								/>
							))}
					</div>
					{gallery.length > 5 && mobile > 480 && (
						<button
							className={`event__gallery-button ${fullPhoto ? 'close' : 'open'}`}
							onClick={() => toggleFullProto(!fullPhoto)}
						>
							Все фото
						</button>
					)}
				</div>
			)}
		</>
	)
})
