import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TeamList } from "./TeamList/TeamList";
import { TeamDropzone } from "./TeamDropzone/TeamDropzone";
import { TeamFilter } from "./TeamFilter/TeamFilter";
import { ConfirmRequest } from "../../components/ConfirmRequest/confirmRequest";
import {
  getIsLoading,
  getUsers,
  getCount,
  getFilters,
  getLocalList,
} from "../../store/reducers/constructor/constructor-selectors";
import {
  requestFilters,
  requestUsers,
  setFilters,
  setLocalList,
  setUsers,
} from "../../store/reducers/constructor/constructor";

import "./team.scss";

function translit(word) {
  var answer = "";
  var converter = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "e",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "ch",
    ш: "sh",
    щ: "sch",
    ь: "",
    ы: "y",
    ъ: "",
    э: "e",
    ю: "yu",
    я: "ya",

    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "E",
    Ё: "E",
    Ж: "Zh",
    З: "Z",
    И: "I",
    Й: "Y",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "H",
    Ц: "C",
    Ч: "Ch",
    Ш: "Sh",
    Щ: "Sch",
    Ь: "",
    Ы: "Y",
    Ъ: "",
    Э: "E",
    Ю: "Yu",
    Я: "Ya",
  };

  for (var i = 0; i < word.length; ++i) {
    if (converter[word[i]] === undefined) {
      answer += word[i];
    } else {
      answer += converter[word[i]];
    }
  }

  return answer;
}

export const Team = React.memo(({ lang }) => {
  const usersRef = useRef();
  const teamDropRef = useRef();

  const dispatch = useDispatch();

  const { skills, specializations, offset } = useSelector(getFilters);

  const loading = useSelector(getIsLoading);
  const users = useSelector(getUsers);
  const count = useSelector(getCount);
  const localList = useSelector(getLocalList);

  const deviceWidth = document.body.clientWidth;

  const [newRequest, setNewRequest] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [currentBoard, setCurrentBoard] = useState(null);

  //список специалистов из Кластера
  const [members, setMembers] = useState([]);

  // формируемый список команды
  const [dev, addDev] = useState([]);

  const eng = lang === "EN";

  // общая функция для удаления и добавления элементов

  const dragElementHandler = (board, dropBoardFunc, dropBoard, user) => {
    dropBoardFunc(dropBoard.filter((item) => item.id !== currentUser.id));

    const dropIndex = board.indexOf(user);
    board.splice(dropIndex + 1, 0, currentUser);
  };

  // drag-n-drop
  const dragOver = (e) => {
    // отслеживание перемещения
    e.preventDefault();
    e.stopPropagation();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e) => {
    // попадание элемента на элемент-назначение
    e.preventDefault();
    e.stopPropagation();
  };

  const dragStart = (user, board) => {
    setCurrentUser(user);
    setCurrentBoard(board);
    // начало переноса карточки
  };

  const dropHandler = (e, nameOfBoard, user) => {
    // элемент, над которым произошло сбрасывание
    e.preventDefault();
    e.stopPropagation();
    if (currentBoard === nameOfBoard) return;

    switch (nameOfBoard) {
      case "team":
        return dragElementHandler(members, addDev, dev, user);
      case "drop":
        return dragElementHandler(dev, setMembers, members, user);
      default:
        return null;
    }
  };

  const dropCard = (nameOfBoard) => {
    // элемент, над которым произошло сбрасывание
    if (currentBoard === nameOfBoard) return;

    switch (nameOfBoard) {
      case "team":
        setMembers([...members, currentUser]);
        return addDev(dev.filter((item) => item.id !== currentUser.id));
      case "drop":
        addDev([...dev, currentUser]);
        return setMembers(members.filter((item) => item.id !== currentUser.id));
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!loading) {
      setMembers(
        users
          ?.filter((item) => dev?.every((user) => item.id !== user.id))
          .map((item) => {
            return {
              ...item,
              name: translit(item.name),
              surname: translit(item.surname),
              skills: item.skills.filter(
                ({ key }) =>
                  key !== "bitcoin" &&
                  key !== "ethereum" &&
                  key !== "eos" &&
                  key !== "binance" &&
                  key !== "basicchain" &&
                  key !== "waves" &&
                  key !== "solidity"
              ),
              specializations: item.specializations.filter(
                ({ key }) => key !== "blockchain_dev"
              ),
            };
          })
      );
    }
  }, [loading, users, skills, specializations, offset, dev]);

  // список фильтров

  useEffect(() => {
    dispatch(requestFilters(eng ? "en" : "ru"));
  }, [dispatch, eng]);

  useEffect(() => {
    dispatch(
      setFilters({
        offset: 0,
      })
    );
    dispatch(setUsers({ users: [] }));
  }, [dispatch, eng]);

  useEffect(() => {
    if (!!Object.keys(localList).length) {
      dispatch(requestUsers());
    }
  }, [dispatch, skills, specializations, offset, localList]);

  useEffect(
    () => () => {
      dispatch(setUsers({ users: [] }));
      setMembers([]);
      addDev([]);
      dispatch(setLocalList({ localList: {} }));
      dispatch(
        setFilters({
          skills: [],
          specializations: [],
          offset: 0,
        })
      );
    },
    [dispatch]
  );

  // console.log(users, members, dev);

  return (
    <section className="team">
      <div className="container">
        <div className="team__wrapper">
          <TeamList
            eng={eng}
            addDev={addDev}
            dev={dev}
            members={members}
            setMembers={setMembers}
            users={users}
            dragOver={dragOver}
            dropCard={dropCard}
            dragEnter={dragEnter}
            dragStart={dragStart}
            dragLeave={dragLeave}
            dropHandler={dropHandler}
            ref={usersRef}
            device={deviceWidth}
            count={count}
          />
          <TeamDropzone
            eng={eng}
            addDev={addDev}
            dev={dev}
            members={members}
            setMembers={setMembers}
            dragOver={dragOver}
            dropCard={dropCard}
            dragEnter={dragEnter}
            dragStart={dragStart}
            dropHandler={dropHandler}
            dragLeave={dragLeave}
            ref={teamDropRef}
            setNewRequest={setNewRequest}
            device={deviceWidth}
          />
          {deviceWidth > 1440 && <TeamFilter eng={eng} />}
        </div>
      </div>
      {deviceWidth < 1440 && deviceWidth >= 768 && (
        <TeamFilter eng={eng} sidebar />
      )}
      {newRequest && (
        <ConfirmRequest
          eng={eng}
          onClose={() => setNewRequest(false)}
          devs={dev}
          setDevs={addDev}
          show={newRequest}
        />
      )}
    </section>
  );
});
