import React from 'react'

import './ContactSocial.scss'

export const ContactSocial = React.memo(({ link, icon, alt }) => (
	<li className="contact-us__contacts-social-item">
		<a href={link} target="_blank" rel="noreferrer" className="contact-us__contacts-social-link">
			<span className="img__wrapper blue">
				<img src={icon} alt={alt} />
				<div className="blur" style={{ backgroundImage: `url(${icon})` }}></div>
			</span>
		</a>
	</li>
))
