import React from 'react'
import PropTypes from 'prop-types'

import './SpecialisationItem.scss'

export const SpecialisationItem = React.memo(
	({ index, item, handler, active, specializations }) => (
		<div className="specializations-item text2">
			<input
				id={`checkbox-${index}`}
				type="checkbox"
				checked={active(item[0])}
				onChange={handler(item[0], specializations)}
			/>
			<label htmlFor={`checkbox-${index}`}>
				<span>{item[1]}</span>
			</label>
		</div>
	)
)

SpecialisationItem.propTypes = {
	label: PropTypes.string,
	tag: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
}
