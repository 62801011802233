import React from 'react'
import cn from 'classnames'

import './InputField.scss'

export const InputField = React.memo(
	({
		name,
		value,
		title,
		onChange,
		active,
		type,
		placeholder,
		onBlur,
		onFocus,
		onKeyUp,
		onClick,
		disabled,
		error,
	}) => (
		<>
			<input
				type={type}
				className={cn('contact-us__form-item', { active }, { error })}
				value={value}
				onChange={({ target: { value } }) => onChange(value)}
				autoComplete="off"
				name={name}
				placeholder={placeholder}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyUp={onKeyUp}
				onClick={onClick}
				disabled={disabled}
			/>
			<label htmlFor="email">{title}</label>
		</>
	)
)
