import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PagesHeader } from "../../../components/PagesHeader/PagesHeader";
import { TechnologyCard } from "../../../components/TechnologyCard/TechnologyCard";
import { XLine } from "../../../components/X-line/XLine";
import { ContactFooter } from "../../../components/ContactFooter/ContactFooter";
import { BlueButton } from "../../../components/Buttons/BlueButton/BlueButton";

import { addResume } from "../../../store/reducers/requests/requests";
import { getResult } from "../../../store/reducers/requests/requests-selector";
import { showMessage } from "../../../store/reducers/functions";

import { validate } from "../../functions";

import { EN_CAREER } from "../../../StaticInfo/StaticInfo";

import "./career.scss";

import headerBackground from "../../../assets/images/career_header.jpg";
// import workFormatImage from "../../../assets/images/kaliningrad.jpg";
// import workFormatImage2 from "../../../assets/images/workFormatImage2.jpg";
import questionBlockImg from "../../../assets/images/qa.jpg";

export const EnCareer = React.memo(() => {
  const dispatch = useDispatch();

  const result = useSelector(getResult);
  const ref = useRef();

  const [isChecked, checkIt] = useState(0);
  const [name, setName] = useState("");
  const [email, setResumeInput] = useState("");
  const [surname, setSubnameInput] = useState("");
  const [link, setEmailInput] = useState("");
  const [description, setTextInput] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [errorData, setErrorData] = useState({
    name: true,
    surname: true,
    link: true,
    email: true,
    company: true,
    description: true,
    checkbox: true,
  });

  const scrollToResumeForm = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: ref.current ? "smooth" : "auto",
        top: ref.current ? ref.current.offsetTop : 0,
      });
    });
  };

  const requestForm = () => {
    const data = {
      name: validate("name", name) ? name : "",
      email: validate("email", email) ? email : "",
      link: validate("link", link) ? link : "",
      surname: validate("surname", surname) && surname,
      description: validate("description", description) && description,
    };

    if (!data.name || !data.email || !data.link || !checkbox) {
      setErrorData({
        name: data.name ? true : false,
        link: data.link ? true : false,
        email: data.email ? true : false,
        checkbox: checkbox ? true : false,
        surname: data.surname === "" || data.surname ? true : false,
        description: data.description === "" || data.description ? true : false,
      });

      showMessage(dispatch, {
        code: "error",
        msg: "Fill all necessary fields",
      });
    } else {
      dispatch(addResume(data, true));
    }
  };

  useEffect(() => {
    if (result) {
      setName("");
      setResumeInput("");
      setSubnameInput("");
      setEmailInput("");
      setTextInput("");
      setCheckbox(false);
      setErrorData({
        name: true,
        surname: true,
        link: true,
        email: true,
        company: true,
        description: true,
        checkbox: true,
      });
    }
  }, [result]);

  return (
    <div className="career-page">
      <PagesHeader image={headerBackground} title={"BECOME A PART OF OUR TEAM"}>
        <p>
          X-Technology is a team of IT specialists. Everyone of us influences
          the development of technological progress and creates value for
          society.
        </p>
        <p>
          We help with realization of quality projects, since our main
          principles are competence and responsibility.
        </p>
      </PagesHeader>
      <div className="container">
        <div className="career-page__specializations">
          <div className="career-page__specializations-title h1">
            <span>Our specialties</span>
          </div>
          <div className="techno-card__wrapper">
            {EN_CAREER.tech.map((item, i) => (
              <TechnologyCard
                key={`card-${i}`}
                icon={item.icon}
                title={item.title}
                body={item.skills}
                button={"SEND YOUR CV"}
                openPopup={() => scrollToResumeForm()}
              />
            ))}
          </div>
          <div className="send-resume-buttom">
            <BlueButton
              text="SEND YOUR CV"
              openPopup={() => scrollToResumeForm()}
            />
          </div>
        </div>
        {/* <div className="work-format">
					<div className="work-format__title h1">
						<span>WORK FORMAT</span>
					</div>
					<div className="work-format__image-block-wrapper">
						<div className="work-format__image-item">
							<div className="work-format__img">
								<picture>
									<source srcSet={workFormatImage} media="(max-width: 768px)" />
									<source srcSet={workFormatImage} />
									<img srcSet={workFormatImage} alt="work-format-img" />
								</picture>
								<div className="blur" style={{ backgroundImage: `url(${workFormatImage})` }}></div>
							</div>
							<div className="work-format__info">
								<div className="work-format__info-title h3">
									<span>OFFICE IN KALININGRAD</span>
								</div>
								<div className="work-format__info-text text2">
									<span>Our first office where everything started</span>
								</div>
							</div>
						</div>
						<div className="work-format__image-item">
							<div className="work-format__img">
								<picture>
									<source srcSet={workFormatImage2} media="(max-width: 768px)" />
									<source srcSet={workFormatImage2} />
									<img srcSet={workFormatImage2} alt="work-format-img" />
								</picture>
								<div className="blur" style={{ backgroundImage: `url(${workFormatImage2})` }}></div>
							</div>
							<div className="work-format__info">
								<div className="work-format__info-title h3">
									<span>REMOTE WORK</span>
								</div>
								<div className="work-format__info-text text2">
									<span>You can work from anywhere</span>
								</div>
							</div>
						</div>
					</div>
				</div> */}
      </div>
      <div className="become-part-of-the-ship">
        <XLine
          title={"BECOME A PART OF OUR TEAM"}
          button={"SEND YOUR CV "}
          openPopup={() => scrollToResumeForm()}
        />
      </div>
      <div className="container">
        <div className="career-page__questions-block">
          <div className="questions-block__info">
            <div className="questions-block__title h1">
              <span>FAQ</span>
            </div>
            <div className="questions-block__body">
              {EN_CAREER.questions.map((item, i) => (
                <div
                  className="questions-block__question"
                  key={`question-${i}`}
                >
                  <label
                    className="career-question h3"
                    htmlFor={`question${i + 1}`}
                    onClick={() =>
                      isChecked === i ? checkIt(false) : checkIt(i)
                    }
                  >
                    {item.question}
                  </label>
                  <input
                    id={`question${i + 1}`}
                    type="radio"
                    name="question"
                    checked={isChecked === i}
                    readOnly
                  />
                  <div className="career-answer text2">
                    <span>{item.answer}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="questions-block__img">
            <picture>
              <source srcSet={questionBlockImg} />
              <img srcSet={questionBlockImg} alt="questionBlockImg" />
            </picture>
            <div
              className="blur"
              style={{ backgroundImage: `url(${questionBlockImg})` }}
            ></div>
          </div>
        </div>
        {/* <div className="telegram-contact">
					<div className="telegram-contact__text h2">
						<span>У вас остались вопросы? Пишите нам</span>
					</div>
					<Link to={'/career'}>
						<button className="main-team__button h3">
							<span>contact us</span>
						</button>
					</Link>
				</div> */}
      </div>
      <div className="career-contact-wrapper" ref={ref}>
        <ContactFooter
          eng
          title={"BECOME A PART OF OUR TEAM "}
          requestForm={requestForm}
          name={name}
          setName={setName}
          contactInput={email}
          setContactInput={setResumeInput}
          extraInput={surname}
          setExtraInput={setSubnameInput}
          linkInput={link}
          setLinkInput={setEmailInput}
          textInput={description}
          setTextInput={setTextInput}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
          errorData={errorData}
        />
      </div>
    </div>
  );
});
