import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TeamCard } from './TeamCard/TeamCard'
import { Preloader } from '../../../components/Preloader/Preloader'

import {
	getCount,
	getFilters,
	getIsLoading,
} from '../../../store/reducers/constructor/constructor-selectors'
import { setFilters } from '../../../store/reducers/constructor/constructor'

export const TeamList = React.forwardRef(
	(
		{
			addDev,
			dev,
			members,
			setMembers,
			dragOver,
			dropCard,
			dragEnter,
			dragStart,
			dropHandler,
			dragLeave,
			eng,
		},
		ref
	) => {
		const scrollRef = useRef()

		const dispatch = useDispatch()

		const count = useSelector(getCount)
		const isLoading = useSelector(getIsLoading)
		const { offset, limit } = useSelector(getFilters)

		const addNewMember = (user) => {
			addDev([...dev, user])
			setMembers(members.filter((item) => item.id !== user.id))
		}

		// отслеживание элемента и получение дополнительного списка элементов для отображения
		const handleScroll = useCallback(
			(entries) => {
				const [entry] = entries

				if (
					entry.isIntersecting &&
					members.length < count &&
					offset + limit <= count &&
					!isLoading
				) {
					dispatch(setFilters({ offset: offset + limit }))
				}
			},
			[dispatch, isLoading, offset, limit, count, members.length]
		)

		useEffect(() => {
			const current = scrollRef.current

			const options = {
				root: null,
				rootMargin: '0px',
				threshold: 1.0,
			}

			const observer = new IntersectionObserver(handleScroll, options)

			if (current) observer.observe(current)

			return () => {
				if (current) observer.unobserve(current)
			}
		}, [scrollRef, handleScroll])

		return (
			<div className="teams">
				<div
					className="team__wrapper-box"
					onDragOver={(e) => dragOver(e)}
					onDrop={() => dropCard('team')}
					ref={ref}
				>
					<div className={`team__wrapper-background ${members.length < 5 ? 'height' : ''}`}>
						{members?.map((item, index) => (
							<div key={`member-${item.id}-${new Date()}`}>
								<TeamCard
									eng={eng}
									user={item}
									dev={members}
									dragOver={dragOver}
									dragEnter={dragEnter}
									dragStart={dragStart}
									dropHandler={dropHandler}
									dragLeave={dragLeave}
									moveMemberToOtherList={addNewMember}
									nameOfBoard={'team'}
								/>
								{!!(index + 1 === members.length) && <span ref={scrollRef}></span>}
							</div>
						))}
						{!!isLoading && <Preloader />}
					</div>
				</div>
			</div>
		)
	}
)
