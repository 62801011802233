import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { TimelineItem } from './TimelineItem/TimelineItem'

import './Timeline.scss'

export const Timeline = React.memo(({ setDate, eng }) => {
	const [currentRangeValue, setCurrentRangeValue] = useState(0)
	const rangeValues = eng
		? [
				'1 month',
				'2 months',
				'3 months',
				'4 months',
				'5 months',
				'6 months',
				'7 months',
				'8 months',
				'9 months',
				'10 months',
				'11 months',
				'12 months',
				'13 months',
				'14 months',
				'15 months',
				'16 months',
				'17 months',
				'18 months',
				'19 months',
				'20 months',
				'21 months',
				'22 months',
				'23 months',
				'24 months',
				'24+ months',
		  ]
		: [
				'1 месяц',
				'2 месяца',
				'3 месяца',
				'4 месяца',
				'5 месяцев',
				'6 месяцев',
				'7 месяцев',
				'8 месяцев',
				'9 месяцев',
				'10 месяцев',
				'11 месяцев',
				'12 месяцев',
				'13 месяцев',
				'14 месяцев',
				'15 месяцев',
				'16 месяцев',
				'17 месяцев',
				'18 месяцев',
				'19 месяцев',
				'20 месяцев',
				'21 месяц',
				'22 месяца',
				'23 месяца',
				'24 месяца',
				'24+ месяцев',
		  ]

	const deviceWidth = document.body.clientWidth

	const fullWidth = (currentRangeValue * 100) / 24
	const leftMargin =
		deviceWidth > 1024 ? (currentRangeValue * 100) / 24 - 10 : (currentRangeValue * 100) / 24 - 9

	useEffect(() => {
		const date = new Date()

		setDate(new Date(date.setMonth(+(date.getMonth() + 1) + +currentRangeValue)))
	}, [setDate, currentRangeValue])

	return (
		<div className="timeline__wrapper">
			<p className="output" style={{ left: `${deviceWidth < 769 ? 50 : leftMargin}%` }}>
				<span>{rangeValues[currentRangeValue]}</span>
			</p>
			<input
				className="timeline__range"
				onChange={(e) => setCurrentRangeValue(e.target.value)}
				type={'range'}
				min={0}
				defaultValue={0}
				max={24}
			/>
			<div className="selector" style={{ width: `${fullWidth}%` }}></div>
			<div className="segment__wrapper">
				<div className="segment" style={{ opacity: 0 }}></div>
				{rangeValues.map((item, index) => (
					<TimelineItem key={item} range={currentRangeValue} index={index} />
				))}
				<div className="segment" style={{ opacity: 0 }}></div>
			</div>
		</div>
	)
})

Timeline.propTypes = {
	quickSearch: PropTypes.bool,
	teamDevs: PropTypes.bool,
}
