import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Popup } from '../../../../components/Popup/Popup'
import { ContactForm } from '../../../../components/ContactFooter/ContactForm/ContactForm'

import { validate } from '../../../functions'
import { showMessage } from '../../../../store/reducers/functions'

import { addResume } from '../../../../store/reducers/requests/requests'
import { getResult } from '../../../../store/reducers/requests/requests-selector'

export const CompanyPopup = React.memo(({ open, close }) => {
	const dispatch = useDispatch()

	const result = useSelector(getResult)

	const [name, setName] = useState('')
	const [email, setResumeInput] = useState('')
	const [surname, setSubnameInput] = useState('')
	const [link, setEmailInput] = useState('')
	const [description, setTextInput] = useState('')
	const [checkbox, setCheckbox] = useState(false)
	const [errorData, setErrorData] = useState({
		name: true,
		surname: true,
		link: true,
		email: true,
		company: true,
		description: true,
		checkbox: true,
	})

	const requestForm = () => {
		const data = {
			name: validate('name', name) ? name : '',
			email: validate('email', email) ? email : '',
			link: validate('link', link) ? link : '',
			surname: validate('surname', surname) && surname,
			description: validate('description', description) && description,
		}

		if (!data.name || !data.email || !data.link || !checkbox) {
			setErrorData({
				name: data.name ? true : false,
				link: data.link ? true : false,
				email: data.email ? true : false,
				checkbox: checkbox ? true : false,
				surname: data.surname === '' || data.surname ? true : false,
				description: data.description === '' || data.description ? true : false,
			})

			showMessage(dispatch, { code: 'error', msg: 'Fill all necessary fields' })
		} else {
			dispatch(addResume(data, true))
		}
	}

	useEffect(() => {
		if (result) {
			setName('')
			setResumeInput('')
			setSubnameInput('')
			setEmailInput('')
			setTextInput('')
			setCheckbox(false)
			setErrorData({
				name: true,
				surname: true,
				link: true,
				email: true,
				company: true,
				description: true,
				checkbox: true,
			})

			close(false)
			document.body.style.overflow = null
		}
	}, [result, close])

	return (
		<div className="company-page__popup">
			<Popup show={open} onClose={close} title={'Become a part of our team'}>
				<ContactForm
					eng
					btnColor={'blue'}
					btnPosition={'right'}
					requestForm={requestForm}
					name={name}
					setName={setName}
					contactInput={email}
					setContactInput={setResumeInput}
					extraInput={surname}
					setExtraInput={setSubnameInput}
					linkInput={link}
					setLinkInput={setEmailInput}
					textInput={description}
					setTextInput={setTextInput}
					checkbox={checkbox}
					setCheckbox={setCheckbox}
					errorData={errorData}
					white
				/>
			</Popup>
		</div>
	)
})
