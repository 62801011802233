import React from 'react'
import PropTypes from 'prop-types'

import './OvalFilterButton.scss'

export const OvalFilterButton = React.memo(
	({ title, checked, onChange, onClick, place, readonly }) => (
		<>
			<input
				type="checkbox"
				id={`${place}-${title}`}
				className="oval-button__checkbox"
				checked={checked}
				onChange={onChange}
				onClick={onClick}
				readOnly={readonly}
			></input>
			<label className="oval-button" htmlFor={`${place}-${title}`}>
				{title}
			</label>
		</>
	)
)

OvalFilterButton.propTypes = {
	title: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
}
