import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUsers, setFilters, requestUsers } from '../../../store/reducers/constructor/constructor'
import { getFilters } from '../../../store/reducers/constructor/constructor-selectors'

import './FoundSpecialists.scss'

export const FoundSpecialists = React.memo(({ dev, dropzone, addDev, device, setMembers, eng }) => {
	const dispatch = useDispatch()

	const { offset } = useSelector(getFilters)

	// определить окончание
	const showEnding = (number, words) => {
		const addNumber = Math.abs(number) % 100
		const number1 = number % 10
		if (addNumber > 10 && addNumber < 20) {
			return words[2]
		}
		if (number1 > 1 && number1 < 5) {
			return words[1]
		}
		if (number1 === 1) {
			return words[0]
		}
		return words[2]
	}

	//очистить список специалистов

	const clearSpecialistsList = () => {
		addDev([])
		setMembers([])
		dispatch(setUsers({ users: [] }))

		if (offset === 0) {
			dispatch(requestUsers())
		} else {
			dispatch(setFilters({ offset: 0 }))
		}
	}

	return (
		<div className="team__drop-buttons">
			<div className="text3">{`${dev} ${showEnding(
				dev,
				eng
					? ['specialist', 'specialists', 'specialists']
					: ['специалист', 'специалиста', 'специалистов']
			)} `}</div>

			{device > 768 && dev !== 0 && dropzone && (
				<button className="team__drop-clear" onClick={() => clearSpecialistsList()}>
					{eng ? 'Clear' : 'Очистить список'}
				</button>
			)}
		</div>
	)
})
