import React from 'react'

import './EmptyDropzone.scss'

export const EmptyDropzone = React.memo(({ setQuickSearch, eng }) => {
	const device = document.body.clientWidth

	return (
		<>
			<div className="dropzone">
				{device > 768 && (
					<span className="text2">
						{eng
							? 'Drag and drop here to add a specialist to your team'
							: 'Перетащи сюда, чтобы добавить специалиста в команду'}
					</span>
				)}
				{device <= 768 && (
					<span className="text2">
						{eng ? 'Add specialist to your team' : 'Добавь специалиста в команду'}
					</span>
				)}
			</div>
			<div className="quicksearch text2">
				<span>
					{eng ? 'or' : 'или'}
					<br />
					{eng ? 'use a quick search' : 'воспользуйтесь быстрым поиском'}
				</span>
				<button
					className="header__button quicksearch-button h3"
					onClick={() => setQuickSearch(true)}
				>
					{eng ? 'QUICK SEARCH' : 'Быстрый поиск'}
				</button>
			</div>
		</>
	)
})
