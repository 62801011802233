import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import cn from "classnames";

import { Popup } from "../Popup/Popup";
import { SpecialisationItem } from "../SpecialisationItem/SpecialisationItem";
import { Timeline } from "../ConfirmRequest/Timeline/Timeline";
import { ContactForm } from "../ContactFooter/ContactForm/ContactForm";
import { Filter } from "../../pages/Team/TeamFilter/Filter/Filter";

import { getLocalList } from "../../store/reducers/constructor/constructor-selectors";
import { getResult } from "../../store/reducers/requests/requests-selector";
import { showMessage } from "../../store/reducers/functions";
import { addQuickSearchTeam } from "../../store/reducers/requests/requests";

import { validate } from "../../pages/functions";

import "./QuickSearch.scss";

export const QuickSearch = React.memo(({ onClose, show, eng }) => {
  const dispatch = useDispatch();
  const localList = useSelector(getLocalList);
  const result = useSelector(getResult);
  const [confirmRequest, pushButton] = useState(false);

  const [spec, setSpec] = useState([]);
  const [skills, setSkills] = useState([]);

  const [name, setName] = useState("");
  const [phone, setContactInput] = useState("");
  const [company, setExtraInput] = useState("");
  const [email, setLinkInput] = useState("");
  const [description, setTextInput] = useState("");
  const [file, setFile] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [date, setDate] = useState("");
  const [fileName, setFileName] = useState("");

  const [errorData, setErrorData] = useState({
    name: true,
    phone: true,
    email: true,
    checkbox: true,
    company: true,
    description: true,
  });

  // фильтр специализаций
  const localSpecList = localList.specializations
    ?.filter(({ key }) => key !== "blockchain_dev")
    .map((item) => [item.key, item.value]);

  const activeCheckbox = (value) => {
    return spec.includes(value) ? true : false;
  };

  const addSpec = (value) => () => {
    if (spec.includes(value)) {
      setSpec(spec.filter((item) => item !== value));
    } else {
      setSpec([...spec, value]);
    }
  };

  // фильтр технологий
  const localTechList = localList.techs?.filter(
    ({ title }) => title !== "Blockchain"
  );

  const activeSkills = (value) => {
    return skills.includes(value) ? true : false;
  };

  const addSkills = (value) => () => {
    if (skills.includes(value)) {
      setSkills(skills.filter((item) => item !== value));
    } else {
      setSkills([...skills, value]);
    }
  };

  //отправка формы
  const requestForm = () => {
    const data = {
      name: validate("name", name) ? name : "",
      company: validate("company", company) && company,
      phone: validate("phone", phone) ? phone : "",
      email: validate("email", email) ? email : "",
      description: validate("description", description) && description,
      specializations: spec,
      skills: skills,
      deadline: date,
      file: file,
    };

    if (!data.name || !data.phone || !data.email || !checkbox) {
      setErrorData({
        name: data.name ? true : false,
        phone: data.phone ? true : false,
        email: data.email ? true : false,
        checkbox: checkbox ? true : false,
        company: data.company === "" || data.company ? true : false,
        description: data.description === "" || data.description ? true : false,
      });

      showMessage(dispatch, {
        code: "error",
        msg: eng
          ? "Fill all necessary fields"
          : "Заполните все необходимые поля",
      });
    } else {
      dispatch(addQuickSearchTeam(data, eng));
    }
  };

  //очистка формы
  useEffect(() => {
    if (result) {
      setName("");
      setContactInput("");
      setExtraInput("");
      setLinkInput("");
      setTextInput("");
      setFile("");
      setCheckbox(false);
      setDate("");
      setFileName("");
      setErrorData({
        name: true,
        phone: true,
        email: true,
        checkbox: true,
        company: true,
        description: true,
      });
      onClose(false);
    }
  }, [result, onClose]);

  return (
    <div className="quick-search">
      {!confirmRequest && (
        <Popup
          show={show}
          title={eng ? "Quick search" : "Быстрый поиск"}
          onClose={onClose}
          quick
        >
          <div className="quick-search__wrapper">
            <Filter
              eng={eng}
              localSpecList={localSpecList}
              localTechList={localTechList}
              handleSpecialization={addSpec}
              handleSkills={addSkills}
              activeCheckbox={activeCheckbox}
              activeSkills={activeSkills}
              place={"search"}
            />
          </div>
          <button
            className={cn("send-request", {
              show: !!spec.length || !!skills.length,
            })}
            onClick={() => pushButton(true)}
          >
            <span className="h3">
              {eng ? "Make a request " : "Оформить заявку"}
            </span>
          </button>
        </Popup>
      )}
      <div className="confirm-request__wrapper">
        {confirmRequest && (
          <Popup
            title={eng ? "Make a request " : "Оформить заявку"}
            onClose={onClose}
          >
            <div>
              <div className="confirm-request">
                <button
                  className="confirm-request__back-btn"
                  onClick={() => pushButton(false)}
                >
                  <span>{eng ? "Back" : "Назад"}</span>
                </button>
                <div className="confirm-request__title h3">
                  {eng ? "Term of your project" : "Срок вашего проекта"}
                </div>
                <div className="timeline">
                  <div className="timeline_scroll-wrapper">
                    <div className="timeline_scroll">
                      <Timeline setDate={setDate} eng={eng} />
                    </div>
                  </div>
                </div>
                <div className="confirm-request__form-title h3">
                  {eng ? "Your data" : "Ваши данные"}
                </div>
                <ContactForm
                  eng={eng}
                  btnColor="blue"
                  btnPosition="right"
                  requestForm={requestForm}
                  name={name}
                  setName={setName}
                  contactInput={phone}
                  setContactInput={setContactInput}
                  extraInput={company}
                  setExtraInput={setExtraInput}
                  linkInput={email}
                  setLinkInput={setLinkInput}
                  textInput={description}
                  setTextInput={setTextInput}
                  file={file}
                  setFile={setFile}
                  checkbox={checkbox}
                  setCheckbox={setCheckbox}
                  fileName={fileName}
                  setFileName={setFileName}
                  errorData={errorData}
                  white
                  contact
                />
              </div>
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
});

SpecialisationItem.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  tag: PropTypes.node,
  label: PropTypes.node,
};

QuickSearch.propTypes = {
  onClose: PropTypes.func,
  skillTitle: PropTypes.func,
  state: PropTypes.object,
  store: PropTypes.object,
  dispatch: PropTypes.func,
};
