export const getFilters = ({ teams: { filters } }) => filters

export const getUsers = ({ teams: { users } }) => users

export const getLocalList = ({ teams: { localList } }) => localList

export const getIsLoading = ({ teams: { isLoading } }) => isLoading

export const getRequested = ({ teams: { requested } }) => requested

export const getCount = ({ teams: { count } }) => count

export const getDevList = ({ teams: { devList } }) => devList
