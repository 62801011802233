import { createSlice } from '@reduxjs/toolkit'

import constructorApi from '../../../api/constructor-api'

import { getLocalStorageLang } from '../../../pages/localStorage'

const initialState = {
	isLoading: false,
	requested: false,
	filters: {
		skills: [],
		specializations: [],
		limit: 15,
		offset: 0,
		pat: '',
	},
	localList: {},
	users: [],
	count: 0,
}

const teams = createSlice({
	name: 'teams',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		setUsers: (state, action) => {
			state.users = action.payload.users
		},
		setCount: (state, action) => {
			state.count = action.payload.count
		},
		setLocalList: (state, action) => {
			state.localList = action.payload.localList
		},
		setIsRequested: (state, action) => {
			state.requested = action.payload
		},
	},
})

const { reducer, actions } = teams

export const {
	setIsLoading,
	setFilters,
	setUsers,
	setLocalList,
	setCount,
	setIsRequested,
} = actions

// Получение списка разработчиков

export const requestUsers = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const langStringUpperCase = getLocalStorageLang()

	const lang = langStringUpperCase?.toLowerCase()

	const {
		teams: { filters, users },
	} = getState()

	const res = await constructorApi.requestUsers({ ...filters, lang })

	if (res.success) {
		dispatch(setUsers({ users: [...users, ...res.result.users] }))
		dispatch(setCount({ count: res.result.count }))
	}
	dispatch(setIsLoading({ isLoading: false }))
}

// Получение списка фильтров

export const requestFilters = (lang) => async (dispatch) => {
	dispatch(setIsRequested({ requested: false }))

	dispatch(setIsLoading({ isLoading: true }))

	const res = await constructorApi.requestFilters(lang)
	if (res.success) {
		dispatch(setLocalList({ localList: res.result }))
		dispatch(setIsRequested({ requested: true }))
	}

	dispatch(setIsLoading({ isLoading: false }))
	dispatch(setIsRequested({ requested: false }))
}

export default reducer
