import React from 'react'
import PropTypes from 'prop-types'

import './PagesHeader.scss'

export const PagesHeader = React.memo(({ image, title, children }) => (
	<div className="pages-header">
		<div className="container">
			<div className="pages-header__info-block">
				<div className="pages-header__title h1">
					<span>{title}</span>
				</div>
				<div className="pages-header__text">
					<span>{children}</span>
				</div>
			</div>
		</div>
		<div className="bg-container">
			<div className="pages-header__x-logo"></div>
			<div
				className="pages-header__image"
				style={{ background: `#f8f9fa url(${image}) 100%/cover no-repeat` }}
			></div>
		</div>
	</div>
))

PagesHeader.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.node,
}
