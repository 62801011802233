import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import teams from './reducers/constructor/constructor'
import mainEvents from './reducers/main/main'
import events from './reducers/community/community'
import requests from './reducers/requests/requests'
import globalMessages from './reducers/global-messages/global-messages'

const reducers = { teams, mainEvents, events, globalMessages, requests }

const middleware = getDefaultMiddleware({
	immutableCheck: false,
	serializableCheck: false,
	thunk: true,
})

const store = configureStore({ reducer: reducers, middleware })

window.store = store

export default store
