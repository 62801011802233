import instance from "./api";

const eventsApi = {
  async requestEvents(offset, limit, tag, lang) {
    const response = await instance.post(
      `/event/user/all?tag=${tag}&offset=${offset}&limit=${limit}&lang=${lang}`,
      {}
    );

    return response.data;
  },

  async requestEvent(id) {
    const response = await instance.get(`event/user/get?id=${id}`);

    return response.data;
  },
};

export default eventsApi;
