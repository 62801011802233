import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Thumbs, Navigation, A11y } from 'swiper'
import { Popup } from '../Popup/Popup'

import { url } from '../../api/api'

import './slider.scss'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/thumbs/thumbs.scss'

SwiperCore.use([Thumbs, Navigation, A11y])

export const Slider = React.memo(({ onClose, gallery, currentPhoto = 0, show }) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null)

	return (
		<div className="slider__wrapper">
			<Popup show={show} onClose={onClose}>
				<Swiper
					initialSlide={currentPhoto}
					className="main__slider"
					thumbs={{ swiper: thumbsSwiper }}
					slidesPerView={1}
					navigation
					loop
				>
					{gallery.map((item) => (
						<SwiperSlide key={`gallery-${item}`}>
							<img src={`${url}/static/${item}`} alt="Галерея" />
						</SwiperSlide>
					))}
				</Swiper>
				<Swiper
					onSwiper={setThumbsSwiper}
					watchSlidesVisibility
					watchSlidesProgress
					freeMode={true}
					slidesPerView={5}
					spaceBetween={14}
				>
					{gallery.map((item, index) => (
						<SwiperSlide key={item} className="slider__thumbs">
							<img src={`${url}/static/${item}`} alt="Галерея" />
						</SwiperSlide>
					))}
				</Swiper>
			</Popup>
		</div>
	)
})

Slider.propTypes = {
	onClose: PropTypes.func,
	gallery: PropTypes.array,
}
