import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/icons/technology.svg";
// import fb from '../../assets/images/icons/facebook_m.svg'
// import insta from '../../assets/images/icons/instagram_m.svg'
// import yoube from "../../assets/images/icons/youtube_m.svg";
// import vk from "../../assets/images/icons/vk_m.svg";
// import linkedin from "../../assets/images/icons/linkedin.svg";
import { ContactSocialArr } from "../../StaticInfo/StaticInfo";

import "./footer.scss";

export const Footer = React.memo(({ lang }) => {
  const year = new Date().getFullYear();

  const eng = lang === "EN";

  const scrollIt = () => () => {
    return window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <button className="scroll-it-up" onClick={scrollIt()}></button>
      <div className="container footer__wrapper">
        <div className="footer__wrapper-item">
          <div className="footer__logo">
            <NavLink to="/">
              <img src={logo} alt="x-tech" />
            </NavLink>
          </div>
          <div className="footer__text">
            <span className="text3">
              Copyright &copy; 2021-{year} “X-Technology”
              <br />
              License No. 25994.
            </span>
            <span className="text3">
              {eng ? "All rights reserved." : "Все права защищены."}
            </span>
          </div>
        </div>
        <div className="footer__wrapper-item">
          <nav className="footer__nav footer__box">
            <h4 className="footer__title">
              {eng ? "Navigation" : "Навигация"}
            </h4>
            <ul className="footer__nav-wrapper">
              <li className="footer__nav-item">
                <NavLink to="/service" className="footer__nav-link">
                  {eng ? "Services" : "Сервисы"}
                </NavLink>
              </li>
              <li className="footer__nav-item">
                <NavLink to="/community" className="footer__nav-link">
                  {eng ? "Community" : "Сообщество"}
                </NavLink>
              </li>
              <li className="footer__nav-item">
                <NavLink to="/career" className="footer__nav-link">
                  {eng ? "Career" : "Карьера"}
                </NavLink>
              </li>
              <li className="footer__nav-item">
                <NavLink to="/company" className="footer__nav-link">
                  {eng ? "About us" : "Компания"}
                </NavLink>
              </li>
              <li className="footer__nav-item">
                <NavLink to="/team" className="footer__nav-link">
                  {eng ? "Team constructor" : "Конструктор команды"}
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="footer__social footer__box">
            <h4 className="footer__title">
              {eng ? "Our social Media " : "Социальные Сети"}
            </h4>
            <ul className="footer__nav-wrapper">
              {/* <li className="footer__nav-item">
                <a
                  href="https://www.linkedin.com/company/x-technology-org/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__nav-link text2"
                >
                  <span className="img__wrapper blue">
                    <img src={linkedin} alt="linkedin" />
                    
											<div className='blur' style={{ backgroundImage: `url(${linkedin})` }}></div>
										
                  </span>
                  <span className="social-title">linkedin</span>
                </a>
              </li> */}
              {/* <li className="footer__nav-item">
								<a
									href="https://www.facebook.com/xcluster39/"
									target="_blank"
									rel="noreferrer"
									className="footer__nav-link text2"
								>
									<span className="img__wrapper blue">
										<img src={fb} alt="facebook" />
									</span>
									<span className="social-title">facebook</span>
								</a>
							</li> */}
              {/* <li className="footer__nav-item">
								<a
									href="https://www.instagram.com/x__cluster/"
									target="_blank"
									rel="noreferrer"
									className="footer__nav-link text2"
								>
									<span className="img__wrapper yellow">
										<img src={insta} alt="instagram" />
									</span>
									<span className="social-title">instagram</span>
								</a>
							</li> */}
              {/* <li className="footer__nav-item">
                <a
                  href="https://www.youtube.com/@x-technologyitcompany790/featured"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__nav-link text2"
                >
                  <span className="img__wrapper red">
                    <img src={yoube} alt="youtube" />
                  </span>
                  <span className="social-title">youtube</span>
                </a>
              </li> */}
              {/* <li className="footer__nav-item">
                <a
                  href="https://vk.com/x_welcome"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__nav-link text2"
                >
                  <span className="img__wrapper blue">
                    <img src={vk} alt="Вконтакте" />
                  </span>
                  <span className="social-title">Вконтакте</span>
                </a>
              </li> */}
              {ContactSocialArr.map(({ link, icon, alt }) => (
                <li className="footer__nav-item" key={`${alt}:${link}`}>
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="footer__nav-link text2"
                  >
                    <span className="img__wrapper blue">
                      <img src={icon} alt={alt} />
                    </span>
                    <span className="social-title">{alt}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer__contact footer__box">
            <h4 className="footer__title">
              {eng ? "OUR OFFICE:" : "Мы находимся:"}
            </h4>
            <div className="footer__box-item">
              <div className="footer__contact-address text2">
                {/* Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab
                Emirates */}
                г. Калининград, ул. Генерал-Лейтенанта Озерова, 17Б (БЦ Мега)
                {/* {eng
                  ? "236010, Russian Federation, Kaliningrad, st. Lieutenant General Ozerov, 17B, 8th floor"
                  : "236010, РФ, г.Калининград, ул. Генерал-лейтенанта Озерова, 17Б, 8 этаж"}
                {eng && (
                  <p>
                    <br />
                    11312, Harju maakond, Tallinn, Kesklinna linnaosa, Pärnu mnt
                    105
                  </p>
                )} */}
              </div>
              <div className="footer__contact-link">
                {/* <Link
								className="footer__nav-link text2"
								to='#'
								onClick={(e) => {
									window.location = 'tel:+796543210'
									e.preventDefault()
								}}>+796543210</Link> */}

                <a
                  href="mailto:manager@x-tek.ru"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__nav-link text2"
                >
                  {/* manager@x-tek.ru */}
                  manager@x-tek.ru
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
